import { Dialog }              from './dialog/Dialog'
import { useEffect, useState } from 'react'
import Consts                  from '../consts'
import ReactGa                 from 'react-ga'
import { useTranslation }      from 'react-i18next'
import { useLocation }         from 'react-router-dom'

function usePageViews() {
  let location = useLocation()

  useEffect(
    () => {
      let analyticsEnabled = localStorage.getItem(Consts.Storage.keyCookiesAnalytics) !== null
      if (analyticsEnabled) {
        ReactGa.pageview(`${location.pathname}${location.search ? `/${location.search}` : ''}`);
      }
    },
    [location]
  )
}

const CookiesDialog = () => {

  const { t } = useTranslation()

  const firstLaunch = localStorage.getItem(Consts.Storage.keyCookiesRequired) === null
  const [analyticsConsent, setAnalyticsConsent] = useState(firstLaunch)
  const [open, setOpen] = useState(firstLaunch)

  useEffect(() => {
    if (!firstLaunch) {
      setAnalyticsConsent(localStorage.getItem(Consts.Storage.keyCookiesAnalytics) !== null)
    }
  }, [open])

  const setConsents = (analytics) => {
    localStorage.setItem(Consts.Storage.keyCookiesRequired, Date.now())
    if (analytics) {
      localStorage.setItem(Consts.Storage.keyCookiesAnalytics, Date.now())
      delete window[`ga-disable-${Consts.GoogleAnalytics.id}`]
    } else {
      localStorage.removeItem(Consts.Storage.keyCookiesAnalytics)
      window[`ga-disable-${Consts.GoogleAnalytics.id}`] = true
    }
    setOpen(false)
  }

  return (
    <div>
      <Dialog title={t('cookies-settings')}
              open={open}
              actions={[
                { label: t('cookies-allow-necessary'), className: 'btn btn-secondary', onClick: (e) => { setConsents(false) }},
                { label: t('cookies-allow-selected'), className: 'btn btn-danger', onClick: (e) => { setConsents(analyticsConsent) }},
              ]}
      >
        <div className={'content-wrapper dialog-consents'}>
          <div className={'form-wrapper'}>
            <div className="form-check">
              <input className="form-check-input" id={'cookies-necessary'} type={'checkbox'} checked={true} disabled={true} onChange={(e) => { /* nothing to do */ }}/>
              <label className="form-check-label" htmlFor="cookies-necessary">
                {t('cookies-necessary')}
              </label>
              <div className={'form-check-info'}>{t('cookies-necessary-text')}</div>
            </div>
            <hr/>
            <div className="form-check">
              <input className="form-check-input" id={'cookies-analytics'} type={'checkbox'} checked={analyticsConsent} onChange={(e) => {
                setAnalyticsConsent(e.target.checked)
              }} />
              <label className="form-check-label" htmlFor="cookies-analytics">
                {t('cookies-analytics')}
              </label>
              <div className={'form-check-info'}>{t('cookies-analytics-text')} </div>
            </div>
          </div>
        </div>
      </Dialog>
      <a href={'#'} onClick={(e) => {
        e.preventDefault()
        setOpen(true)
      }}>{t('cookies-settings')}</a>
    </div>
  );
}

export { usePageViews, CookiesDialog }