const { DateTime } = require("luxon")

class BirthNumber {
  constructor(number) {
    this.number = (number ?? '').replaceAll('/', '').replaceAll(' ', '')
  }

  isValid = () => {
    if (isNaN(parseInt(this.number))) {
      return false
    }
    if (this.number.length < 9 || this.number.length > 10) {
      return false
    }
    if (this.number.length === 10 && this.number % 11 !== 0) {
      return false
    }
    if (this.getMonth() < 1 || this.getMonth() > 12) {
      return false
    }
    if (this.getDay() < 1 || this.getDay() > 31) {
      return false
    }
    return true
  }

  getYear = () => {
    let year = parseInt(this.number.substring(0, 2))
    return (this.number.length === 9 || year >= 54 ? 1900 : 2000) + year
  }
  getMonth = () => {
    let month = parseInt(this.number.substring(2, 4))
    return month > 50 ? month - 50 : month
  }
  getDay = () => parseInt(this.number.substring(4, 6))

  getAge = () => Math.abs(DateTime.local(this.getYear(), this.getMonth(), this.getDay(), 0, 0).diffNow('years').years)
}

// module.exports = BirthNumber
export default BirthNumber
