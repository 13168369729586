import { withTranslation }                     from 'react-i18next'
import { Form, ValidationRules }               from '../components/form/Form'
import FormField                               from '../components/FormField'
import Loader                                  from '../components/Loader'
import { Divider }                                        from '../components/Divider'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate }                                    from 'react-router-dom'
import { useApi }                              from '../api'
import { AppContext }                          from '../index'
import ReCAPTCHA                               from 'react-google-recaptcha'
import Consts                                  from '../consts'

function LoginPage({ t }) {

  const { setUser } = useContext(AppContext)
  const api = useApi()
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState(null)
  const [requireNewPassword, setRequireNewPassword] = useState(false)
  const navigate = useNavigate()
  const recaptchaRef = useRef();

  const onSubmit = async (data) => {
    const captchaToken = recaptchaRef.current.getValue()
    if (!captchaToken) {
      return
    }
    setResult(() => null)
    setLoading(() => true)
    try {
      let response = await api.user.login(data.email.value, data.password.value, requireNewPassword ? data.newPassword.value : null, captchaToken)
      setUser(response)
      navigate('/')
    } catch (e) {
      recaptchaRef.current?.reset()
      if (e.status === 403) {
        setRequireNewPassword(true)
      } else {
        setResult({
          success: false,
          message: e.error ?? (t('login-failed')),
        })
      }
    }

    setLoading(() => false)
  }

  const provideValidationRules = () => ({
    'email': { regex: ValidationRules.email, error: t('validation-invalid-email') },
    'password': { regex: ValidationRules.notEmpty, error: t('validation-invalid-password') },
    ... (requireNewPassword ? {
      'newPassword': { regex: ValidationRules.password, error: t('validation-invalid-password') },
      'newPasswordAgain': { regex: ValidationRules.password, error: t('validation-invalid-password') },
    } : {})
  })

  const customValidateForm = (state, dispatch) => {
    if (requireNewPassword && state['newPassword']?.value !== state['newPasswordAgain']?.value) {
      dispatch({ key: 'newPasswordAgain', payload: { value: state['newPasswordAgain']?.value, error: 'validation-invalid-password-dont-match' } })
      return false
    }
    return true
  }


  return (<div className={'container'}>
    <div className={'content-wrapper'}>
      <div className={'form-wrapper'}>
        <div className={'title-content'}>
          <h3>{t('login-title')}</h3>
        </div>
        <Form
          defaultState={{
            email: { value: '', error: null },
            password: { value: '', error: null },
          }}
          customValidateForm={customValidateForm}
          provideValidationRules={provideValidationRules}
          onValidFormSubmit={onSubmit}
        >
          <FormField label={t('general-email')} name={'email'} type={'email'} />
          <FormField label={t('login-codeOrPassword')} name={'password'} type={'password'} />
          {requireNewPassword && <FormField label={t('login-newPassword')} name={'newPassword'} type={'password'} />}
          {requireNewPassword && <FormField label={t('login-newPasswordAgain')} name={'newPasswordAgain'} type={'password'} />}
          <Divider />
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={!process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? Consts.ReCAPTCHA.keyDev : Consts.ReCAPTCHA.key}
            size={'normal'}
          />
          <Divider />
          {loading && <Loader />}
          {result !== null && <div className={result.success ? 'field-success' : 'field-error'}>{result.message}</div>}
          {!loading && <div><button type={'submit'} className={'btn btn-submit'}>{t('login-login')}</button></div>}
        </Form>
      </div>
    </div>
    <Divider size={5} />
  </div>)
}


export default withTranslation()(LoginPage)