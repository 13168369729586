import { usePageViews } from '../components/CookiesDialog'
import ScrollToTop from '../components/ScrollToTop'
import {Outlet, useLocation, useSearchParams} from 'react-router-dom'
import Header from './components/header/Header'
import Footer from './components/footer/Footer'
import {useEffect, useLayoutEffect} from "react";
import './scss/index.scss'
import {useTranslation} from "react-i18next";
import UAConsts from "./consts";

const UALayout = () => {
  usePageViews()
  const {i18n: {language, changeLanguage}, t} = useTranslation()
  const location = useLocation()
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const link = document.querySelector("link[rel='icon']");
    link.href = '/img/favicon_ua.ico';
  }, [])

  useEffect(() => {
    if (location.pathname !== '/'){
      document.title = t('ua-title')
    }
    localStorage.setItem(UAConsts.LocalStorageLangKey, language)
  }, [language, location])

  useEffect(() => {
    const lang = searchParams.get('lang')
    const storageLang = localStorage.getItem(UAConsts.LocalStorageLangKey)
    if (lang && ['sk', 'ua'].find((item) => item === lang)) {
      changeLanguage(lang)
    }
    else if (storageLang && ['sk', 'ua'].find((item) => item === storageLang)) {
      changeLanguage(storageLang)
    }
  }, []);

  return (<>
    <Header />
    <ScrollToTop />
    <Outlet />
    <Footer />
  </>)
}

export default UALayout
