import { withTranslation }       from 'react-i18next'
import React, { useState }       from 'react'
import { useApi }                from '../../api'
import FormField                 from '../../components/FormField'
import { Divider }               from '../../components/Divider'
import Consts                    from '../../consts'
import { Form, ValidationRules } from '../../components/form/Form'
import FormResult                from '../../components/form/FormResult'
import { DateTime }              from 'luxon'

const AdminStatisticsPage = ({ t }) => {

  const api = useApi()
  const [result, setResult] = useState(null)
  const [items, setItems] = useState([])

  const onSubmit = (data, entity) => {
    setResult(null)
    let from = DateTime.fromFormat(data.dateFrom.value, Consts.IsoDateFormat)
    let to = DateTime.fromFormat(data.dateTo.value, Consts.IsoDateFormat)
    api.admin.userStatistics(from.toFormat(Consts.ShortApiDateFormat), to.toFormat(Consts.ShortApiDateFormat)).then(response => {
      setItems(response)
    }).catch(error => {
      setResult({
        success: false,
        message: error.message
      })
    })
  }

  const customValidateForm = (state, dispatch) => {
    try {
      let from = DateTime.fromFormat(state['dateFrom'].value, Consts.IsoDateFormat)
      let to = DateTime.fromFormat(state['dateTo'].value, Consts.IsoDateFormat)
      if (from > to) {
        dispatch({ key: 'dateFrom', payload: { value: state['dateFrom'].value, error: 'validation-date-preceded' } })
        return false
      }
    } catch (e) {
      return false
    }
    return true
  }

  return (
    <div className={'container'}>
      <div className={'content-wrapper'}>
        <div className={'title-content mb-4'}>
          <h3>{t('statistics-user-title')}</h3>
        </div>
        <div className={'form-wrapper'}>
          <Form
            defaultState={{
              dateFrom: { value: '', error: null },
              dateTo: { value: '', error: null },
            }}
            provideValidationRules={() => ({
              'dateFrom': { regex: ValidationRules.date, error: 'validation-invalid-date' },
              'dateTo': { regex: ValidationRules.date, error: 'validation-invalid-date' },
            })}
            customValidateForm={customValidateForm}
            onValidFormSubmit={onSubmit}
          >
            <div className={'row'}>
              <div className={'col-12 col-md-6'}>
                <FormField label={t('export-dateFrom')} name={'dateFrom'} type={'date'} />
              </div>
              <div className={'col-12 col-md-6'}>
                <FormField label={t('export-dateTo')} name={'dateTo'} type={'date'} />
              </div>
            </div>
            <Divider />
            <div className={'d-flex justify-content-start gap-2'}>
              <button type={'submit'} className={'btn btn-primary btn-sm'}>{t('general-show')}</button>
            </div>
            <FormResult result={result} />
            <Divider />
            <table className={'table'}>
              <thead>
                <th>{t('statistics-user-user')}</th>
                <th>{t('statistics-user-email')}</th>
                <th>{t('statistics-user-seens')}</th>
              </thead>
              <tbody>
              {items.map(it => <tr>
                <td>{it.user.name}</td>
                <td>{it.user.email}</td>
                <td>{it.voucherSearch ?? 0}</td>
              </tr>)}
              </tbody>
            </table>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(AdminStatisticsPage)