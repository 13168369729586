import { withTranslation }       from 'react-i18next'
import FormField                 from '../../components/FormField'
import { Divider }               from '../../components/Divider'
import Loader                    from '../../components/Loader'
import { Form, ValidationRules } from '../../components/form/Form'
import { useApi }                from '../../api'
import { useState }              from 'react'
import BirthNumber               from '../../helper/birth_number'
import { useNavigate }           from 'react-router-dom'
import Consts                    from '../../consts'

const stepVoucherLookup = 0
const stepPersonValidation = 1
const idNumberValidation = (val) =>  new BirthNumber(val).isValid()
const VoucherLookupPage = ({ t }) => {

  const api = useApi()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState(null)
  const [step, setStep] = useState(stepVoucherLookup)
  const [isStudentAdult, setIsStudentAdult] = useState(false)
  const [isUaVoucher, setIsUaVoucher] = useState(false)

  const onFormValueChange = (key, value) => {
    if (key === 'studentIdNumber') {
      let number = new BirthNumber(value)
      setIsStudentAdult(number.isValid() && number.getAge() >= 18)
    }
  }

  const provideValidationRules = () => {
    switch (step) {
      case stepVoucherLookup:
        return {
          'code': { regex: ValidationRules.voucherCode, error: t('validation-invalid-voucher-code') },
        }
      case stepPersonValidation:
        let result = {
          'studentIdNumber': { regex: ValidationRules.idNumber, error: t('validation-invalid-idnumber'), additionalValidation: idNumberValidation },
        }
        if (!isStudentAdult) {
          result.parentIdNumber = { regex:  ValidationRules.notEmpty, error: t('validation-invalid-idnumber') }
        }
        return result
      default:
        return {}
    }
  }

  const onSubmit = async (data) => {
    setResult(null)
    setLoading(() => true)
    let validatedValues = [data.code.value, data.studentIdNumber.value?.replaceAll('/', '') ?? null, data.parentIdNumber.value?.replaceAll('/', '') ?? null]
    try {
      let response = await api.salesman.checkVoucher(...validatedValues)
      if (response?.id !== null) {
        if (step >= stepPersonValidation) {
          let lockedVoucher = await api.salesman.lockVoucher(...validatedValues)
          const isUa = (lockedVoucher.type & Consts.Voucher.Type.Ukraine) !== 0
          navigate(`${isUa ? '/ua' : ''}/salesman/voucher/check/${lockedVoucher.id}`)
        } else {
          setStep((prev) => ++prev)
          setIsUaVoucher((response.type & Consts.Voucher.Type.Ukraine) !== 0)
        }
      }

      // previously was setup step with next index of step => this changed to lock voucher immediately and go to validate student & parent data
      // setStep((prev) => ++prev)
    } catch (e) {
      console.error("Failed to check voucher", e)
      setResult({
        success: false,
        message: e.error ?? (e.status === 403 ? t('voucher-locked-for-other-salesman') : (e.status === 404 ? t('voucher-invalid-params') : t('voucher-check-failed'))),
      })
    }
    setLoading(() => false)
  }


  return (
    <>
      <div className={'container'}>
      <div className={'content-wrapper'}>
        <div className={'form-wrapper'}>
          <div className={'title-content'}>
            <h3>{t('lookup-title')}</h3>
            <p>{t('lookup-desc')}</p>
          </div>
          <Form
            defaultState={{
              code: { value: '', error: null },
              studentIdNumber: { value: '', error: null },
              parentIdNumber: { value: '', error: null },
            }}
            provideValidationRules={provideValidationRules}
            onValidFormSubmit={onSubmit}
            onValueChange={onFormValueChange}
          >
            <FormField label={t('lookup-code')} name={'code'} type={'text'} disabled={step !== stepVoucherLookup} />
            {step > stepVoucherLookup && <>
              <div className={'row'}>
                <div className={'col-12 col-md-6'}>
                  <FormField label={`${t('lookup-student-idnumber')} *`} name={'studentIdNumber'} type={'text'} hint={t('hint-idnumber')} />
                </div>
                <div className={'col-12 col-md-6'}>
                  <FormField label={`${t(isUaVoucher ? 'lookup-parent-idnumber-ua' : 'lookup-parent-idnumber')}${isStudentAdult ? '' : ' *'}`} name={'parentIdNumber'} type={'text'} hint={t(isUaVoucher ? 'lookup-parent-idnumber-hint-ua' : 'hint-idnumber')} disabled={isStudentAdult} />
                </div>
              </div>
            </>}
            <Divider />
            {loading && <Loader />}
            {result !== null && <div className={result.success ? 'field-success' : 'field-error'}>{result.message}</div>}
            {!loading && <div><button type={'submit'} className={'btn btn-submit'}>{t('general-send')}</button></div>}
          </Form>
        </div>
      </div>
      </div>
    </>
  )
}

export default withTranslation()(VoucherLookupPage)