import React, {useEffect, useImperativeHandle, useState} from 'react'
import { FormReducer }                from './FormReducer'
import { FormProvider }    from './FormProvider'
import { useImmerReducer } from 'use-immer'

export const ValidationRules = {
  anyName: /^(.{2,255})$/,
  anyNameOrEmpty: /^$|^(.{2,255})$/,
  anyNameLatin: /^[A-Za-z ]{2,255}$/,
  anyNameLatinDiacritics: /^[\u0041-\u005A\u0061-\u007A\u00C0-\u00FF\u0100-\u017F ]{2,255}$/,
  address: /^(.{2,255})$/,
  addressOrEmpty: /^$|^(.{2,255})$/,
  idNumber: /^([0-9]{6})(\/?[0-9]{3,4})$/,
  icoNumber: /^([0-9]{6})([0-9]{2})?$/,
  postCode: /^([\w \/\-]{2,10})$/,
  postCodeOrEmpty: /^$|^([\w \/\-]{2,10})$/,
  email: /^[^@]+@[^@]+\.(.+)$/,
  emailOptional: /^$|^[^@]+@[^@]+\.(.+)$/,
  email2: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  email2Optional: /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})?$/,
  phone: /^\+?([\d\s]{10,})$/,
  phoneOptional: /^$|^\+?([\d\s]{10,})$/,
  verificationCode: /^([\d]{4,10})$/,
  tutorialCode: /^([\w\-]{6,12})$/,
  voucherCode: /^([\w\-]{6,12})$/,
  notEmpty: /^.+$/,
  password: /^.{6,}$/,
  date: /^\d{4}-\d{2}-\d{2}$/,
  priceOrEmpty: /^$|^[\d]+([,.][\d]+)?$/,
  digitsMultiline: /^([\d]+\s?)+$/,
  ifo: /^\d{10}$/,
  ifoNotRequired: /^(\d{10})?$/,
  ifoUniversal: /^[a-zA-Z0-9/]{8,12}$/,
  passport: /^[A-Za-z]{2}\d{6}$/,
  drivingLicense: /^(?:[A-Za-z]{3}\d{6}|[A-Za-z]\d{7})$/,
  phoneSkOrUa: /^(\+421\d{9}|\+380\d{9})$/,
  phoneSkOrUaOrEmpty: /^$|^(\+421\d{9}|\+380\d{9})$/,
}

const isFormValid = (rules, state, dispatch) => {
  let allRulesValid = true
  Object.keys(rules).forEach((key, index) => {
    let value = state.hasOwnProperty(key) ? state[key]?.value : ''
    let isValid = rules[key].regex.test(value)
    isValid &= (!rules[key].additionalValidation || rules[key].additionalValidation(value))
    dispatch({
      key: key,
      payload: {
        value: value,
        error: isValid ? null : rules[key].error
      }
    })
    allRulesValid &= isValid
  })
  return allRulesValid
}

const validateSingleField = (field, value, rules, state, dispatch) => {
  if (!rules?.[field]) return;
  let isValid = rules[field].regex.test(value)
  isValid &= (!rules[field].additionalValidation || rules[field].additionalValidation(value))
  dispatch({
    key: field,
    payload: {
      value: value,
      error: isValid ? null : rules[field].error
    }
  })
}

export const extractPrefixed = (prefix, data) => {
  let result = {}
  Object.keys(data).forEach((key) => {
    if (key.startsWith(`${prefix}`)) {
      let realKey = key.substr(prefix.length)
      result[realKey] = !!data[key]?.value ? String(data[key]?.value)?.trim() : null
    }
  })
  return result
}

export const Form = React.forwardRef((props, ref) => {
  const {
    children,
    onValidFormSubmit,
    provideValidationRules = () => ({}),
    defaultState = {},
    customValidateForm,
    onValueChange,
    onErrorFormSubmit = () => undefined
  } = props

  const [state, dispatch] = useImmerReducer(FormReducer, defaultState);
  const [submittingError, setSubmittingError] = useState(false)

  useImperativeHandle(ref, () => ({
    clear() {
      for (const [key, data] of Object.entries(state)) {
        dispatch({
          key: key,
          payload: {
            value: '',
            error: null
          }
        });
      }

      console.log('cleared state', )
    }
  }))

  const handleChange = (key, value) => {
    dispatch({
      key: key,
      payload: {
        value: value,
        error: null
      }
    });
    if (!!onValueChange) {
     onValueChange(key, value, state, dispatch)
    }
  }

  const handleValidate = (key, value) => {
    validateSingleField(key, value, provideValidationRules(), state, dispatch)
  }

  const submitForm = (e) => {
    e.preventDefault();
    const submitterValue = e?.nativeEvent?.submitter?.value
    let formValid = isFormValid(provideValidationRules(), state, dispatch, submitterValue)
    formValid &= (!customValidateForm || customValidateForm(state, dispatch, submitterValue))
    if (formValid) {
      onValidFormSubmit(state, submitterValue)
    } else {
      // TODO state is not updated (no error from prev lines validation)
      // watch state changes in useEffect
      setSubmittingError(true)
    }
  }

  useEffect(() => {
    if (submittingError){
      setSubmittingError(false)
      onErrorFormSubmit(state)
    }
  }, [state]);

  return (
    <form onSubmit={submitForm} autoComplete={'off'}>
      <FormProvider value={{ state, handleChange, handleValidate }}>
        {children}
      </FormProvider>
    </form>
  );
})
