import { withTranslation }            from 'react-i18next'
import { Form, ValidationRules }      from '../../components/form/Form'
import FormField                      from '../../components/FormField'
import { Divider }                    from '../../components/Divider'
import FormResult                             from '../../components/form/FormResult'
import React, { useEffect, useRef, useState } from 'react'
import { useParams, useNavigate }             from 'react-router-dom'
import { useApi }                     from '../../api'
import { User }                       from '../../consts'
import StoreChainSelect               from '../../components/StoreChainSelect'
import Loader                         from '../../components/Loader'

const UserPage = ({ t }) => {

  const api = useApi()
  const navigate = useNavigate()
  const { userEmail } = useParams()
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isSupervisor, setIsSupervisor] = useState(false)
  const [result, setResult] = useState(null)
  const formRef = useRef()

  const creating = userEmail === 'create'

  useEffect(() => {
    if (!!userEmail && !creating) {
      setLoading(() => true)
      api.admin.getUser(userEmail).then(response => {
        setUser(() => response)
        setIsSupervisor(() => response.flags & User.Flags.Supervisor)
      }).catch(error => {
        let msg = error.status === 404 ? t('user-not-found') : error.error
        setResult({ success: false, message: msg })
      }).finally(() => {
        setLoading(() => false)
      })
    } else {
      setUser({})
    }
  }, [userEmail])

  const onSubmit = (data, triggerKey) => {
    const formData = {
      id: user?.id ?? undefined,
      name: data.name.value,
      email: data.email.value,
      subject: { id: data.storeId.value },
      flags: isSupervisor ? 1 : 0,
      type: User.Type.Salesman,
    }
    setLoading(() => true)
    api.admin.saveUser(formData)
      .then((response) => {
        setResult({ success: true, message: t('user-saved') })
        if (triggerKey === 'next') {
          setUser(() => ({}))
          navigate(`/user/create`)
          formRef.current.clear()
        } else {
          navigate(`/user/${response.email}`)
        }
      })
      .catch((error) => {
        setResult({ success: false, message: error.error })
      })
      .finally(() => {
        setLoading(() => false)
      })
  }

  const onClearPasswordClick = (e) => {
    if (window.confirm(t('user-clear-password-confirm'))) {
      setLoading(() => true)
      api.admin.clearPassword(user?.id).then(() => {
          setResult({ success: true, message: t('user-password-cleared') })
        })
        .catch((error) => {
          setResult({ success: false, message: error.error })
        }).finally(() => {
          setLoading(() => false)
        })
    }
  }

  const onBlockUserClick = (e) => {
    if (window.confirm(t('user-block-confirm'))) {
      setLoading(() => true)
      api.admin.blockUser(user?.id).then(() => {
          setResult({ success: true, message: t('user-blocked') })
        })
        .catch((error) => {
          setResult({ success: false, message: error.error })
        }).finally(() => {
          setLoading(() => false)
        })
    }
  }

  return (
    <div className={'container'}>
      <div className={'content-wrapper'}>
        <div className={'title-content mb-4'}>
          <h3>{creating ? t('user-create-title') : (t('user-edit-title') + ` (${user?.type})`)}</h3>
        </div>
        {loading && <Loader />}
        <div className={'form-wrapper'}>
          {user && <Form
            ref={formRef}
            defaultState={{
              name: { value: user?.name ?? '', error: null },
              email: { value: user?.email ?? '', error: null },
              storeId: { value: (user?.type === User.Type.Salesman ? user?.subject?.id : undefined) ?? '', error: null },
              storeChainId: { value: (user?.type === User.Type.Salesman ? user?.subject?.parent?.id : user?.type === User.Type.StoreChain ? user?.subject?.id : undefined) ?? '', error: null },
            }}
            provideValidationRules={() => ({
              'name': { regex: ValidationRules.notEmpty, error: 'validation-invalid-name' },
              'email': { regex: ValidationRules.email, error: 'validation-invalid-email' },
            })}
            onValidFormSubmit={onSubmit}
          >
            { (!user?.id || user?.type === User.Type.Salesman || user?.type === User.Type.StoreChain) &&
              <StoreChainSelect key={'store-chain-select'} hideStorePicker={user?.type === User.Type.StoreChain} />
            }
            <div className={'row'}>
              <div className={'col-12 col-md-6'}>
                <FormField label={t('user-name')} name={'name'} type={'text'} />
              </div>
              <div className={'col-12 col-md-6'}>
                <FormField label={t('user-email')} name={'email'} type={'email'} />
              </div>
            </div>
            { user?.type === User.Type.Salesman && <div className={'row'}>
              <div className={'col-12'}>
                <div className="form-check my-3">
                  <input type="checkbox" className="form-check-input" id="checkbox-supervisor" checked={isSupervisor} onChange={(e) => [
                    setIsSupervisor(() => e.target.checked)
                  ]} />
                  <label className="form-check-label" htmlFor="checkbox-supervisor">{t('user-supervisor')}</label>
                </div>
              </div>
            </div> }
            <Divider />
            {user?.disabled === true && <div className="alert alert-danger" role="alert">
              {t('user-disabled-alert')}
            </div>}
            <FormResult result={result} />
            <div className={'d-flex justify-content-between gap-2'}>
              <div className={'d-flex gap-2'}>
                { !user?.id || user?.type === User.Type.Salesman ? <>
                  <button type={'submit'} value={'submit'} className={'btn btn-primary'}>{t('general-save')}</button>
                  <button type={'submit'} value={'next'} className={'btn btn-outline-primary'}>{t('general-save-and-insert-next')}</button>
                </> : <span>{t('user-not-salesman')}</span> }
              </div>
              {user?.id && <div className={'d-flex gap-2'}>
                {!user?.disabled && <button type={'button'} className={'btn btn-outline-danger'} onClick={onBlockUserClick}>{t('user-block')}</button>}
                {user?.type === User.Type.Salesman && user?.tutorialCode && <button type={'button'} className={'btn btn-outline-warning'} onClick={onClearPasswordClick}>{t('user-clear-password')}</button>}
                {user?.type === User.Type.Salesman && !user?.tutorialCode && <span>{t('user-not-activated')}</span>}
              </div>}
            </div>
          </Form>}
          {!user && <FormResult result={result} />}
        </div>
      </div>
    </div>)
}

export default withTranslation()(UserPage)