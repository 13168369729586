import BirthNumber         from './birth_number'
import { ValidationRules } from '../components/form/Form'
import Consts              from '../consts'

const idNumberValidation = (val) =>  new BirthNumber(val).isValid()
const idNumberOrIcoValidation = (val) =>  new BirthNumber(val).isValid() || ValidationRules.icoNumber.test(val)
const VALIDATION_RULES_STUDENT = (isAdult) => ({
  'student.name': { regex: ValidationRules.anyName, error: 'validation-invalid-name' },
  'student.surname': { regex: ValidationRules.anyName, error: 'validation-invalid-surname' },
  'student.idNumber': { regex: ValidationRules.idNumber, error: 'validation-invalid-idnumber', additionalValidation: idNumberValidation },
  'student.town': { regex: ValidationRules.anyName, error: 'validation-invalid-town' },
  'student.address': { regex: ValidationRules.address, error: 'validation-invalid-address' },
  'student.postcode': { regex: ValidationRules.postCode, error: 'validation-invalid-postcode' },
  'student.email': { regex: isAdult ? ValidationRules.email : ValidationRules.emailOptional, error: 'validation-invalid-email' },
  'student.phone': { regex: ValidationRules.phoneOptional, error: 'validation-invalid-phone' },
})
const VALIDATION_RULES_PARENT_INSTITUTION = {
  'parent1.name': { regex: ValidationRules.anyName, error: 'validation-invalid-name' },
  'parent1.idNumber': { regex: ValidationRules.icoNumber, error: 'validation-invalid-ico' },
  'parent1.phone': { regex: ValidationRules.phone, error: 'validation-invalid-phone' },
}
const VALIDATION_RULES_PARENT1 = {
  'parent1.name': { regex: ValidationRules.anyName, error: 'validation-invalid-name' },
  'parent1.surname': { regex: ValidationRules.anyName, error: 'validation-invalid-surname' },
  'parent1.idNumber': { regex: ValidationRules.idNumber, error: 'validation-invalid-idnumber', additionalValidation: idNumberValidation },
  'parent1.town': { regex: ValidationRules.anyName, error: 'validation-invalid-town' },
  'parent1.address': { regex: ValidationRules.address, error: 'validation-invalid-address' },
  'parent1.postcode': { regex: ValidationRules.postCode, error: 'validation-invalid-postcode' },
  'parent1.email': { regex: ValidationRules.emailOptional, error: 'validation-invalid-email' },
  'parent1.phone': { regex: ValidationRules.phone, error: 'validation-invalid-phone' },
}
const VALIDATION_RULES_PARENT2 = {
  'parent2.name': { regex: ValidationRules.anyName, error: 'validation-invalid-name' },
  'parent2.surname': { regex: ValidationRules.anyName, error: 'validation-invalid-surname' },
  'parent2.idNumber': { regex: ValidationRules.idNumber, error: 'validation-invalid-idnumber', additionalValidation: idNumberValidation },
  'parent2.town': { regex: ValidationRules.anyName, error: 'validation-invalid-town' },
  'parent2.address': { regex: ValidationRules.address, error: 'validation-invalid-address' },
  'parent2.postcode': { regex: ValidationRules.postCode, error: 'validation-invalid-postcode' },
  'parent2.email': { regex: ValidationRules.emailOptional, error: 'validation-invalid-email' },
  'parent2.phone': { regex: ValidationRules.phoneOptional, error: 'validation-invalid-phone' },
}
const VALIDATION_RULES_SUBJECT_PERSON = {
  'name': { regex: ValidationRules.anyName, error: 'validation-invalid-name' },
  'surname': { regex: ValidationRules.anyNameOrEmpty, error: 'validation-invalid-surname' },
  'idNumber': { regex: ValidationRules.idNumber, error: 'validation-invalid-idnumber', additionalValidation: idNumberOrIcoValidation },
  'town': { regex: ValidationRules.anyNameOrEmpty, error: 'validation-invalid-town' },
  'address': { regex: ValidationRules.anyNameOrEmpty, error: 'validation-invalid-address' },
  'postcode': { regex: ValidationRules.anyNameOrEmpty, error: 'validation-invalid-postcode' },
  'email': { regex: ValidationRules.emailOptional, error: 'validation-invalid-email' },
  'phone': { regex: ValidationRules.phoneOptional, error: 'validation-invalid-phone' },
  'idCard': { regex: ValidationRules.anyNameOrEmpty, error: 'validation-cant-be-empty' },
}
const VALIDATION_RULES_SUBJECT_INSTITUTION = {
  'name': { regex: ValidationRules.anyName, error: 'validation-invalid-name' },
  'idNumber': { regex: ValidationRules.icoNumber, error: 'validation-invalid-ico' },
  'phone': { regex: ValidationRules.phone, error: 'validation-invalid-phone' },
}
const VALIDATION_RULES_VOUCHER_MODEL = {
  'model': { regex: ValidationRules.anyNameOrEmpty, error: 'validation-invalid-value' },
  'serialNumber': { regex: ValidationRules.anyNameOrEmpty, error: 'validation-invalid-value' },
  'description': { regex: ValidationRules.anyNameOrEmpty, error: 'validation-invalid-value' },
  'descriptionAccessories': { regex: ValidationRules.anyNameOrEmpty, error: 'validation-invalid-value' },
  'priceDevice': { regex: ValidationRules.priceOrEmpty, error: 'validation-invalid-value' },
  'priceAccessories': { regex: ValidationRules.priceOrEmpty, error: 'validation-invalid-value' },
  'reference': { regex: ValidationRules.anyNameOrEmpty, error: 'validation-invalid-value' },
}
const VALIDATION_ADDRESS_STUDENT = {
  'student.town': { regex: ValidationRules.anyName, error: 'validation-invalid-town' },
  'student.address': { regex: ValidationRules.addressOrEmpty, error: 'validation-invalid-address' },
  'student.postcode': { regex: ValidationRules.postCodeOrEmpty, error: 'validation-invalid-postcode' },
}
const VALIDATION_ADDRESS_CONTRACT_SUBJECT = {
  'contractSubject.town': { regex: ValidationRules.anyName, error: 'validation-invalid-town' },
  'contractSubject.address': { regex: ValidationRules.addressOrEmpty, error: 'validation-invalid-address' },
  'contractSubject.postcode': { regex: ValidationRules.postCodeOrEmpty, error: 'validation-invalid-postcode' },
}

const REASON_OPTIONS = [
  { label: 'reason0' },
  { label: 'reason1' },
  { label: 'reason2' },
  { label: 'reason3' },
]
const CONSENTS = [
  { label: 'consent-title0', required: true, info: 'consent0', error: 'consent-required' },
  { label: 'consent-title1', required: true, info: 'consent1', error: 'consent-required' },
  { label: 'consent-title2', required: true, info: 'consent2', error: 'consent-required' },
  { label: 'consent-title3', required: false, info: 'consent3', error: 'consent-required' },
]

const REQUIRED_FILES = [
  { fileName: Consts.VoucherAttachment.nameContract, mimeType: null, base64: null, required: true },
  { fileName: Consts.VoucherAttachment.nameInvoice, mimeType: null, base64: null, required: true },
  { fileName: Consts.VoucherAttachment.nameAcceptanceProtocol, mimeType: null, base64: null, required: true }
]

const sanitizeRequiredFiles = (files) => {
  let result = files ?? []
  REQUIRED_FILES.forEach((file) => {
    if (!result.some(resultFile => resultFile.fileName === file.fileName)) {
      result.push(file)
    }
  })
  return result
}

const hasAllRequiredFilesFilled = (files) => {
  return REQUIRED_FILES.every((file) => files.some((f) => f.fileName === file.fileName && (!!f.base64 || f.id)))
}

export { idNumberValidation, sanitizeRequiredFiles, hasAllRequiredFilesFilled,
  VALIDATION_RULES_STUDENT,
  VALIDATION_RULES_PARENT1, VALIDATION_RULES_PARENT2, VALIDATION_RULES_PARENT_INSTITUTION,
  REASON_OPTIONS, CONSENTS, VALIDATION_RULES_SUBJECT_PERSON, VALIDATION_RULES_VOUCHER_MODEL,
  REQUIRED_FILES,
  VALIDATION_ADDRESS_STUDENT, VALIDATION_ADDRESS_CONTRACT_SUBJECT, VALIDATION_RULES_SUBJECT_INSTITUTION
}
