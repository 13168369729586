export default function PrivacyPolicyPage() {
  return <div className={'container'}>
    <div className={'content-wrapper'}>
    <h1>PODMIENKY OCHRANY OSOBNÝCH ÚDAJOV</h1>
    <h3>KTO SME?</h3>
    <p>Národná koalícia pre digitálne zručnosti a povolania Slovenskej republiky je záujmové združenie právnických osôb založené Memorandom členov digitálnej koalície 26. septembra 2017, so sídlom Mlynské nivy 18890/5, 82109 BratislavaRužinov, Slovenská republika, IČO: 52 828 123, zapísané v Registri záujmových združení právnických osôb Okresného úradu Bratislava (ďalej „Digitálna koalícia“).</p>
    <p>Podľa článku 13 a článku 14 Nariadenia Európskeho parlamentu a Radu (EÚ) 2016/679 z 27. apríla 2016 o ochrane fyzických osôb pri spracúvaní osobných údajov a o voľnom pohybe takýchto údajov, ktorým sa zrušuje smernica 95/46/ES (ďalej ako „GDPR“) Vám Digitálna koalícia ako prevádzkovateľ  týmto ako dotknutej osobe poskytuje informácie o účele spracúvania osobných údajov, na ktorý sú osobné údaje určené a to aj v prípade, keď sa osobné údaje nezískavajú priamo od dotknutej osoby.</p>
    <p>Je potrebné, aby ste sa s týmito informáciami oboznámili, aby ste týmto informáciám porozumeli a to najneskôr pri získavaní Vašich osobných údajov.</p>
    <p>Cieľom Digitálnej koalície je dosiahnuť, aby vzdelávanie v oblasti digitálnych technológií bolo aktuálne, efektívne a poskytovalo každému občanovi Slovenskej republiky príležitosť rozvíjať v priebehu celého života vlastné digitálne zručnosti tak, aby bol úspešný na trhu práce a bol schopný využívať digitálne technológie na zabezpečenie spokojného života.</p>
    <p>Digitálna koalícia je prijímateľom nenávratného finančného príspevku z Operačného programu Integrovaná infraštruktúra 2014 – 2020 a realizátorom národného projektu <i>„Digitálny príspevok pre žiakov Slovenskej republiky“</i> (ďalej ako <b>„Projekt“</b>), ktorého <b>predmetom je vybavenie cieľových skupín Projektu digitálnym vybavením z príspevku poskytnutého na nákup digitálneho zariadenia.</b> Digitálny príspevok bude poskytnutý zákonným zástupcom žiakov, resp. plnoletým žiakom, ktorí (i) jednak spĺňajú kritériá stanovené pre jednu alebo viacero cieľových skupín a (ii) zároveň splnenie kritérií stanovených pre niektorú z cieľových skupín Projektu bolo overené verifikáciou údajov s údajmi v informačných systémoch verejnej správy. Bez overenia cieľovej skupiny užívateľov nie je možné zo strany Digitálnej koalície potvrdiť zaradenie žiadateľa medzi oprávnených príjemcov digitálneho príspevku. Na základe tohto oprávnenia prevádzkovateľ získava nevyhnutné osobné údaje od žiadateľov, žiakov a/alebo ich zákonných zástupcov a títo sú svoje osobné údaje v požadovanom rozsahu povinní poskytnúť. V opačnom prípade nebude možné overiť splnenie kritérií pre niektorú z cieľových skupín a následne zaradiť do databázy oprávnených príjemcov digitálneho príspevku.</p>
    <p>Cieľom Projektu je podporiť definované cieľové skupiny a to cestou poskytnutia digitálneho príspevku. Cieľom národného projektu je podpora rozvoja digitálnych zručností vo všeobecnosti a to prostredníctvom podpory nákupu digitálneho vybavenia. Prostredníctvom vybavenia cieľových skupín potrebným digitálnym vybavením a softvérom sa aktívne nepriamo podporí využívanie digitálnych technológií aj vo vzdelávacom procese.</p>
    <p>Projekt má za cieľ aj zlepšiť inklúziu a prístup k digitálnym technológiám pre žiakov zo sociálne znevýhodneného prostredia. Účelom národného projektu je znížiť sociálne rozdiely medzi žiakmi, posilniť digitálne zručnosti u všetkých skupín žiakov, poskytnúť cieľovej skupine príspevok na nákup digitálneho vybavenia pre žiakov.</p>

    <h3>TRVALÝ ZÁVÄZOK K OCHRANE SÚKROMIA</h3>
    <p>Rešpektujeme súkromie všetkých fyzických osôb, ktorých osobné údaje spracúvame v rámci našej činnosti, a preto sa zaväzujeme chrániť Vaše súkromie a so všetkými informáciami, ktoré o Vás zhromažďujeme a uchovávame, budeme zaobchádzať zodpovedne.</p>
    <p>Oboznámením sa s týmito informáciami beriete na vedomie, že môžeme Vaše osobné údaje zhromažďovať, používať a prípadne ich poskytovať ďalším orgánom verejnej správy, ako aj tretím osobám v ďalej popísanom rozsahu a popísaným spôsobom.</p>
    <p>Je pre nás dôležité, aby ste rozumeli tomu, prečo a akým spôsobom nakladáme s Vašimi osobnými údajmi.</p>
    <p>Prečítajte si preto prosím nižšie uvedené informácie o tom, akým spôsobom Vaše osobné údaje získavame, ako s nimi ďalej nakladáme a aké sú Vaše ďalšie práva súvisiace so spracovaním Vašich osobných údajov Digitálnou koalíciou.</p>

    <h3>ČO SÚ TO OSOBNÉ ÚDAJE?</h3>
    <p>Za osobné údaje považujeme <b>akékoľvek informácie o identifikovanej alebo identifikovateľnej fyzickej osobe,</b> ako sú napríklad meno, priezvisko, e-mailová adresa, telefónne číslo, IP adresa, rodné číslo, dátum narodenia, informácia o príjme, a pod.</p>
    <p>Vaše údaje môžeme ako prevádzkovateľ spracúvať na rôzne účely, pričom právny základ pre spracúvanie, doba spracúvania a uchovávania sa môže líšiť v závislosti od účelu spracovateľských operácií.</p>

    <h3>AKÉ SÚ NAŠE KONTAKTNÉ ÚDAJE</h3>
    <p>Ak máte akékoľvek otázky alebo pripomienky týkajúce sa ochrany osobných údajov, môžete nás kontaktovať písomne, emailom, telefonicky na nasledovných kontaktných údajoch:<br/>
      Národná koalícia pre digitálne zručnosti a povolania Slovenskej republiky<br/>
      Mlynské nivy 18890/5<br/>
      82109 BratislavaRužinov<br/>
      Slovenská republika<br/>
      Email: <a href={'mailto:gdpr@digitalnyziak.sk'}>gdpr@digitalnyziak.sk</a><br/>
      Kontaktná na zodpovednú osobu: <a href={'mailto:gdpr@digitalnyziak.sk'}>gdpr@digitalnyziak.sk</a><br/>
    </p>
    <p>V záujme posilnenia záruky a právnej garancie Vašich práv a slobôd pri spracúvaní Vašich osobných údajov sme poverili zodpovednú osobu, dohľadom nad zákonnosťou spracúvania Vašich osobných údajov.</p>
    <p>Zodpovedná osoba je vaším kontaktným bodom pre akékoľvek otázky či prípadné žiadosti týkajúce sa ochrany osobných údajov a pre uplatnenie Vašich práv. Môžete ju kontaktovať na vyššie uvedenom telefónnom čísle, emailovej adrese, alebo písomne; písomnú správu vždy označte „k rukám zodpovednej osoby“.</p>

    <h3>OCHRANA OSÔB MLADŠÍCH AKO 16 ROKOV</h3>
    <p>Pokiaľ máte menej ako 16 rokov, požiadajte prosím skôr, ako nám poskytnete akékoľvek osobné údaje o sebe alebo zákonných zástupcoch, svojich zákonných zástupcov o udelenie súhlasu. Bez udelenia takéhoto súhlasu nie ste oprávnení nám Vaše osobné údaje poskytnúť.</p>

    <h3>AKÉ KATEGÓRIE OSOBNÝCH ÚDAJOV O VÁS SPRACÚVAME?</h3>
    <p>V súvislosti s Vašou participáciou na projekte Digitálny príspevok pre žiakov SR a pri poskytnutí digitálneho príspevku spracúvame osobné údaje o nasledovných kategóriách dotknutých osôb:
      <ul>
        <li>žiak ako cieľová skupina Projektu / oprávnený príjemca pomoci digitálneho príspevku,</li>
        <li>zákonný zástupca / zákonní zástupcovia žiaka,</li>
        <li>členovia domácnosti žiaka.</li>
      </ul>
    </p>
    <p>Medzi cieľové skupiny Projektu zaraďujeme nasledovné kategórie žiakov:
      <ul>
        <li>žiaci, ktorí sú členmi domácnosti v hmotnej núdzi (príjem členov domácnosti nedosahuje sumy životného minima);</li>
        <li>žiaci, ktorí majú vyjadrenie zariadenia poradenstva a prevencie o tom, že ich špeciálne výchovno-vzdelávacie potreby vyplývajú výlučne z ich vývinu v sociálne znevýhodnenom prostredí;</li>
        <li>žiaci, ktorí sú členmi domácností, ktoré neprekračujú 60% medián príjmu (na základe potvrdenia o výške príjmu, resp. doklad o nevykonávaní zárobkovej činnosti (napr. potvrdenie o nezamestnanej osobe);</li>
        <li>žiaci prvého ročníka stredných škôl (pre rok 2022/2023) alebo žiaci piateho ročníka v osemročnom vzdelávacom programe gymnázia.</li>
      </ul>
    </p>
    <p>V súvislosti s Vašou participáciou na projekte Digitálny príspevok pre žiakov SR spracúvame najmä nasledovné kategórie osobných údajov:
      <ul>
        <li>identifikačné a kontaktné údaje žiakov a zákonných zástupcov: a to najmä meno, priezvisko, adresa trvalého bydliska, rodné číslo, číslo občianskeho preukazu alebo iného identifikačného dokladu, telefónne číslo a emailovú adresu; eduID žiaka; v prípade ak je zákonným zástupcom žiaka právnická osoba, spracúvame údaje v rovnakom rozsahu aj o štatutárnych zástupcoch tejto právnickej osoby;</li>
        <li>informácie o oprávnenosti splnenia niektorého z kritérií pre zaradenie do cieľovej skupiny Projektu: údaj o škole, ktorú žiak navštevuje, konkrétne jej názov, adresu a eduID; údaj o tom, či je dieťa žiakom prvého ročníka stredných škôl v roku 2022/2023; údaj, o tom, či je žiak členom domácnosti v hmotnej núdzi; alebo údaj, o tom, či je žiak členom domácnosti členom domácnosti, ktorá neprekračuje 60% medián príjmu, a/alebo či je dieťa žiakom, ktorý má vyjadrenie zariadenia poradenstva a prevencie o tom, že jeho špeciálne výchovnovzdelávacie potreby vyplývajú výlučne z ich vývinu v sociálne znevýhodnenom prostredí;</li>
        <li>informácie o overení splnenia niektorého z kritérií pre zaradenie do cieľovej skupiny poskytnuté zo strany orgánov štátnej správy (najmä Ministerstvo investícií, regionálneho rozvoja a informatizácie SR, ďalej „MIRRI“);</li>
        <li>akékoľvek iné osobné údaje, ktoré nám o sebe poskytnete;</li>
        <li>údaj o čerpaní, nečerpaní príspevku na nákup digitálneho zariadenia;</li>
        <li>overenie dokladu totožnosti plnoletej osoby (žiaka, prípadne zákonného zástupcu), ktorá uplatnila u predajcu čerpanie digitálneho príspevku v mene oprávneného príjemcu;</li>
        <li>informácie o čerpaní digitálneho príspevku a kúpe digitálneho zariadenia.</li>
      </ul>
    </p>
    <p>Kategórie osobných údajov: všeobecné osobné údaje</p>

    <h3>AKO SPRACÚVAME VAŠE OSOBNÉ ÚDAJE?</h3>
    <p>Spracúvanie sa nevykonáva v listinnej podobe. Spracúvanie sa vykonáva v informačnom systéme prevádzkovateľa.</p>

    <h3>AKO ZÍSKAVAME VAŠE OSOBNÉ ÚDAJE?</h3>
    <p>Osobné údaje získavame priamo od Vás, pokiaľ v tomto dokumente ďalej nie je výslovne uvedené inak.
      <ul>
        <li>osobné údaje spracúvame na nasledovných právnych základoch:</li>
        <li>na základe zmluvných a predzmluvných vzťahov (čl. 6 ods. 1 písm. b) GDPR);</li>
        <li>na základe oprávnených záujmov (čl. 6 ods. 1 písm. f) GDPR);</li>
        <li>na základe plnenia zákonnej povinnosti (čl. 6 ods. 1 písm. c) GDPR);</li>
        <li>na základe splnenie úlohy realizovanej vo verejnom záujme alebo pri výkone verejnej moci zverenej prevádzkovateľovi (čl. 6 ods. 1 písm. e) GDPR);</li>
        <li>na základe súhlasu (čl. 6 ods. 1 písm. a) GDPR).</li>
      </ul>
    </p>
    <p>Vaše osobné údaje ste povinní poskytnúť, ak je spracúvanie osobných údajov nevyhnutné v súvislosti s plnením zákonnej povinnosti prevádzkovateľa. Taktiež ste povinní poskytnúť osobné údaje v prípadoch, kedy je ich poskytnutie zmluvnou požiadavkou vyplývajúcou zo zmluvy uzatvorenej medzi Vami a prevádzkovateľom.</p>
    <p>Poskytnutie Vašich osobných údajov v rámci predzmluvných a zmluvných vzťahov je potrebné, v opačnom prípade by ich neposkytnutie mohlo znemožniť Vašu účasť na Projekte, uzatvorenie zmluvy s Vami, príp. jej plnenie.</p>
    <p>Ak je právnym základom spracúvania osobných údajov súhlas (napr. súhlas na marketingové a propagačné účely), udelenie súhlasu je dobrovoľné. V prípade, že spracúvame Vaše osobné údaje na základe Vášho súhlasu, máte právo svoj súhlas kedykoľvek odvolať. Odvolanie súhlasu nemá vplyv na zákonnosť spracúvania založeného na súhlase uvedenom pred jeho odvolaním. Odvolanie súhlasu nie je spoplatnené, ani inak sankcionované.</p>
    <p>V prípade, ak spracúvame Vaše osobné údaje na základe oprávneného záujmu, máte právo kedykoľvek namietať z dôvodov týkajúcich sa Vašej konkrétnej situácie proti spracúvaniu Vašich osobných údajov založenom na tomto základe vrátane namietania proti profilovaniu založenému na oprávnenom záujme (čl. 21 GDPR). Prevádzkovateľ v takomto prípade nesmie ďalej spracúvať osobné údaje, pokiaľ nepreukáže nevyhnutné oprávnené dôvody, ktoré prevažujú nad Vašimi záujmami, právami a slobodami ako dotknutej osoby, alebo dôvody na preukazovanie, uplatňovanie alebo obhajovanie našich právnych nárokov.</p>

    <h3>NA AKÉ ÚČELY A NA AKOM PRÁVNOM ZÁKLADE SPRACÚVAME VAŠE OSOBNÉ ÚDAJE?</h3>
    <p>Vaše údaje budeme spracúvať na nasledovné účely: </p>
    <p>Tu nájdete zoznam účelov, ich právne základy a dobu uchovávania Vašich osobných údajov:</p>
    <table>
      <tr>
        <th>Účel</th>
        <th>Bližší popis</th>
        <th>Právny základ</th>
        <th>Doba uchovávania</th>
      </tr>
      <tr>
        <td>REGISTRÁCIA DO PROJEKTU<br/><br/>
          (participácia na Projekte Digitálny príspevok pre žiakov SR, t.j. zaslanie a spracovanie žiadosti o registráciu)</td>
        <td>
          <p>Údaje o Vás ako zákonnom zástupcovi a žiakovi ako o  žiadateľovi/príjemcovi digitálneho príspevku, prípadne členoch domácnosti žiaka, spracúvame za účelom:</p>
          <ul>
            <li>podania žiadosti o registráciu medzi oprávnených príjemcov digitálneho príspevku,</li>
            <li>overenia Vami poskytnutých údajov vo verejných registroch, výsledkom ktorého je posúdenie splnenia kritérií niektorej z cieľových skupín vo vzťahu k zaradeniu žiaka medzi oprávnených príjemcov digitálneho príspevku,</li>
            <li>zaradenie žiaka medzi oprávnených príjemcov digitálneho príspevku,</li>
            <li>notifikácie zákonného zástupcu/žiaka, o zaradení do zoznamu oprávnených príjemcov a možnosti uplatnenia digitálneho príspevku v sieti zazmluvnených predajcov,</li>
            <li>komunikácie s Vami,</li>
            <li>poskytnutia informácií vo vzťahu k možnostiam čerpania digitálneho príspevku,</li>
            <li>overenia identity plnoletej osoby uplatňujúcej čerpanie digitálneho príspevku u predajcu,</li>
            <li>poskytnutia digitálneho príspevku,</li>
            <li>plnenia práv a povinností vyplývajúcich zo zmluvy o participácii žiaka na Projekte,</li>
            <li>kontroly plnenia podmienok čerpania digitálneho príspevku,</li>
            <li>poskytovania súčinnosti Digitálnej koalícii.</li>
          </ul>
          <p>Aké osobné údaje budú na tento účel spracúvané závisí od toho, ktorú kategóriu v rámci cieľových skupín označíte, že spĺňate a do veľkej miery aj od toho, aké osobné údaje Vy sami sprístupníte na stránke alebo počas vzájomnej komunikácie.</p>
        </td>
        <td>predzmluvné vzťahy / zmluvný vzťah</td>
        <td>10 rokov po ukončení zmluvného vzťahu</td>
      </tr>
      <tr>
        <td>POSKYTNUTIE DIGITÁLNEHO PRÍSPEVKU<br/><br/>
          (poskytnutie digitálneho príspevku a plnenie zmluvy o poskytnutí digitálneho príspevku)</td>
        <td>
          <p>Údaje o Vás ako zákonnom zástupcovi a žiakovi ako oprávnenom príjemcovi digitálneho príspevku, spracúvame za účelom:</p>
          <ul>
            <li>komunikácie v predzmluvných vzťahoch,</li>
            <li>uzavretia zmluvy o poskytnutí digitálneho príspevku prostredníctvom siete zazmluvnených predajcov,</li>
            <li>poskytnutia digitálneho príspevku,</li>
            <li>plnenia práv a povinností vyplývajúcich zo zmluvy o poskytnutí digitálneho príspevku,</li>
            <li>kontroly plnenia podmienok použitia digitálneho príspevku,</li>
            <li>overenia totožnosti zmluvných strán a osoby preberajúcej digitálne zariadenie,</li>
            <li>poskytovania súčinnosti Digitálnej koalícii.</li>
          </ul>
        </td>
        <td>predzmluvné vzťahy / zmluvný vzťah </td>
        <td>10 rokov po ukončení zmluvného vzťahu</td>
      </tr>
      <tr>
        <td>OVEROVANIE CIEĽOVEJ SKUPINY UŽÍVATEĽOV<br/><br/>
          (poskytovanie údajov vyplývajúcich z predloženej žiadosti o poskytnutie digitálneho príspevku orgánom verejnej moci za účelom overovania splnenia kritérií pre zaradenie do databázy oprávnených príjemcov digitálneho príspevku)</td>
        <td>
          <p>Údaje, ktoré nám poskytnete v rámci registračného formulára za účelom zapojenia sa do Projektu, poskytujeme na základe Vášho súhlasu vybraným orgánom verejnej správy, ktoré v rámci vykonávania zverených úloh a presadzovania verejného záujmu, budú spracúvať Vaše údaje za účelom overenia splnenia kritérií niektorej z cieľových skupín Projektu v informačných systémoch verejnej správy, poskytnutia tejto informácie o overení splnenia podmienok realizátorovi Projektu, čo bude slúžiť ako predpoklad pre zaradenie žiaka medzi oprávnených príjemcov Digitálneho príspevku a plnenie našich povinností v zmysle Zmluvy o participácii žiaka na Projekte.</p>
          <p>Udelenie súhlasu s (i) poskytnutím údajov zo strany Digitálnej koalície orgánom verejnej správy za účelom vykonania overenia, (ii) spracúvania poskytnutých údajov orgánmi verejnej správy vykonávajúcimi overenie splnenia podmienok cieľovej skupiny Projektu a následné poskytnutie výsledku overenia Digitálnej koalícii je nevyhnutnou podmienkou plnenia Zmluvy o participácii žiaka na Projekte. V prípade odvolania tohto súhlasu, resp. neudelenia tohto súhlasu sa nebudete môcť zúčastniť Projektu a/alebo zaradiť medzi oprávnených príjemcov digitálneho príspevku.</p>
          <p>Overovanie cieľovej skupiny užívateľov vykonáva Digitálna koalícia ako spoločný prevádzkovateľ s MIRRI, ktoré spracúva osobné údaje dotknutých osôb a splnenie úlohy realizovanej vo verejnom záujme alebo pri výkone verejnej moci zverenej MIRRI. Informácie o spracúvaní osobných údajov vo vzťahu k MIRRI sú dostupné na <a href={'https://www.mirri.gov.sk/ministerstvo/informacna-povinnost-prevadzkovatela/'} target={'_blank'}>https://www.mirri.gov.sk/ministerstvo/informacna-povinnost-prevadzkovatela/</a></p>
          <p>Spoloční prevádzkovatelia sa dohodli, že spoločným kontaktným miestom pre dotknuté osoby je Digitálna koalícia.</p>
          <p>Dohoda spoločných prevádzkovateľov je dostupná na: <a href={'https://digitalnyziak.sk/'} target={'_blank'}>www.digitalnyziak.sk</a></p>
        </td>
        <td>
          <p>súhlas (po overení, výsledok overenia bude spracúvaný na základe zmluvy na vyššie uvedený účel)</p>
          <p>MIRRI: splnenie úlohy realizovanej vo verejnom záujme alebo pri výkone verejnej moci zverenej prevádzkovateľovi.</p>
          <p>MIRRI SR koná na základe § 47 zákona č. 292/2014 o príspevku poskytovanom z európskych štrukturálnych a investičných fondov a o zmene a doplnení niektorých zákonov.</p>
          <p>MIRRI si plní svoju úlohu ako poskytovateľ národného projektu tým, že na základe požiadavky Európskej komisie overuje cieľovú skupinu užívateľov.</p>
          <p>Bez overenia cieľovej skupiny užívateľov nie je možné zo strany MIRRI potvrdiť oprávnenosť poskytnutia príspevku pre prijímateľa národného projektu– Digitálnu koalíciu.</p>
        </td>
        <td>10 rokov po jeho skončení zmluvného vzťahu s Digitálnou koalíciou alebo  (ii) do 31.12.2028, podľa toho, čo nastane neskôr</td>
      </tr>
      <tr>
        <td>PLNENIE POVINNOSTÍ<br/><br/>
          (plnenie povinností Digitálnej koalície ako prijímateľa NFP pri realizácií, monitorovaní Projektu, audite, kontrole a vykazovaní, archivácii) </td>
        <td>Údaje o Vás ako zákonnom zástupcovi a žiakovi ako o  žiadateľovi/príjemcovi digitálneho príspevku, plnení prípadne členoch domácnosti žiaka, spracúvame aj za účelom plnenia našich zmluvných a zákonných povinností.</td>
        <td>plnenie zákonných povinností a zmluvných povinností vyplývajúcich zo zmluvy o poskytnutí nenávratného finančného príspevku na realizáciu Projektu</td>
        <td>10 rokov od predloženia poslednej následnej monitorovacej správy poskytovateľovi NFP (MIRRI)</td>
      </tr>
      <tr>
        <td>ÚČTOVNÍCTVO A DAŇOVÁ AGENDA</td>
        <td>Účtovné doklady spracúva externá účtovná firma a niektoré údaje sú poskytnuté finančnej správe za účelom plnenia našich zákonných povinností.</td>
        <td>plnenie zákonných povinností</td>
        <td>10 rokov od vystavenia účtovného dokladu</td>
      </tr>
      <tr>
        <td>KLIENTSKA PODPORA<br/><br/>
          (odpovedanie na Vaše žiadosti a dopyty) </td>
        <td>
          <p>Môžete nás kontaktovať na našej linke podpory alebo emailovej adrese  klientskej podpory. Klientsku podporu poskytujeme prostredníctvom nášho externého partnera. Vaše osobné údaje (ak nám ich poskytnete) sú spracúvané pre účel zodpovedania otázok a vybavenia požiadaviek, napr. v prípade, ak nastal technický problém pri spracovaní Vašej žiadosti, ak ste nedostali potvrdenie o zaradení do zoznamu oprávnených príjemcov, v prípade duplicity žiadostí vo vzťahu k jednému žiakovi, v prípade potreby zmeny údajov, a pod.</p>
          <p>V prípade kontaktovania formou telefonickej linky bude telefonický hovor nahrávaný. Ak sa otázka či požiadavka týka existujúcej žiadosti/uzavretej zmluvy, právnym základom spracúvania osobných údajov je zmluvný vzťah vyplývajúci z podanej žiadosti.</p>
          <p>V opačnom prípade je právnym základom oprávnený záujem a doba uchovávania je kratšia.</p>
        </td>
        <td>zmluvný vzťah alebo oprávnený záujem (na poskytovaní kvalitnej klientskej podpory a riešení prípadných sťažností)</td>
        <td>10 rokov od ukončenia zmluvy alebo 1 rok od vykonaného kontaktu v prípade oprávneného záujmu (ak s nami následne uzavriete zmluvu, údaj bude uchovávaný rovnako ako v prípade, ak je základom zmluva)</td>
      </tr>
      <tr>
        <td>MARKETINGOVÉ A PROPAGAČNÉ ÚČELY<br/><br/>
          (zvyšovanie digitálnej gramotnosti)</td>
        <td>Za účelom informovania posielame zákonným zástupcom a/alebo žiakom občasné emaily, prípadne sms o našich projektoch, výzvach, akciách, kurzoch, podujatiach, novinkách alebo zaujímavých ponukách, ktorých cieľom je zvyšovanie digitálnej gramotnosti obyvateľstva.</td>
        <td>Váš predchádzajúci súhlas</td>
        <td>3 roky od udelenia súhlasu</td>
      </tr>
      <tr>
        <td>PREUKAZOVANIE, UPLATŇOVANIE ALEBO OBHAJOVANIE PRÁVNYCH NÁROKOV<br/><br/>
          (v prípade súdnych, správnych a iných konaní pred príslušnými orgánmi)</td>
        <td>Vaše údaje môžeme spracovávať aj v prípade uplatňovania alebo obhajovania právnych nárokov v prípade súdnych, správnych a iných konaní pred príslušnými orgánmi.</td>
        <td>predzmluvné vzťahy a plnenie zmluvných povinností – zlúčený účel</td>
        <td>Po dobu trvania prípadnej premlčacej doby, max. 10 rokov</td>
      </tr>
      <tr>
        <td>VYBAVOVANIE AGENDY PRÁV DOTKNUTÝCH OSÔB</td>
        <td>Vaše osobné údaje budeme spracúvať aj za účelom vybavovania agendy práv dotknutých osôb, ak sa na nás obrátite ako dotknutá osoba a uplatníte voči nám svoje práva vyplývajúce z predpisov na ochranu osobných údajov.</td>
        <td>plnenie zákonných povinností</td>
        <td>10 rokov od vybavenia žiadosti</td>
      </tr>
    </table>

    <h3>STE POVINNÍ NÁM POSKYTNÚŤ VAŠE OSOBNÉ ÚDAJE?</h3>
    <p>Pri výkone našej činnosti a realizácii Projektu od Vás požadujeme osobné údaje, ktoré spracúvame za rôznymi účelmi a spracúvanie vykonávame na rôznych právnych základoch.</p>
    <p>Poskytovanie osobných údajov je oprávnenou požiadavkou zo zákona v zmysle § 47 zákona č. 292/2014 Z.z. o príspevku poskytovanom z európskych štrukturálnych a investičných fondov a o zmene a doplnení niektorých zákonov. V prípade neposkytnutia osobných údajov dotknutá osoba nemôže byť zaradená do databázy oprávnených príjemcov na nákup digitálneho vybavenia pre žiaka.</p>
    <p>V prípade, ak je právnym základom pre spracúvanie Vašich osobných údajov plnenie našej zákonnej povinnosti podľa článku 6 ods. 1 písm. c) GDPR a Vy nám odmietnete poskytnúť Vaše osobné údaje, môže to viesť k vzniku škody, ktorej náhradu si môžeme voči Vám uplatňovať (napr. Vaše údaje sme povinný poskytnúť kontrolným orgánom pri vykonávaní finančnej kontroly použitia prostriedkov poskytnutých z nenávratného finančného príspevku).</p>
    <p>V prípade, ak je právnym základom pre spracúvanie Vašich osobných údajov uzatvorenie alebo plnenie zmluvného vzťahu a Vy nám odmietnete poskytnúť Vaše osobné údaje, môže to viesť k zmareniu možnosti vstúpiť do záväzného zmluvného právneho vzťahu s nami.</p>
    <p>V prípade, ak je právnym základom pre spracúvanie Vašich osobných údajov súhlas so spracúvaním podľa článku 6 ods. 1 písm. a) GDPR, môžete ho kedykoľvek odvolať, pričom ste oprávnení použiť taký istý spôsob, akým ste nám súhlas udeľovali. Udelenie súhlasu so spracúvaním osobných údajov na požadované účely nikdy nepodmieňujeme možnosťou uzavrieť s nami zmluvný vzťah.</p>
    <p>V prípade, ak je právnym základom pre spracúvanie Vašich osobných údajov prevaha našich oprávnených záujmov, a teda využívame na spracúvanie Vašich osobných údajov právny základ podľa článku 6 ods. 1 písm. f) GDPR, ste povinní toto spracúvanie strpieť, ale máte voči nemu právo podať námietku. Viac sa o tomto práve dozviete v osobitnej časti nižšie.</p>
    <p>V prípade, ak je právnym základom pre spracúvanie Vašich osobných údajov plnenie úlohy realizovanej vo verejnom záujme podľa čl. 6 ods. 1 písm. e) GDPR, sme oprávnení spracúvať Vaše osobné údaje za podmienok určených v tomto dokumente.</p>

    <h3>KTO OKREM DIGITÁLNEJ KOALÍCIE MÔŽE MAŤ PRÍSTUP K VAŠIM ÚDAJOM?</h3>
    <p>Vaše osobné údaje môžeme zdieľať s inými tretími stranami, a to s preverenými a zmluvne zaviazanými externými obchodnými partnermi, ktorí nám pomáhajú pri spracúvaní a ochrane Vašich osobných údajov. Ide o tzv. príjemcov a sprostredkovateľov, ktorí pre Digitálnu koalíciu vytvárajú a zabezpečujú funkčné a bezpečné systémy, poskytujú softvérové alebo iné služby, ktoré sú nevyhnutné pre spracúvanie Vašich osobných údajov na vyššie uvedené účely.</p>
    <p>Vaše osobné údaje sme povinní poskytnúť aj oprávneným orgánom verejnej moci v prípade výkonu ich zákonných oprávnení podľa príslušných osobitných zákonov (napr. vyšetrovanie polície, vykonávanie finančnej a administratívnej kontroly, a pod.), ktoré však nemajú postavenie príjemcov v zmysle GDPR.</p>
    <p>V súvislosti s plnením zákonných povinností prevádzkovateľa sú resp. môžu byť príjemcami Vašich osobných údajov subjekty určené právnymi predpismi, najmä daňový úrad, orgány štátnej správy a verejnej moci na výkon kontroly a dozoru, súdy a orgány činné v trestnom konaní.</p>
    <p>V závislosti od účelu spracúvania a konkrétnych okolností môžu patriť medzi príjemcov Vašich osobných údajov aj iné osoby (v pozícii sprostredkovateľa alebo samostatného prevádzkovateľa), najmä:
      <ul>
        <li>externí dodávatelia – služby call centra a klientskej podpory,</li>
        <li>poskytovatelia poštových a doručovacích služieb,</li>
        <li>externí dodávatelia programátorských prác a správy systému,</li>
        <li>spoločnosti, ktoré sú externými dodávateľmi účtovných, auditných alebo personalistických služieb,</li>
        <li>poskytovatelia služieb v oblasti marketingových činností,</li>
        <li>organizátori akcií, na ktoré sa prostredníctvom tejto stránky prihlásite,</li>
        <li>externí poradcovia, advokáti, súdy a exekútori,</li>
        <li>zodpovedná osoba v zmysle GDPR,</li>
        <li>zazmluvnení predajcovia digitálných zariadení financovaných z digitálneho príspevku,</li>
        <li>banky,</li>
        <li>spoločnosti poskytujúce služby správy registratúry a archívnictva,</li>
        <li>poskytovatelia hostingu, poskytovatelia služieb dátového centra,</li>
        <li>poskytovatelia cloudových služieb,</li>
        <li>školy, komunitné centrá,</li>
        <li>kontrolné orgány na vnútroštátnej úrovni, pričom oprávnenými osobami na výkon kontroly alebo auditu sú najmä:
          <ol type="i">
            <li>útvar vnútorného auditu Ministerstva dopravy Slovenskej republiky, Ministerstva financií Slovenskej republiky a Ministerstva investícií, regionálneho rozvoja a informatizácie Slovenskej republiky a nimi poverené osoby;</li>
            <li>Najvyšší kontrolný úrad Slovenskej republiky a ním poverené osoby;</li>
            <li>orgán auditu Úradu vládneho auditu a ním poverené osoby;</li>
            <li>splnomocnení zástupcovia Európskej Komisie a Európskeho dvora audítorov;</li>
            <li>orgán zabezpečujúci ochranu finančných záujmov EÚ;</li>
            <li>osoby prizvané vyššie uvedenými osobami v súlade s príslušnými právnymi predpismi Slovenskej republiky a právnymi aktmi Európskej únie;</li>
            <li>externé audítorské subjekty,</li>
          </ol>
        </li>
        <li>kontrolné orgány na európskej úrovni: Európska komisia a iné európske subjekty, ktoré môžu osobné údaje vyžadovať na účely vysporiadania prípadných nezrovnalostí v projekte a na účely kontroly,</li>
        <li>Ministerstvo investícií, regionálneho rozvoja a informatizácie Slovenskej republiky;</li>
        <li>Ministerstvo dopravy Slovenskej republiky;</li>
        <li>Ministerstvo školstva, vedy a výskumu Slovenskej republiky;</li>
        <li>Ministerstvo práce, sociálnych vecí a rodiny Slovenskej republiky;</li>
        <li>príslušné Úrady práce, sociálnych vecí a rodiny, príslušné pobočky Sociálnej poisťovne, Finančná správa SR.</li>
      </ul>
    </p>
    <p>V prípadoch, kedy spracúvame Vaše osobné údaje prostredníctvom sprostredkovateľov alebo ako spoloční prevádzkovatelia (spolu s MIRRI za účelom overenia cieľovej skupiny), ako osobitnej kategórie príjemcov osobných údajov, dbáme na to, aby postupovali v súlade s platnými právnymi predpismi a podmienkami dohodnutými zmluvou o spracúvaní osobných údajov alebo dohodou spoločných prevádzkovateľov, aby boli viazaní mlčanlivosťou a Vaše údaje chránili v súlade s požiadavkami GDPR.</p>

    <h3>DOCHÁDZA K AUTOMATIZOVANÉMU SPRACÚVANIU VAŠICH ÚDAJOV S PRÁVNYM ÚČINKOM NA VÁS, VRÁTANE PROFILOVANIA?</h3>
    <p>Nie, nedochádza.</p>

    <h3>DOCHÁDZA K CEZHRANIČNÉMU PRENOSU VAŠICH OSOBNÝCH ÚDAJOV?</h3>
    <p>Nie, nedochádza.</p>

    <h3>AKÉ SÚ VAŠE PRÁVA AKO DOTKNUTEJ OSOBY?</h3>
    <p>Podľa platných právnych predpisov o ochrane údajov máte ako dotknutá osoba určité práva.
      <ul>
        <li>Právo na prístup - máte právo na poskytnutie kópie osobných údajov, ktoré o Vás máme k dispozícii, ako aj na informácie o tom, ako Vaše osobné údaje používame. Vo väčšine prípadov Vám budú Vaše osobné údaje poskytnuté v písomnej listinnej forme, pokiaľ nie je z Vašej strany požadované inak. Ak ste o poskytnutie týchto informácií požiadali elektronickými prostriedkami, budú Vám poskytnuté elektronicky, ak to bude technicky možné.</li>
        <li>Právo na opravu - prijímame primerané opatrenia, aby sme zabezpečili presnosť, úplnosť a aktuálnosť informácií, ktoré o Vás máme k dispozícii. Ak si myslíte, že údaje, ktorými disponujeme, sú nepresné, neúplné alebo neaktuálne, prosím, neváhajte nás požiadať, aby sme tieto informácie upravili, aktualizovali alebo doplnili. Ako dotknutá osoba máte právo na opravu osobných údajov, ktoré sa Vás týkajú, ak sú nesprávne alebo na ich doplnenie, ak sú neúplné. Vašej žiadosti o opravu príp. doplnenie osobných údajov sme povinní vyhovieť bez zbytočného odkladu.</li>
        <li>Právo na vymazanie - za určitých okolností máte právo nás požiadať o vymazanie Vašich osobných údajov, napríklad v prípade, ak osobné údaje, ktoré sme o Vás získali, už viac nie sú potrebné na naplnenie pôvodného účelu spracovania alebo v prípade, ak odvoláte svoj súhlas so spracovaním. Vaše právo je však potrebné posúdiť z pohľadu všetkých relevantných okolností. Napríklad, ak je spracúvanie Vašich údajov potrebné na splnenie našej zákonnej povinnosti alebo na preukazovanie, uplatňovanie alebo obhajovanie právnych nárokov, nemusíme Vašej žiadosti vyhovieť.</li>
        <li>Právo na obmedzenie spracovanie - za určitých okolností ste oprávnení nás požiadať, aby sme prestali používať Vaše osobné údaje. Môže sa napr. jednať o situácie, ak si myslíte, že osobné údaje, ktoré o Vás máme, sú nepresné alebo že Vaše osobné údaje už nepotrebujeme využívať. Ak sa spracúvanie obmedzilo v súlade s čl. 18 ods. 1 GDPR, takéto osobné údaje sa s výnimkou uchovávania spracúvajú: a) len so súhlasom dotknutej osoby, alebo b) na preukazovanie, uplatňovanie alebo obhajovanie právnych nárokov, alebo c) na ochranu práv inej fyzickej alebo právnickej osoby, alebo d) z dôvodov dôležitého verejného záujmu Únie alebo členského štátu.</li>
        <li>Právo na prenosnosť údajov - za určitých okolností máte právo požiadať nás o prenos osobných údajov, ktoré ste nám poskytli, na inú tretiu stranu podľa Váš/Vášho výberu. Ak sa spracúvanie zakladá na súhlase alebo na zmluve a vykonáva sa automatizovanými prostriedkami, máte právo získať svoje osobné údaje, ktoré ste nám poskytli, v štruktúrovanom, bežne používanom a strojovo čitateľnom formáte a má právo preniesť tieto údaje ďalšiemu prevádzkovateľovi. Pokiaľ je to technicky možné, máte právo na prenos údajov priamo od jedného prevádzkovateľa k druhému.</li>
        <li>Právo namietať - v prípade ak sa spracúvanie zakladá na našich oprávnených záujmoch (čl. 6 ods. 1 písm. f) GDPR), máte právo kedykoľvek namietať z dôvodov súvisiacich s Vašou konkrétnou situáciou proti takémuto spracúvaniu osobných údajov, ktoré sa Vás týka, vrátane namietania proti profilovaniu založenému na uvedených záujmoch. V takomto prípade nesmieme ďalej spracúvať Vaše osobné údaje, okrem situácie, ak (i) preukážeme nevyhnutné oprávnené dôvody, ktoré prevažujú nad Vašimi záujmami, právami a slobodami ako dotknutej osoby, alebo (ii) dôvody na preukazovanie, uplatňovanie alebo obhajovanie právnych nárokov. V prípade, ak namietate proti spracúvaniu osobných údajov na účely priameho marketingu, vrátane profilovania v rozsahu, v akom súvisí s takýmto priamym marketingom, osobné údaje sa už na takéto účely nesmú spracúvať.</li>
        <li>Práva súvisiace s automatizovaným rozhodovaním - máte právo odmietnuť automatizované rozhodovanie, vrátane profilovania, z ktorého pre Vás vyplynie právny alebo podobný významný dôsledok.</li>
        <li>Právo odvolať súhlas - vo väčšine prípadov Vaše osobné údaje nespracúvame na základe Vášho súhlasu. V prípade, ak v konkrétnych prípadoch spracúvame Vaše údaje na základe Vášho súhlasu, máte právo odvolať svoj súhlas s ďalším používaním Vašich osobných údajov.</li>
        <li>Právo podať sťažnosť alebo domáhať sa súdnej ochrany - ako dotknutá osoba máte tiež právo kedykoľvek podať sťažnosť na dozorný orgán, ktorým je Úrad na ochranu osobných údajov SR (viac informácií je možné nájsť na webovej stránke (www.dataprotection.gov.sk) alebo sa obrátiť so žalobou na príslušný súd.</li>
      </ul>
    </p>

    <h3>PODNETY OHĽADOM POUŽÍVANIA VAŠICH OSOBNÝCH ÚDAJOV</h3>
    <p>Záleží nám na ochrane Vašich osobných údajov, a preto sa usilujeme o ich zabezpečenie prostredníctvom individuálnych, moderných technických a organizačných opatrení, ako aj prostredníctvom možnosti kedykoľvek uplatniť Vaše práva dotknutej osoby podľa GDPR prostredníctvom žiadosti.</p>
    <p>Žiadosti o výkon práva dotknutej osoby nám môžete zasielať elektronicky alebo písomne na vyššie uvedené kontaktné údaje zodpovednej osoby.  Týmto postupom nie je dotknuté Vaše právo odvolať udelený súhlas so spracúvaním osobných údajov, ktorý môžete vždy odvolať tak ľahko ako ste nám ho udelili (napr. ak ste súhlas udelili elektronicky, vždy ho môžete odvolať aj emailom alebo aplikáciou bez potreby zasielania písomnej žiadosti na adresu nášho) alebo Vaše právo namietať automatizovanými prostriedkami s použitím technických špecifikácií, ak sú dostupné.</p>
    <p>Odporúčame pri každej žiadosti čo najpodrobnejšie vysvetliť aké právo v zmysle GDPR uplatňujete, aké sú Vaše identifikačné údaje (na overenie totožnosti) príp. akých účelov a údajov sa žiadosť týka. Pri príliš všeobecných žiadostiach musíme žiadať upresnenie.</p>
    <p>GDPR ustanovuje všeobecné podmienky výkonu Vašich jednotlivých práv. Ich existencia však automaticky neznamená, že pri uplatňovaní jednotlivých práv im bude z našej strany vyhovené nakoľko v konkrétnom prípade sa môžu uplatňovať aj výnimky resp. niektoré práva sú naviazané na konkrétne podmienky, ktoré nemusia byť v každom prípade splnené. Vašou žiadosťou týkajúcou sa konkrétneho práva sa budeme vždy zaoberať a skúmať z hľadiska právnej úpravy a našej internej politiky pre vybavovanie podnetov dotknutých osôb.</p>
    <p>V prípade, ak Digitálna koalícia nebude príslušná na posúdenie Vašej žiadosti o výkon práva dotknutej osoby, bude Vaša žiadosť bezodkladne postúpená príslušnému prevádzkovateľovi.</p>
    <p>Každá žiadosť o výkon práva dotknutej osoby, ktorá nám bude doručená bude individuálne a kompetentne posúdená, pričom o výsledku Vás budeme vždy informovať najneskôr do jedného mesiaca od prijatia Vašej žiadosti.</p>
    <p>Dovoľujeme si Vás upozorniť, že pri vybavovaní Vašej žiadosti o výkon práva dotknutej osoby podľa GDPR Vás môžeme požiadať o dôveryhodné overenie Vašej totožnosti, a to najmä v prípadoch, ak budú existovať pochybnosti o Vašej identite. Je našou povinnosťou zabrániť poskytnutiu osobných údajov o Vás neoprávnenej osobe. Proces vybavenia Vašej žiadosti spojenej s výkonom Vášho práva dotknutej osoby podľa GDPR je bezplatný. Ak je Vaša žiadosť zjavne neopodstatnená alebo neprimeraná, najmä preto, že sa opakuje, sme oprávnení účtovať si primeraný poplatok, ktorý zohľadňuje administratívne náklady</p>

    <h3>ZMENA TÝCHTO PODMIENOK</h3>
    <p>Ochrana osobných údajov pre nás nie je jednorazovou záležitosťou. Informácie, ktoré sme Vám povinní vzhľadom na naše spracúvanie osobných údajov poskytnúť, sa môžu meniť alebo prestať byť aktuálne.</p>
    <p>Z tohto dôvodu si vyhradzujeme možnosť kedykoľvek tieto podmienky upraviť a zmeniť v akomkoľvek rozsahu. V prípade, ak zmeníme tieto informácie podstatným spôsobom, túto zmenu Vám dáme do pozornosti napr. všeobecným oznámením na tejto webstránke alebo osobitným oznámením prostredníctvom emailu.</p>
    <p>Táto verzia bola vydaná dňa 27.02.2023.</p>
  </div>
  </div>
}
