export default function ContractPage() {
  return <div className={'container'}>
    <div className={'content-wrapper'}>
    <h1>ZMLUVNÉ PODMIENKY</h1>
    <h2>participácie žiakov na projekte Digitálny príspevok pre žiakov SR</h2>

    <h3>PREAMBULA</h3>
    <ol>
      <li>Národná koalícia pre digitálne zručnosti a povolania Slovenskej republiky je záujmové združenie právnických osôb založené Memorandom členov digitálnej koalície 26. septembra 2017, so sídlom Mlynské nivy 18890/5, 82109 Bratislava Ružinov, Slovenská republika, IČO: 52 828 123, zapísané v Registri záujmových združení právnických osôb Okresného úradu Bratislava (ďalej len „Digitálna koalícia“) realizuje národný projekt Digitálny príspevok pre žiakov SR (ďalej len „Digitálny príspevok“).</li>
      <li>Digitálny príspevok má podporiť rozvoj digitálnych zručností žiakov prostredníctvom podpory nákupu digitálneho vybavenia.</li>
      <li>Vzhľadom na to, že cieľom Digitálneho príspevku je zlepšenie inklúzie a prístupu žiakov k digitálnym technológiám, a to najmä žiakov zo sociálne znevýhodneného prostredia, budú finálnymi príjemcami Digitálneho príspevku práve žiaci z cieľových skupín, ktoré sú popísané v týchto Zmluvných podmienkach (ďalej len „Žiaci“).</li>
      <li>Keďže cieľových skupín Žiakov je viacero a ich zaradenie medzi oprávnených žiadateľov o Digitálny príspevok je komplexný proces, upravujú vzájomné práva a povinnosti, ktoré v priebehu tohto procesu vzniknú medzi Digitálnou koalíciou a jednotlivými Žiakmi práve tieto zmluvné podmienky (ďalej len „Zmluvné podmienky“, Žiak spoločne s Digitálnou koalíciou ďalej aj ako „Zmluvné strany“).</li>
    </ol>

    <h3>PRVÁ ČASŤ<br/>Úvodné ustanovenia</h3>

    <h3>Článok 1<br/>Predmet úpravy</h3>
    <ol>
      <li>Zmluvné podmienky dopĺňajú zmluvný vzťah, ktorým je Zmluva o participácii žiaka na projekte Digitálneho príspevku pre žiakov SR (ďalej len „Zmluva“) a ktorý vznikne medzi Digitálnou koalíciou a Žiakom registráciou na portáli www.digitalnyziak.sk, ktorá je prvým krokom zaradenia Žiaka medzi oprávnených žiadateľov o Digitálny príspevok.</li>
    </ol>

    <h3>Článok 2<br/>Účinnosť Zmluvných podmienok</h3>
    <ol>
      <li>Zmluvné podmienky sú účinné od 1.4.2023.</li>
    </ol>

    <h3>Článok 3<br/>Kontaktné údaje Digitálnej koalície</h3>
    <ol>
      <li>Digitálnu koalíciu môžete kontaktovať telefonicky, písomne, alebo emailom. ​​Adresa: Mlynské nivy 5, 821 09 Bratislava, Slovenská republika. Emailová adresa: <a href={'mailto:info@digitalnyziak.sk'}>info@digitalnyziak.sk</a></li>
    </ol>

    <h3>Článok 4<br/>Zmluva</h3>
    <ol>
      <li>Dokončením registrácie na portáli www.digitalnyziak.sk za účelom získania Digitálneho príspevku je medzi Žiakom a Digitálnou koalíciou uzavretá Zmluva. Ak je žiak neplnoletý, musí byť zastúpený svojim zákonným zástupcom.</li>
      <li>Predmetom Zmluvy je povinnosť Žiaka (resp. jeho zákonného zástupcu) postupovať pri uplatnení Digitálneho príspevku spôsobom predpokladaným v Zmluvných podmienkach a poskytnúť Digitálnej koalícii všetky potrebné údaje špecifikované v Zmluvných podmienkach.</li>
      <li>Oproti záväzku Žiaka stojí povinnosť Digitálnej koalície umožniť Žiakovi participáciu na projekte Digitálny Žiak a požiadať o Digitálny príspevok, ak splní podmienky participácie uvedené v Zmluvných podmienkach a povinnosť nakladať s poskytnutými údajmi Žiaka (prípadne jeho zákonného zástupcu) v súlade s platnými právnymi predpismi týkajúcimi sa ochrany osobných údajov.</li>
      <li>Zmluva zaniká 31. decembra 2028 a zanikne bez ohľadu na to, či si Žiak (resp. jeho zákonný zástupca) Digitálny príspevok uplatní.</li>
      <li>Ak žiadajúcemu žiakovi nevznikne nárok na poskytnutie Digitálneho príspevku v zmysle ustanovení Článku 7, odseku 5 Zmluvných podmienok, zaniká Zmluva momentom doručenia SMS správy žiadajúcemu žiakovi (resp. jeho zákonnému zástupcovi) s informáciou o tom, že žiadajúci žiak nebol zaradený do databázy oprávnených príjemcov Digitálneho príspevku.</li>
      <li>Žiak (resp. jeho zákonný zástupca) môže od tejto Zmluvy odstúpiť z akéhokoľvek dôvodu až do uplatnenia Digitálneho príspevku, a to prostredníctvom emailovej správy zaslanej na emailovú adresu Digitálnej koalície, ktorej prijatie Digitálna koalícia Žiakovi (resp. jeho zákonnému zástupcovi) bezodkladne potvrdí rovnakým spôsobom, alebo v písomnej forme zaslaním odstúpenia na adresu Digitálnej koalície. V odstúpení od Zmluvy žiak (resp. jeho zákonný zástupca uvedie) svoje identifikačné údaje (resp. identifikačné údaje žiaka).</li>
      <li>Odstúpenie je účinné jeho doručením Digitálnej koalícii, prípadne uplynutím lehoty pre vyzdvihnutie zásielky na pošte.</li>
    </ol>

    <h3>DRUHÁ ČASŤ<br/>Digitálny príspevok</h3>

    <h3>Článok 5<br/>Základné informácie o Digitálnom príspevku</h3>
    <ol>
      <li>Digitálny príspevok je finančný príspevok pre Žiakov z cieľových skupín, určený na nákup digitálnej techniky.</li>
      <li>Oprávneným príjemcom Digitálneho príspevku je žiak, ktorý:
        <ol type="a">
          <li>je žiakom prvého ročníka strednej školy (v školskom roku 2022/2023), alebo žiakom piateho ročníka v osemročnom vzdelávacom programe gymnázia a/alebo;</li>
          <li>je členom domácnosti v hmotnej núdzi (teda ak príjem členov domácnosti nedosahuje výšku životného minima), a/alebo;</li>
          <li>je členom domácnosti, ktorá neprekračuje 60% medián príjmu (na základe potvrdenia o výške príjmu, resp. dokladu o nevykonávaní zárobkovej činnosti, ktorým môže byť napríklad potvrdenie o nezamestnanej osobe), a/alebo;</li>
          <li>má vyjadrenie zariadenia poradenstva a prevencie o tom, že jeho špeciálne výchovnovzdelávacie potreby vyplývajú výlučne z jeho vývinu v sociálne znevýhodnenom prostredí.</li>
        </ol>
      </li>
      <li>Ak žiadajúci žiak nie je plnoletý, vykonáva za neho všetky úkony potrebné pre získanie Digitálneho príspevku a jeho využitie jeho zákonný zástupca, a to až do dňa jeho osemnástych narodenín. Zariadenie nadobudnuté využitím Digitálneho príspevku je však vždy majetkom Žiaka.</li>
      <li>Výška Digitálneho príspevku je 350 EUR.</li>
      <li>Výška Digitálneho príspevku nelimituje cenu zakúpeného zariadenia a Žiak si môže s vlastným doplatkom kúpiť aj zariadenie (prípadne príslušenstvo) prevyšujúce hodnotu príspevku.</li>
    </ol>

    <h3>Článok 6<br/>Špecifikácia zariadenia</h3>
    <ol>
      <li>
          <p>
            Zariadenia, ktoré je možné zakúpiť s použitím príspevku, sú primárne určené na vzdelávanie, a preto musia spĺňať nasledujúce parametre:
            <ol type='a'>
              <li>displej s minimálnym rozlíšením 1366 x 768 pixelov</li>
              <li>minimálne jeden fyzický port pre pripojenie periférií</li>
              <li>fyzickú (hardware) slovenskú klávesnicu ako súčasť zariadenia alebo pripojiteľnú fyzickými portami zariadenia, alebo bezdrôtovo</li>
              <li>funkcia ovládania kurzora, vstavané alebo pripojiteľné fyzickými portami zariadenia alebo bezdrôtovo (myš, dotykový displej, touchpad a pod.)</li>
              <li>zabudovaná kamera, reproduktory a mikrofón</li>
              <li>funkcionalita pripojenia na Wi-Fi</li>
              <li>rozhranie pre možnosť pripojenia k mobilným dátovým službám – v prípade tabletu SIM slot ( integrovaný LTE Modem). Pri ostatných zariadeniach možnosť pripojenia LTE modemu pre 4G sieť</li>
            </ol>
          </p>
          <p>
            V danom ponímaní sa jedná štandardne o zariadenia typu Notebook, All in one počítač alebo tablet s uhlopriečkou displeja nie menšou ako 25 centimetrov, pričom:
            <ol type='a' start='8'>
              <li>zariadenie musí mať nainštalovaný užívateľsky jednoduchý operačný systém – jeho najnovšiu verziu, prípadne verziu, ktorá umožňuje aktualizáciu na novšie verzie, je možné ďalej doinštalovať balík kancelárskeho programového vybavenia (software) lokalizovaného do slovenčiny a používaného v školskom prostredí na Slovensku, antivírusový software, a je tiež kompatibilné s prostredím a aplikáciami využívanými v školskom prostredí – najmä Edunet a pod.</li>
              <li>zariadenie musí byť nové (vyrobené v roku 2022 a neskôr), má minimálne dvojročnú záruku a garantovanú plnú podporu výrobcu minimálne na tri roky (na hardware a aj software vybavenie)</li>
              <li>zariadenie musí mať možnosť pripojenia k široko podporovanej online distribučnej platforme voľných a platených aplikácií (vrátane aplikácií ako <a href={'http://viki.iedu.sk/'} target={'_blank'}>viki.iedu.sk</a>, Edupage a Slovensko.sk) a ich plnohodnotného používania</li>
            </ol>
          </p>
          <p>* Výška príspevku neohraničuje cenu zakúpeného zariadenia. Užívateľ digitálneho príspevku/zákonný zástupca môže s vlastným doplatkom nakúpiť zariadenie a príslušenstvo aj nad rámec technickej špecifikácie a hodnoty príspevku.</p>
      </li>
    </ol>

    <h3>TRETIA ČASŤ<br/>Získanie a použitie Digitálneho príspevku</h3>

    <h3>Článok 7<br/>Registrácia</h3>
    <ol>
      <li>Prvým krokom vedúcim k získaniu Digitálneho príspevku je registrácia Žiaka na portáli www.digitalnyziak.sk, ktorú v prípade neplnoletého Žiaka vykoná jeho zákonný zástupca.</li>
      <li>V registračnom formulári je potrebné:
        <ol type="a">
          <li>uviesť identifikačné údaje žiadajúceho žiaka, teda jeho meno a priezvisko, rodné číslo, adresu, emailovú adresu a telefónne číslo;</li>
          <li>uviesť základné údaje o škole, ktorú žiadajúci žiak navštevuje, teda jej názov, adresu;</li>
          <li>vybrať príznak špecifikujúci, do ktorej skupiny oprávnených Žiakov podľa ustanovení Článku 5, odseku 2 žiadajúci žiak patrí;</li>
          <li>uviesť identifikačné údaje aspoň (ak je to možné, tak oboch) jedného zákonného zástupcu, teda meno a priezvisko, rodné číslo, adresu, emailovú adresu a telefónne číslo;</li>
          <li>ak je zákonným zástupcom žiadajúceho žiaka právnická osoba, uvádza sa jej názov, adresa sídla, IČO a údaje štatutára, teda jeho meno a priezvisko, rodné číslo, emailová adresa a telefónne číslo.</li>
        </ol>
      </li>
      <li>Po vyplnení a odoslaní registračného formulára Digitálna koalícia zabezpečí overenie zadaných údajov, teda overí, či konkrétny žiadajúci žiak spĺňa podmienky poskytnutia Digitálneho príspevku na základe uvedených údajov a zvoleného príznaku cieľovej skupiny oprávnených Žiakov.</li>
      <li>Ak konkrétny žiadajúci žiak spĺňa podmienky poskytnutia Digitálneho príspevku, bude zaradený do databázy oprávnených príjemcov Digitálneho príspevku (ďalej len „Databáza“), čím mu vznikne nárok na poskytnutie Digitálneho príspevku. O zaradení do Databázy bude Žiak a/alebo jeho zákonný zástupca informovaný prostredníctvom SMS správy s unikátnym kódom pre uplatnenie Digitálneho príspevku u predajcov zariadení.</li>
      <li>Naopak, ak žiadajúci žiak nespĺňa podmienky poskytnutia Digitálneho príspevku, nebude zaradený do Databázy a nárok na poskytnutie Digitálneho príspevku mu nevznikne. O tom, že nebol zaradený do Databázy, bude žiadajúci žiak a/alebo jeho zákonný zástupca informovaný prostredníctvom SMS správy.</li>
      <li>Ak žiadajúci žiak (resp. jeho zákonný zástupca) považuje odmietnutie zaradenia do Databázy za nesprávne, môže Digitálnu koalíciu požiadať o preskúmanie výsledkov overenia. Digitálna koalícia môže po preskúmaní žiadajúceho žiaka zaradiť do Databázy, alebo potvrdiť, že žiadajúci žiak nespĺňa podmienky pre poskytnutie Digitálneho príspevku.</li>
      <li>O výsledku preskúmania výsledkov overenia Digitálna koalícia informuje žiadajúceho žiaka a/alebo jeho zákonného zástupcu prostredníctvom SMS správy, ktorá bude v prípade dodatočného zaradenia Žiaka do Databázy obsahovať aj unikátny kód pre uplatnenie Digitálneho príspevku u predajcov zariadení.</li>
      <li>Digitálny príspevok je možné uplatniť len jedenkrát, preto ak registráciu Žiaka vykoná viacero zákonných zástupcov, Digitálna koalícia telefonicky kontaktuje oboch registrovaných zákonných zástupcov za účelom overenia individuálnych registrácií a požiada zákonných zástupcov o vysvetlenie dôvodov násobnej registrácie. Ak je to možné, požiada Digitálna koalícia o vyjadrenie aj samotného Žiaka.</li>
      <li>Na základe získaných informácií následne Digitálna koalícia môže určiť, že niektorý zo zákonných zástupcov (alebo aj žiadny z nich) nie je oprávnený uplatniť v mene Žiaka Digitálny príspevok. O výsledku tohto procesu Digitálna koalícia všetkých dotknutých zákonných zástupcov (a ak je to možné, tak aj Žiaka), informuje telefonicky.</li>
      <li>Ak Žiak po registrácii jeho zákonným zástupcom a zaradení do Databázy a pred uplatnením Digitálneho príspevku nadobudne plnoletosť, môže kontaktovať Digitálnu koalíciu so žiadosťou o zmenu údajov v Databáze, aby si následne mohol Digitálny príspevok uplatniť sám bez zákonného zástupcu.</li>
    </ol>

    <h3>Článok 8<br/>Poskytnutie Digitálneho príspevku</h3>

    <ol>
      <li>Digitálny príspevok si môže Žiak uplatniť u ktoréhokoľvek zo zmluvných predajcov zariadení (ďalej len „Predajca“), ktorých zoznam je dostupný na www.digitalnyziak.sk.</li>
      <li>Žiak alebo jeho zákonný zástupca osloví Predajcu, ktorého si vyberie a požiada ho o uplatnenie Digitálneho príspevku. Oznámi Predajcovi unikátny kód, ktorý mu bol doručený v SMS správe o zaradení do Databázy.</li>
      <li>Predajca na základe tohto unikátneho kódu najskôr overí, či je konkrétny Žiak zaradený v Databáze a teda či má nárok na poskytnutie Digitálneho príspevku.</li>
      <li>Ak je Žiak zaradený v Databáze a nie je plnoletý, Predajca získa prístup k údajom Žiaka a jeho zákonného zástupcu, ktoré sú uložené v Databáze. Predajca následne overí osobné údaje zákonného zástupcu podľa jeho občianskeho preukazu alebo iného identifikačného dokladu a údaje Žiaka podľa informácií od zákonného zástupcu Žiaka, alebo podľa identifikačného dokladu Žiaka (občiansky preukaz alebo cestovný pas), ak už mu bol pridelený. Predajca týmto tiež overí, či je prítomný zákonný zástupca Žiaka oprávnený uplatniť v mene Žiaka Digitálny príspevok.</li>
      <li>Ak je Žiak zaradený v Databáze a je plnoletý, Predajca získa prístup k údajom Žiaka, ktoré sú uložené v Databáze, ktoré následne overí podľa jeho občianskeho preukazu alebo iného identifikačného dokladu.</li>
      <li>Ak údaje nie sú správne, môže sa Žiak, alebo jeho zákonný zástupca, a/alebo Predajca obrátiť telefonicky na Digitálnu koalíciu a s pracovníkmi callcentra vyriešiť prípadný rozpor v údajoch registrovaných v Databáze (takáto situácia môže nastať napríklad pri chybách v písaní, zmene údajov zadaných v registračnom formulári, alebo ak Žiak v dobe medzi registráciou a uplatnením Digitálneho príspevku nadobudol plnoletosť).</li>
      <li>Ak sú údaje správne, predloží Predajca Žiakovi (resp. jeho oprávnenému zákonnému zástupcovi) Zmluvu o poskytnutí Digitálneho príspevku podpísanú Digitálnou koalíciou vo forme faksimile (teda s predtlačeným podpisom). Ak Žiak (resp. jeho oprávnený zákonný zástupca) s návrhom Zmluvy o poskytnutí Digitálneho príspevku súhlasí, pripojí svoj podpis, čím dôjde k uzavretiu Zmluvy o poskytnutí Digitálneho príspevku.</li>
      <li>Po uzavretí Zmluvy o poskytnutí Digitálneho príspevku si Žiak (resp. jeho oprávnený zákonný zástupca) vyberie zariadenie a Predajca overí, či vybraté zariadenie spĺňa minimálne požiadavky podľa článku 6 Zmluvných podmienok. Ak vybraté zariadenie minimálne požiadavky nespĺňa, poradí Predajca Žiakovi (resp. jeho oprávnenému zákonnému zástupcovi) s výberom zariadenia.</li>
      <li>Keď si Žiak (resp. jeho zákonný zástupca) vyberie zariadenie spĺňajúce minimálne podmienky, predá toto zariadenie Žiakovi (resp. jeho oprávnenému zákonnému zástupcovi), pričom od ceny zariadenia odpočíta sumu Digitálneho príspevku.</li>
      <li>Ak je cena vybratého zariadenia nižšia ako Digitálny príspevok, uplatní sa Digitálny príspevok maximálne do výšky ceny vybratého zariadenia, nárok na zvyšnú časť Digitálneho príspevku tak zaniká.</li>
      <li>Predajca je povinný pred predajom zariadenia vykonať prvé zapnutie zariadenia a prípadne doinštalovať jeho softvér tak, aby pri ďalšom zapnutí Žiak nemusel vykonávať žiadne ďalšie kroky a zariadenie fungovalo. Vykonanie tejto služby je Žiak (resp. jeho oprávnený zákonný zástupca) povinný Predajcovi potvrdiť podpísaním protokolu vystaveného Predajcom.</li>
      <li>Následne Predajca predá a odovzdá zariadenie Žiakovi (resp. jeho oprávnenému zákonnému zástupcovi) spolu s riadnym účtovným dokladom.</li>
    </ol>

    <h3>Článok 9<br/>Interakcia Digitálnej koalície so Žiakom</h3>

    <ol>
      <li>Po uzavretí Zmluvy o poskytnutí Digitálneho príspevku môže Digitálna koalícia občasne komunikovať so Žiakom prostredníctvom emailovej správy, SMS správy, alebo telefonicky na základe kontaktných údajov poskytnutých pri registrácii, ak na to Žiak predtým udelil súhlas.</li>
      <li>Účelom komunikácie Digitálnej koalície so Žiakom bude zlepšovanie digitálnych zručností žiakov a ich podpora v osvojovaní digitálnych zručností. So zámerom dosiahnuť tieto ciele môže Digitálna koalícia informovať Žiakov o možnosti zapojenia sa do rôznych projektov, súťaží alebo iných aktivít.</li>
      <li>Žiak tiež môže kontaktovať Digitálnu koalíciu so žiadosťou o poskytnutie informácií, poradenstva alebo inej pomoci v súvislosti s Digitálnym príspevkom alebo v súvislosti s akýmkoľvek projektom, súťažou alebo inou aktivitou súvisiacou, o ktorých bol informovaný.</li>
    </ol>

    <h3>ŠTVRTÁ ČASŤ<br/>Poskytovanie osobných údajov a ich ochrana</h3>

    <h3>Článok 10<br/>Poskytovanie osobných údajov</h3>

    <ol>
      <li>Pre poskytnutie Digitálneho príspevku je potrebné, aby Žiak (prípadne jeho zákonný zástupca) poskytol Digitálnej koalícii niektoré osobné údaje. Poskytnutie osobných údajov je podmienok poskytnutia Digitálneho príspevku.</li>
      <li>Digitálna koalícia rešpektuje súkromie všetkých osôb, ktorých údaje spracúva a zaväzuje sa chrániť ich súkromie a zaobchádzať s ich údajmi zodpovedne. Podmienky ochrany osobných údajov a súkromia Žiakov (prípadne ich zákonných zástupcov) sú podrobne a zrozumiteľným spôsobom upravené v Podmienkach ochrany osobných údajov.</li>
      <li>Pre základné porozumenie poskytovaniu osobných údajov v súvislosti s poskytovaním Digitálneho príspevku tieto Zmluvné podmienky v nasledujúcich ustanoveniach popisujú, v akom rozsahu a akým spôsobom bude Digitálna koalícia v rôznych fázach procesu poskytovania Digitálneho príspevku osobné údaje zbierať a používať.</li>
    </ol>

    <h3>Článok 11<br/>Rozsah a spôsob spracovania osobných údajov</h3>

    <ol>
      <li>Digitálny príspevok nie je poskytovaný všetkým žiakom, ale len Žiakom z vybraných cieľových skupín. Najskôr je preto potrebné zistiť, či žiak, ktorý má o tento príspevok záujem, spĺňa kritériá niektorej z týchto skupín.</li>
      <li>Aby bola Digitálna koalícia schopná overiť, či je žiadajúci žiak oprávnený na získanie Digitálneho príspevku, potrebuje pri registrácii získať údaje (i) o žiadajúcom žiakovi, (ii) o jeho škole. Ak je žiadajúci žiak neplnoletý a úkony súvisiace so získaním Digitálneho príspevku za neho vykonáva jeho zákonný zástupca, potrebuje Digitálna koalícia získať aj údaje (iii) o zákonnom zástupcovi žiadajúceho žiaka. Zásadným údajom, ktorý je potrebný pre overenie oprávnenosti žiadajúceho žiaka na získanie Digitálneho príspevku, je (iv) príznak špecifikujúci, do ktorej skupiny oprávnených žiadateľov žiadajúci žiak patrí.</li>
      <li>Údaje, ktoré Digitálna koalícia pri registrácii potrebuje získať
        <ol type="a">
          <li>o žiadajúcom žiakovi pozostávajú z mena a priezviska, adresy, rodného čísla, eduID a jeho kontaktných údajov, teda telefónneho čísla a emailovej adresy;</li>
          <li>o škole pozostávajú z jej názvu, adresy a eduID;</li>
          <li>o zákonnom zástupcovi pozostávajú z mena a priezviska, adresy, rodného čísla, čísla občianskeho preukazu alebo iného identifikačného dokladu a jeho kontaktných údajov, teda telefónneho čísla a emailovej adresy;</li>
          <li>ak je zákonným zástupcom žiadajúceho žiaka právnická osoba, jej údaje pozostávajú z jej názvu, sídla, identifikačného čísla a z údajov osoby konajúcej v jej mene. Údaje konajúcej osoby pozostávajú z mena a priezviska, čísla občianskeho a kontaktných údajov, teda telefónneho čísla a emailovej adresy.</li>
        </ol>
      </li>
      <li>Žiak alebo zákonný zástupca prehlasuje, že má písomný súhlas každej fyzickej osoby, ktorej údaje uvedie v registračnom formulári (okrem tej, ktorej je zákonným zástupcom) s poskytnutím jej osobných údajov pre účely získania Digitálneho príspevku a tento súhlas sa zaväzuje v prípade potreby bez zbytočného odkladu poskytnúť Digitálnej koalícii, ak ho o to požiada.</li>
      <li>Digitálna koalícia následne zabezpečí overenie údajov získaných o žiadajúcom žiakovi (resp. jeho zákonnom zástupcovi) v súvislosti s vybranými príznakmi špecifikujúcimi skupinu oprávnených žiadateľov. Overenie vykoná Ministerstvo investícií, regionálneho rozvoja a informatizácie Slovenskej republiky v spolupráci s inými orgánmi štátnej správy, najmä s Ministerstvom školstva, vedy, výskumu a športu Slovenskej republiky, alebo Sociálnou poisťovňou, Finančnou správou Slovenskej republiky, alebo Úradom práce, sociálnych vecí a rodiny Slovenskej republiky.</li>
      <li>Príslušné orgány štátnej správy po overení, že žiadajúci žiak spĺňa kritériá pre zaradenie do niektorej z cieľových skupín (teda že je oprávneným príjemcom Digitálneho príspevku) poskytnú Digitálnej koalícii len konečný výsledok overenia, nie konkrétne údaje týkajúce sa označených príznakov.</li>
      <li>Bez poskytnutia údajov zadaných v registračnom formulári a bez ich následného overenia orgánmi štátnej správy nie je možné žiadajúceho žiaka zaradiť medzi oprávnených príjemcov Digitálneho príspevku.</li>
      <li>Na vykonanie overenia je preto nevyhnutné, aby Žiak (resp. jeho zákonný zástupca) udelil súhlas s poskytnutím osobných údajov Digitálnou koalíciou príslušným orgánom štátnej správy a s ich následným spracovaním orgánmi štátnej správy za účelom overenia a poskytnutím výsledku overenia údajov Digitálnej koalícii. Takýto súhlas je nevyhnutnou podmienkou plnenia Zmluvy (teda poskytnutia Digitálneho príspevku). Žiak (resp. jeho zákonný zástupca) berie na vedomie, že odvolaním udeleného súhlasu so spracovaním osobných údajov pred uplatnením Digitálneho príspevku zaniká nárok jeho poskytnutie.</li>
      <li>Ak je Žiak zaradený v Databáze, môže si uplatniť Digitálny príspevok u niektorého z Predajcov. Predajca najskôr prostredníctvom unikátneho kódu, ktorým sa Žiak (prípadne jeho zákonný zástupca) preukáže, overí, či je Žiak zaradený v Databáze. Ak je Žiak zaradený v Databáze, získa Predajca prístup k údajom Žiaka (prípadne jeho zákonného zástupcu), ktoré sú uložené v Databáze.</li>
      <li>Ak je Žiak plnoletý, Predajca overí jeho osobné údaje podľa jeho občianskeho preukazu alebo iného identifikačného dokladu. Ak Žiak nie je plnoletý, Predajca overí osobné údaje zákonného zástupcu podľa jeho občianskeho preukazu alebo iného identifikačného dokladu a následne overí údaje Žiaka podľa informácií od zákonného zástupcu Žiaka, alebo podľa identifikačného dokladu Žiaka (občiansky preukaz alebo cestovný pas), ak už mu bol pridelený.</li>
      <li>Po overení osobných údajov Predajca predloží Žiakovi (resp. jeho oprávnenému zákonnému zástupcovi) na podpis Zmluvu o poskytnutí Digitálneho príspevku podpísanú Digitálnou koalíciou. Kópiu podpísanej Zmluvy o poskytnutí Digitálneho príspevku uloží do Databázy.</li>
      <li>Ak si Žiak (prípadne jeho oprávnený zákonný zástupca) Digitálny príspevok uplatní, Predajca do Databázy uloží aj kópiu protokolu o prvom spustení a prípadnom doinštalovaní softvéru vystaveného Predajcom podpísaného Žiakom (prípadne jeho zákonným zástupcom), spolu s kópiou účtovného dokladu vystaveného Žiakovi (resp. jeho zákonného zástupcovi).</li>
    </ol>
  </div>
  </div>
}
