import { withTranslation } from 'react-i18next'
import React, { useState } from 'react'
import { Form }            from '../../components/form/Form'
import FormField           from '../../components/FormField'
import { Divider }         from '../../components/Divider'
import Loader              from '../../components/Loader'
import { useApi }          from '../../api'
import NoData              from '../../components/NoData'
import VoucherListItem     from '../common/VoucherListItem'

const CallSearchVoucherPage = ({t}) => {

  const api = useApi()
  const [result, setResult] = useState(null)
  const [loading, setLoading] = useState(null)
  const [vouchers, setVouchers] = useState(null)

  const onSubmit = (data) => {
    setLoading((val) => true)
    setResult(null)
    setVouchers(null)
    api.callcenter.vouchers(data.code.value)
      .then(vouchers => setVouchers(vouchers))
      .catch(e => {
        setResult({ success: false, message: e.error })
      })
      .finally(() => {
        setLoading((val) => false)
      })
  }

  return (<div className={'container'}>
    <div className={'content-wrapper d-flex'}>
      <div className={'form-wrapper'}>
        <div className={'title-content'}>
          <h3>{t('lookup-title')}</h3>
        </div>
        <Form onValidFormSubmit={onSubmit}
              defaultState={{code: { value: '', error: null }}}>
          <FormField label={`${t('voucher-searchByIdOrPhone')} *`} name={'code'} />
          <Divider />
          {loading && <Loader />}
          {result !== null && <div className={result.success ? 'field-success' : 'field-error'}>{result.message}</div>}
          {!loading && (result === null || result.success === false) && <div><button type={'submit'} className={'btn btn-submit'}>{t('general-search')}</button></div>}
        </Form>
        <Divider />
        <div className="list-group">
        {(vouchers ?? []).map(voucher => <VoucherListItem key={`voucher-${voucher.id}`} voucher={voucher} allowedFields={['student', 'parent1', 'parent2', 'salesmanParent']} />)}
        </div>
        <NoData loading={loading} data={vouchers} />
      </div>
    </div>
  </div>)
}

export default withTranslation()(CallSearchVoucherPage)