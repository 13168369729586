import { withTranslation } from 'react-i18next'
import { useFormContext }  from './form/FormProvider'

const { useState, useEffect } = require('react')
const { useApi } = require('../api')

const StoreChainSelect = ({ t, hideStorePicker }) => {

  const api = useApi()
  const { state, handleChange } = useFormContext();

  const [storeChains, setStoreChains] = useState([])
  const [stores, setStores] = useState([])
  const [selectedStoreChainId, setSelectedStoreChainId] = useState(state['storeChainId']?.value ?? '')

  const reloadStores = (storeChainId) => {
    api.admin.storeChainDetail(storeChainId, ['children']).then((response) => {
      setStores(response.children)
      let hasSelectedStoreId = state['storeId']?.value && response.children.map(it => it.id).indexOf(state['storeId']?.value) > -1
      handleChange('storeId', hasSelectedStoreId ? state['storeId']?.value : response.children[0].id)
    })
  }

  useEffect(() => {
    if (selectedStoreChainId) {
      reloadStores(selectedStoreChainId)
    }
  }, [selectedStoreChainId])

  useEffect(() => {
    api.admin.storeChain().then((response) => {
      setStoreChains(response.subjects)
      if (!selectedStoreChainId) {
        reloadStores(response.subjects[0].id)
      }
    })
  }, [])

  return (<div className={'row mb-3'}>
    <div className={'col-12 col-md-6'}>
      <div className={'form-group'}>
        <label className={'form-label'}>{t('storeChain-title')}</label>
        <select className="form-select"
                value={state['storeChainId']?.value ?? ''}
                onChange={(e) => {
                  setSelectedStoreChainId(() => e.target.value)
                  handleChange('storeChainId', e.target.value)
                }}>
          {(storeChains ?? []).map((chain) => (<option key={`chain-${chain.id}`} value={chain.id}>{chain.name}</option>))}
        </select>
      </div>
    </div>
    { !hideStorePicker && <div className={'col-12 col-md-6'}>
      <div className={'form-group'}>
        <label className={'form-label'}>{t('store-title')}</label>
        <select className="form-select"
                value={state['storeId']?.value ?? ''}
                onChange={(e) => handleChange('storeId', e.target.value)}>
          {(stores ?? []).map((store) => (<option key={`store-${store.id}`} value={store.id}>{store.name}</option>))}
        </select>
      </div>
    </div> }
  </div>)

}

export default withTranslation()(StoreChainSelect)