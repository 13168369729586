import React               from 'react'
import { withTranslation } from 'react-i18next'
import consts from '../../consts'
import { CookiesDialog }   from '../../../components/CookiesDialog'
import './footer.scss'
import AnimationContainer from "../header-footer-animation/AnimationContainer";

function Footer({ t }) {
  return (
    <footer className={'footer'}>
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <div className="footer__left">
              <a href="/" rel="home" title="Starter Frontend TWIG" className="footer__logo">
                <img src={'/img/logo_digitalna_koalicia_2023.png'} alt={t('logo-digi-coalition')} className="lazy entered loaded" data-ll-status="loaded" />
              </a>
              <div className="mt-4">
                {t('footer-company')}
              </div>
              <div>
                {t('footer-company-address-line-1')}
              </div>
              <div className="mb-4">
                {t('footer-company-address-line-2')}
              </div>

              <div className="mb-4 d-flex flex-column">
                <span>
                  <a className="link link--tel" href="tel:+421232284466" title={consts.Contact.tel}>{consts.Contact.tel}</a>
                  &nbsp; {t('footer-phone-info')}
                </span>

                <a className="link" href={'mailto:' + consts.Contact.email} title={consts.Contact.email}>
                  {consts.Contact.email}
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-7 col-lg-6 offset-lg-1">
            <div className="d-flex flex-column-reverse flex-md-column">
              <div className="footer__partners d-flex justify-content-md-end flex-wrap flex-md-nowrap">
                <img src={'/img/logo_eu.svg'} alt={t('logo-eu')} />
                <img src={'/img/logo-md-sk.svg'} alt={t('logo-opii')} />
                <img src={'/img/logo-miiri.svg'} alt={t('logo-mirri')} />
              </div>

              <div className="footer__info mb-5 mb-md-0">
                <div className={'mb-4'}>{t('footer-company-realisation-info-ua')}</div>
                <div className={'mb-4'}>{t('footer-more-info')}: <a href={'https://www.opii.gov.sk'} target={'_blank'}>www.opii.gov.sk</a> {t('general-and')} <a href={'https://www.mirri.gov.sk'} target={'_blank'}>www.mirri.gov.sk</a></div>
                <CookiesDialog />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AnimationContainer/>

    </footer>
  )
}

export default withTranslation()(Footer)
