import { useState } from 'react'

const LOCAL_STORAGE_KEY_USER = 'sk.digitalnyziak.user'

const getLocalStorageUser = () => {
  let userJson = !!localStorage.getItem(LOCAL_STORAGE_KEY_USER) ? localStorage.getItem(LOCAL_STORAGE_KEY_USER) : null
  return userJson ? JSON.parse(userJson) : null
}

let currentUser = getLocalStorageUser()

const setLocalStorageUser = (user) => {
  if (user === null) {
    localStorage.removeItem(LOCAL_STORAGE_KEY_USER)
  } else {
    localStorage.setItem(LOCAL_STORAGE_KEY_USER, JSON.stringify(user))
  }
}

export const useCurrentUser = () => {

  const [user, setUser] = useState(currentUser)

  const setUserInternal = (usr) => {
    currentUser = usr
    setUser(usr)
    setLocalStorageUser(usr)
  }

  // return global userState state and setter function
  return [user, setUserInternal];
}