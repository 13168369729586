import { useContext } from 'react'
import { AppContext } from './index'
import { DateTime }   from 'luxon'

export function useApi() {

  const { user, setUser } = useContext(AppContext)
  const BASE_URL = window.location.protocol + '//' + window.location.host + '/api'
  // const BASE_URL = 'http://localhost:8000/api'

  const send = async (url, options, getBlob = false) => {
    if (!!user) {
      options.headers = {
        ...options.headers ?? {},
        Authorization: `Bearer ${user.accessToken}`
      }
    }
    return sendImpl(url, options, getBlob).catch(async (e) => {
      if (e.status === 401) {
        if (!!user && !!user.refreshToken && !!user.expiresAt && DateTime.fromISO(user.expiresAt) < DateTime.now()) {
          // refresh token & call again
          let newUser = await post('/user/refreshToken', { refreshToken: user.refreshToken })
          setUser({
            ...newUser,
            refreshToken: user.refreshToken,
          })
          options.headers = {
            ...options.headers ?? {},
            Authorization: `Bearer ${newUser.accessToken}`
          }
          return sendImpl(url, options, getBlob)
        }
        setUser(null)
      }
      throw e
    })
  }

  const sendImpl = (url, options, getBlob = false) => {
    return new Promise((resolve, reject) => {
      fetch(url, options).then(async (response) => {
        let data
        if (getBlob) {
          data = await response.blob()
        } else {
          try {
            data = JSON.parse(await response.text())
          } catch (e) {
            data = null
          }
        }

        if (response.status / 100 !== 2) {
          return reject({
            status: response.status,
            statusText: response.statusText,
            error: data?.error ?? null,
          })
        }
        return resolve(data)
      }).catch(error => {
        console.error(`Api failed on ${options.method.toUpperCase()} ${url}`, error)
        reject(error)
      })
    })
  }

  const encodeURIComponentOwn = (component) => {
    return Array.isArray(component) ? component.map(it => encodeURIComponent(it)).join(',') : encodeURIComponent(component)
  }

  const get = (path, args, fields = null) => {
    let query = ''
    if (args && Object.getOwnPropertyNames(args).length > 0) {
      query = '?' + (Object.getOwnPropertyNames(args).filter(key => args[key] !== null).map(key => `${key}=${encodeURIComponentOwn(args[key])}`).join('&'))
    }
    if (fields) {
      query += (query.length === 0 ? '?' : '&') + 'fields='+(fields.join(','))
    }
    let headers = { }
    return send(BASE_URL+path+query, { method: "get", headers: headers, })
  }

  const _delete = (path) => {
    let headers = { }
    return send(BASE_URL+path, { method: "delete", headers: headers, })
  }

  const post = (path, data) => {
    let headers = {
      'Content-Type': 'application/json'
    }
    return send(BASE_URL+path, { method: "post", body: JSON.stringify(data), headers: headers, })
  }
  const put = (path, data) => {
    let headers = {
      'Content-Type': 'application/json'
    }
    return send(BASE_URL+path, { method: "put", body: data ? JSON.stringify(data) : null, headers: headers, })
  }

  return {
    subject: {
      create: (data) => post('/subject', data),
      search: (type, query) => get(`/subject/search/${type}/${encodeURIComponent(query)}`, {}),
      verifyPhone: (phone, code) => put(`/subject/verifyPhone/${phone}/${encodeURIComponent(code)}`, {}),
      resendVerificationSms: (project, studentIdNumber, phone, captchaToken) => post(`/subject/resendPhoneValidationSms/${project}`, {
        captchaToken: captchaToken,
        studentIdNumber: studentIdNumber,
        phone: phone,
      }),
    },
    voucher: {
      create: (data) => post('/voucher', data),
      verify: (project, studentIdNumber, parentIdNumber, captchaToken) => post(`/voucher/verify/${project}`, {
        studentIdNumber: studentIdNumber,
        parentIdNumber: parentIdNumber,
        captchaToken: captchaToken,
      }),
      resend: (project, telNumber, studentIdNumber, parentIdNumber, captchaToken) => post(`/voucher/resend/${project}`, {
        telNumber: telNumber,
        studentIdNumber: studentIdNumber,
        parentIdNumber: parentIdNumber,
        captchaToken: captchaToken,
      }),
      transfer: (voucherCode, hash, token, uaProject) => {
        return post(`/voucher/transfer/${uaProject ? 'ua' : 'sk'}`, {
          voucherCode: voucherCode,
          hash: hash,
          captchaToken: token,
        })
      }
    },
    user: {
      login: (email, password, newPassword, token) => post('/user/login', {
        email: email,
        password: password,
        newPassword: newPassword,
        captchaToken: token,
      }),
      refreshToken: (refreshToken) => post('/user/refreshToken', { refreshToken: refreshToken }),
      logout: () => post('/user/logout', { user: { id: user.id } }),
    },
    admin: {
      getUser: (email) => get(`/admin/user/${email}`),
      saveUser: (data) => post(`/admin/user`, data),
      blockUser: (userId) => _delete(`/admin/user/${userId}`),
      clearPassword: (userId) => _delete(`/admin/salesman/password/${userId}`),
      importUsers: (userType, usersData) => post('/admin/importUsers', { userType: userType, users: usersData }),
      vouchers: (query) => get(`/admin/vouchers/${encodeURIComponent(query)}`),
      storeChain: (fields) => get(`/admin/storeChain`, null, fields),
      storeChainDetail: (id, fields) => get(`/admin/storeChain/${id}`, null, fields),
      voucherDetail: (id) => get(`/admin/voucher/${id}`),
      updateSubject: (data) => put(`/admin/subject`, data),
      createSubject: (voucherId, data) => post(`/admin/subject/${voucherId}`, data),
      updateVoucher: (data) => put(`/admin/voucher`, data),
      deleteVoucher: (id) => _delete(`/admin/voucher/${id}`),
      releaseSaleVoucher: (id) => post(`/admin/voucher/releaseSale/${id}`, {}),
      approveVoucher: (id) => post(`/admin/voucher/approve/${id}`, {}),
      rejectVoucher: (id, reason) => post(`/admin/voucher/reject/${id}`, { reason: reason }),
      paidVouchers: (date, refs) => post(`/admin/voucher/paid`, { date: date, voucherRefs: refs, }),
      importIfo: (ifoNumbers) => post(`/admin/importIfo`, ifoNumbers),
      deactivateVouchers: (voucherCodes) => post(`/admin/voucher/deactivate`, { voucherCodes: voucherCodes }),
      userStatistics: (from,to) => get(`/admin/user/statistics/${from}-${to}`),
    },
    salesman: {
      get: (voucherId) => get(`/salesman/voucher/${voucherId}`),
      checkVoucher: (code, studentIdNumber, parentIdNumber) => get(`/salesman/checkVoucher/${code}${studentIdNumber ? `/${encodeURIComponent(studentIdNumber)}` : ''}${parentIdNumber ? `/${encodeURIComponent(parentIdNumber)}` : ''}`),
      lockVoucher: (code, studentIdNumber, parentIdNumber) => put(`/salesman/lockVoucher/${code}${studentIdNumber ? `/${encodeURIComponent(studentIdNumber)}` : ''}${parentIdNumber ? `/${encodeURIComponent(parentIdNumber)}` : ''}`),
      confirmVoucher: (voucherId) => put(`/salesman/confirmVoucher/${voucherId}`),
      unlockVoucher: (voucherId) => put(`/salesman/unlockVoucher/${voucherId}`),
      updateVoucher: (voucherId, data) => put(`/salesman/voucher/${voucherId}`, data),
      getAttachment: (attachmentId, fileName) => send(`${BASE_URL}/salesman/voucherAttachment/${attachmentId}/${encodeURIComponent(fileName)}`, { method: "get", headers: {}, }, true),
      getVoucherDocUrl(voucherId, doc) {
        return `${BASE_URL}/salesman/voucher/${voucherId}-${encodeURIComponent(doc)}?accessToken=${user.accessToken}`
      },
      updateSubject: (voucherId, subjectId, data) => put(`/salesman/subject/${voucherId}/${subjectId}`, data),
    },
    supervisor: {
      vouchers: (from, to) => get(`/salesman/storeVouchers?from=${from}&to=${to}`),
    },
    callcenter: {
      vouchers: (code) => get(`/callcenter/vouchers/${encodeURIComponent(code)}`),
      voucher: (code) => get(`/callcenter/voucher/${encodeURIComponent(code)}`),
    },
    storeChain: {
      storeChain: (fields) => get(`/storeChain/storeChain`, null, fields),
      store: (id) => get(`/storeChain/store/${id}`),
      storeVouchers: (id, query) => get(`/storeChain/store/vouchers/${id}`, query ? { query: query } : null, ['code', 'salesman', 'deviceInfo']),
      voucherDetail: (id) => get(`/storeChain/voucher/${id}`),
      updateVoucher: (data) => put(`/storeChain/voucher`, data),
      repairVoucher: (id) => post(`/storeChain/voucher/repair/${id}`),
      getVoucherDocUrl(voucherId, doc) {
        return `${BASE_URL}/storeChain/voucher/${voucherId}-${encodeURIComponent(doc)}?accessToken=${user.accessToken}`
      },
    },
    common: {
      getVoucherAttachmentUrl(userType, attachmentId, fileName) {
        return `${BASE_URL}/${userType}/voucherAttachment/${attachmentId}/${encodeURIComponent(fileName)}?accessToken=${user.accessToken}`
      },
      uploadAttachment: (userType, voucherId, fileName, mimeType, base64) => post(`/${userType}/voucherAttachment/${voucherId}`, {
        fileName: fileName,
        mimeType: mimeType,
        data: base64,
      }),
      deleteAttachment: (userType, attachmentId) => _delete(`/${userType}/voucherAttachment/${attachmentId}`),
      getExportDataUrl: (entity, extension, from, to) => `${BASE_URL}/admin/export/${entity}-${from}-${to}.${extension}?accessToken=${user.accessToken}`
    }
  }
}