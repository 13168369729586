import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation }                      from 'react-router-dom'
import {useTranslation, withTranslation} from 'react-i18next'
import Consts                                     from '../../consts'
import { useApi }                                 from '../../../api'
import { AppContext }                             from '../../../index'
import './header.scss'
import AnimationContainer from "../header-footer-animation/AnimationContainer";

const ChangeLanguageItem = () => {
  const {
    i18n: { language, changeLanguage },
  } = useTranslation()
  const [toLang, setToLang] = useState(language)

  useEffect(() => {
    setToLang(language === 'sk' ? 'Українська' : 'Slovenčina')
  }, [language])

  return <Link to={'#'} onClick={() => changeLanguage(language === 'sk' ? 'ua' : 'sk')} className={'nav-link'}>{toLang}</Link>
}

const defaultMenuItems = (t, language = 'ua') => [
  <a href="https://ukrajinskyziak.sk/" target="_blank" className="nav-link">{t('header-home')}</a>,
  <a href={`https://ukrajinskyziak.sk/kontakt-${language}/`} target="_blank" className="nav-link">{t('header-callcentrum')}</a>,
]

const LogoutMenuItem = ({ t }) => {

  const api = useApi()
  const { setUser } = useContext(AppContext)

  return (<a href={'/'} className={'nav-link'} onClick={async (e) => {
    e.preventDefault()
    api.user.logout()
    setUser(null)
  }}>{t('general-logout')}</a>)
}

const UserNameMenuItem = () => {
  const { user } = useContext(AppContext)
  if (!user) {
    return <></>
  }

  return <div className={'nav-link username'}>{user?.name}</div>
}

function Header({ t }) {
  const {i18n: {language}} = useTranslation()
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const { user } = useContext(AppContext)
  const [menuItems, setMenuItems] = useState([])

  const adminMenuItems = (t) => [
    <Link to={'/'} className={'nav-link'}>{t('lookup-title')}</Link>,
    <Link to={'/storeChain/list'} className={'nav-link'}>{t('storeChain-list-title')}</Link>,
    <Link to={'/export'} className={'nav-link'}>{t('export-title')}</Link>,
    <Link to={'/vouchers/paid'} className={'nav-link'}>{t('voucher-paid-title')}</Link>,
    <LogoutMenuItem t={t} />
  ]
  const salesmanMenuItems = (t, isSupervisor) => {
    let items = []
    if (isSupervisor) {
      items.push(<Link to={'/supervisor/vouchers'} className={'nav-link'}>{t('supervisor-vouchers')}</Link>)
    }
    return items.concat([
      <a href={'/'} className={'nav-link'}>{t('salesman-validation')}</a>, // ignore <Link component> -> navigate with browser router
      <LogoutMenuItem t={t} />
    ])
  }
  const callcenterMenuItems = (t) => [
    <LogoutMenuItem t={t} />
  ]

  useEffect(() => {
    setOpen(false)
  }, [location])

  useEffect(() => {
    let items = defaultMenuItems(t, language);
    if (user?.type === Consts.User.Type.Salesman) {
      items = salesmanMenuItems(t, user?.flags & Consts.User.Flags.Supervisor)
    } else if (user?.type === Consts.User.Type.Admin) {
      items = adminMenuItems(t)
    } else if (user?.type === Consts.User.Type.Callcenter) {
      items = callcenterMenuItems(t)
    }
    if (user) {
      items = [<UserNameMenuItem />, ...items]
    }
    setMenuItems([...items, <ChangeLanguageItem />])
  }, [user, language])

  const toggleMenu = (e) => {
    e.preventDefault()
    setOpen((o) => !o)
  }
  return (<>
    <header className={'header'}>
      <AnimationContainer/>
      <div className="container d-none d-md-flex">
        
      </div>
      <div className="container d-flex align-items-center">
        <a href="https://ukrajinskyziak.sk/" target="_blank" className="header__logo">
          <img src={'/img/logo-ua.png'} alt={t('logo-digiziak')}/>
        </a>

        <ul className="navbar-nav d-none d-lg-flex flex-row flex-wrap bd-navbar-nav ms-auto">
          {menuItems.map((link, index) => (<li key={`nav-item-${index}`} className="nav-item">
            {link}
          </li>))}
        </ul>

        <div className="d-lg-none d-flex align-items-center w-100">
          <div className={`menu ms-auto${open ? ' nav-is-toggled' : ''}`} id="ham">
            <div className="nav-top" onClick={toggleMenu}>
              <span></span>
              <span></span>
            </div>
            <nav className={`nav-drill px-5 px-sm-4 px-md-5${open ? ' open' : ''}`}>
              <ul className="navbar-nav">
                {menuItems.map((link, index) => (<li key={`nav-item-${index}`} className="nav-item">
                  {link}
                </li>))}
              </ul>
            </nav>
          </div>
        </div>


      </div>
    </header>
    </>)
}

export default withTranslation()(Header)
