import Loader                         from '../../components/Loader'
import React, { useEffect, useState } from 'react'
import { Trans, withTranslation }     from 'react-i18next'
import { Link, useParams }            from 'react-router-dom'
import { useApi }                     from '../../api'
import NoData                         from '../../components/NoData'
import { DateTime }                   from 'luxon'
import Consts                         from '../../consts'
import { useCurrentUser }             from '../../hooks'
import { Icon }                       from '@iconify/react'

const StorePage = ({ t }) => {

  const { storeId } = useParams()
  const api = useApi()
  const [user] = useCurrentUser()
  const [loading, setLoading] = useState(0)
  const [store, setStore] = useState(null)
  const [query, setQuery] = useState('')
  const [vouchers, setVouchers] = useState(null)

  useEffect(() => {
    setLoading((prev) => prev + 1)
    api.storeChain.store(storeId).then(store => {
      setStore(store)
    }).catch((e) => {
      setStore(null)
    }).finally(()=>{
      setLoading((prev) => prev - 1)
    })
  }, [storeId])

  useEffect(() => {
    setLoading((prev) => prev + 1)
    api.storeChain.storeVouchers(storeId, query).then(responseVouchers => {
      setVouchers(() => responseVouchers)
    }).catch((e) => {
      setVouchers(() => [])
    }).finally(() => {
      setLoading((prev) => prev - 1)
    })
  }, [query])

  return <div className={'container'}>
    <div className={'content-wrapper'}>
      <div className={'title-content mb-4'}>
        <h3>{t('store-sales')} - {store?.name ?? ''}</h3>
      </div>
      <div className={'d-flex justify-content-end gap-2 mb-3'}>
        <div className={'col'}></div>
        <div className={'col-auto'}>
          <input className={'form-control'} placeholder={t('store-search-voucher')} value={query} onChange={(e) => setQuery(e.target.value)} />
        </div>
      </div>
      {loading > 0 && <Loader />}
      <div className={'table-responsive'}>
      <table className={'table'}>
        <thead>
        <tr>
          <th>#</th>
          <th>{t('store-voucher')}</th>
          <th>&nbsp;</th>
          <th>{t('store-sale-created')}</th>
          <th>{t('store-sale-price')}</th>
          <th>{t('store-sale-item')}</th>
          <th>{t('store-salesman')}</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
        </tr>
        </thead>
        <tbody>
        {(vouchers ?? []).map((voucher, index) => {
          let status = null
          let isEditEnabled = false
          if (voucher.confirmedAt && !voucher.approvedAt && !voucher.rejectedReason) {
            status = t('voucher-status-forReview')
          } else if (voucher.approvedAt) {
            status = t('voucher-status-approved')
          } else if (voucher.confirmedAt && voucher.rejectedReason) {
            status = t('voucher-status-returned')
            isEditEnabled = true
          } else if (voucher.paidAt) {
            status = `${t('voucher-status-paid')} - ${DateTime.fromISO(voucher.paidAt).toFormat(Consts.UserDateFormat)}}`
          }
          return (
            <tr key={`voucher-item-${voucher.id}`}>
              <td>{index + 1}.</td>
              <td style={{ whiteSpace: 'nowrap' }}>
                { user?.type === Consts.User.Type.StoreChain && !isEditEnabled ? voucher.code : <Link to={`/voucher/${voucher.id}`}>{voucher.code}</Link> }
              </td>
              <td>{((voucher.type & Consts.Voucher.Type.Ukraine) === 0) ? <Icon icon={'cif:sk'} /> : <Icon icon={'cif:ua'} />}</td>
              <td style={{ whiteSpace: 'nowrap' }}>{voucher.appliedAt ? DateTime.fromISO(voucher.appliedAt).toFormat(Consts.UserDateTimeFormat) : '---'}</td>
              <td>
                <Trans i18nKey={'fmCurrency'} values={{ val: (voucher.priceDevice ?? 0) + (voucher.priceAccessories ?? 0) }}/>
              </td>
              <td>{voucher.model}</td>
              <td>{`${voucher.salesman?.name} (${voucher.salesman?.email})`}</td>
              <td className={'d-flex gap-2'}>
                <a href={api.storeChain.getVoucherDocUrl(voucher.id, `zmluva.pdf`)} target={'_blank'}>
                  <span>{t('attachment-contract')}</span>
                </a>
                <a href={api.storeChain.getVoucherDocUrl(voucher.id, `preberak.pdf`)} target={'_blank'}>
                  <span>{t('attachment-protocol')}</span>
                </a>
              </td>
              <td>
                <span className={'fw-semibold'}>{status}</span>
              </td>
            </tr>
          )
        })}
        </tbody>
      </table>
      </div>
      <NoData loading={loading > 0} data={vouchers ?? []} />
    </div>
  </div>
}

export default withTranslation()(StorePage);