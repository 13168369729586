import { Trans, withTranslation } from 'react-i18next'
import React, { useState }        from 'react'
import { Form }                   from '../../components/form/Form'
import FormField                  from '../../components/FormField'
import { Divider }                from '../../components/Divider'
import Loader                     from '../../components/Loader'
import { useApi }                 from '../../api'
import NoData                     from '../../components/NoData'
import VoucherListItem            from '../common/VoucherListItem'
import FormResult                 from '../../components/form/FormResult'

const VoucherSearchPage = ({t}) => {

  const api = useApi()
  const [result, setResult] = useState(null)
  const [loading, setLoading] = useState(null)
  const [vouchers, setVouchers] = useState(null)
  const [selectedVoucherCodes, setSelectedVoucherCodes] = useState([])

  const onSubmit = (data) => {
    setLoading((val) => true)
    setResult(() => null)
    setVouchers(null)
    api.admin.vouchers(data.code.value)
      .then(list => setVouchers(list))
      .catch(e => {
        setResult({ success: false, message: e.error })
      })
      .finally(() => {
        setLoading((val) => false)
      })
  }

  const onDeactivateClick = (e) => {
    if (selectedVoucherCodes.length === 0) {
      return
    }
    if (window.confirm(t('voucher-deactivate-confirm', { count: selectedVoucherCodes.length }))) {
      setLoading((val) => true)
      setResult(null)
      api.admin.deactivateVouchers(selectedVoucherCodes)
        .then(resp => {
          const errors = resp.filter(res => !!res.error).map(res => `${res.voucherCode}: ${res.error}`)
          const success = errors?.length === 0
          setResult({ success: success, message: success ? t('voucher-deactivate-success', { count: selectedVoucherCodes.length }) : errors })
          setSelectedVoucherCodes((prev) => [])
        })
        .catch(e => { setResult({ success: false, message: e.error }) })
        .finally(() => { setLoading((val) => false) })
    }
  }

  const deactivationEnabled = selectedVoucherCodes.length > 0

  return (<div className={'container'}>
    <div className={'content-wrapper d-flex'}>
      <div className={'form-wrapper'}>
        <div className={'title-content'}>
          <h3>{t('lookup-title')}</h3>
        </div>
        <Form onValidFormSubmit={onSubmit}
              defaultState={{code: { value: '', error: null }}}>
          <FormField label={`${t('voucher-search')} *`} name={'code'} hint={t('voucher-search-hint')} />
          <Divider />
          {loading && <Loader />}
          <FormResult result={result} />
          {!loading && <div className={'d-flex flex-row justify-content-between'}>
            <button type={'submit'} className={'btn btn-submit'}>{t('general-search')}</button>
            {deactivationEnabled && <button type={'button'} className={'btn btn-outline-danger'} onClick={onDeactivateClick}>
              <Trans i18nKey={'voucher-deactivate-count'} values={{ count: selectedVoucherCodes.length }} />
            </button>}
          </div>}
        </Form>
        <Divider />
        <div className="list-group">
          {(vouchers ?? []).map(voucher => <VoucherListItem key={`voucher-${voucher.id}`} voucher={voucher} allowedFields={['created', 'code', 'student', 'parent1', 'parent2', 'school', 'salesman', 'deviceInfo', 'attachments', 'edit']}
                                                            isCheckedToDeactivate={selectedVoucherCodes.indexOf(voucher.code) > -1}
                                                            onDeactivateCheckChange={(voucher, checked) => {
                                                              setSelectedVoucherCodes(prev => {
                                                                const index = prev.indexOf(voucher.code)
                                                                if (checked && index === -1) {
                                                                  return [...prev, voucher.code]
                                                                } else if (!checked && index > -1) {
                                                                  prev.splice(index, 1)
                                                                  return [...prev]
                                                                }
                                                                return prev
                                                              })
                                                            }}
          />)}
        </div>
        <NoData loading={loading} data={vouchers} />
      </div>
    </div>
  </div>)
}

export default withTranslation()(VoucherSearchPage)