import { useFormContext }  from './form/FormProvider'
import { withTranslation } from 'react-i18next'
import { Icon }            from '@iconify/react'
import React               from 'react'

function FormField({ t, name, label, type = 'text', hint = null,  link = null, disabled = false }) {

  const { state, handleChange } = useFormContext();

  return (<div className={`form-field ${!!state[name]?.error ? 'has-error' : ''}`}>
    {!!state[name]?.error && <div className={'field-error'}>{t(state[name]?.error)}</div> }
    <div className={'form-group'}>
      <label>{label}</label>
      {link && <a href={link} target={'_blank'} className={'mx-1 text-secondary'}>
        <Icon icon={'mdi:help-circle-outline'} style={{ fontSize: '2.2em' }} />
      </a>}
      {hint && <span className={'hint'}>({hint})</span>}
      {type !== 'textarea' && <input className={'form-control'} type={type} value={state[name]?.value ?? ''} disabled={disabled}
                                     onChange={(e) => handleChange(name, e.target.value)}
                                     onBlur={(e) => handleChange(name, e.target.value.trim())}
      />}
      {type === 'textarea' && <textarea className={'form-control'} value={state[name]?.value ?? ''} disabled={disabled} onChange={(e) => handleChange(name, e.target.value)} />}
    </div>
  </div>)
}

export default withTranslation()(FormField)