import { useApi }                                                        from '../../api'
import { useNavigate, useParams }                  from 'react-router-dom'
import React, { useCallback, useEffect, useState } from 'react'
import { Divider }                                 from '../../components/Divider'
import Loader                                      from '../../components/Loader'
import { Trans, withTranslation }                  from 'react-i18next'
import { extractPrefixed, Form }                   from '../../components/form/Form'
import {
  VALIDATION_ADDRESS_CONTRACT_SUBJECT,
  VALIDATION_ADDRESS_STUDENT,
}                                                  from '../../helper/voucherValidation'
import FormInput                                   from '../../components/FormInput'
import Consts                                      from '../../consts'

const VoucherCheckDataPage = ({ t }) => {

  const api = useApi()
  const navigate = useNavigate()
  const { voucherId } = useParams()
  const [voucher, setVoucher] = useState(null)
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState(null)

  useEffect(() => {
    (async () => {
      const responseVoucher = await api.salesman.get(voucherId)
      setVoucher(responseVoucher)
    })()
  }, [voucherId])

  const isContractSubject = voucher?.contractSubject?.id !== voucher?.student?.id
  const isPersonContractSubject = voucher?.contractSubject?.type === Consts.Subject.Type.person

  const provideDefaultValues = useCallback(() => {
    const result = {}
    const append = (objectPrefix) => {
      Object.keys(voucher[objectPrefix]).forEach(key => {
        result[`${objectPrefix}.${key}`] = { value: voucher[objectPrefix][key], error: null }
      })
    }
    append('student')
    if (isContractSubject) append('contractSubject')
    return result
  }, [voucher])

  const provideValidationRules = useCallback(() => ({
    ...VALIDATION_ADDRESS_STUDENT,
    ...(isContractSubject ? (isPersonContractSubject ? VALIDATION_ADDRESS_CONTRACT_SUBJECT : {}) : {}),
  }), [voucher])

  const onCancelClick = async(e) => {
    e.preventDefault()
    try {
      if (window.confirm(t('voucher-process-cancel-confirm'))) {
        await api.salesman.unlockVoucher(voucher.id)
        navigate(`/`)
      }
    } catch (e) {
      setResult({ success: false, message: e.error ?? t('voucher-model-update-failed'), })
    }
  }

  const onSubmit = async (data) => {
    setResult(() => null)
    setLoading(() => true)
    try {
      const student = extractPrefixed('student.', data)
      await api.salesman.updateSubject(voucher.id, student.id, student)
      if (isContractSubject) {
        const contractSubject = extractPrefixed('contractSubject.', data)
        await api.salesman.updateSubject(voucher.id, contractSubject.id, contractSubject)
      }
      const isUa = (voucher?.type & Consts.Voucher.Type.Ukraine) !== 0
      navigate(`${isUa ? '/ua' : ''}/salesman/voucher/${voucher.id}`)
    } catch (e) {
      console.error("Failed to send register form", e)
      setResult({
        success: false,
        message: e.error ?? t('general-form-failed-to-send'),
      })
    }
    setLoading(() => false)
  }

  return (<>
    <div className={'container'}>
      <div className={'content-wrapper'}>
        <div className={'form-wrapper'}>
          {voucher && <Form
            defaultState={provideDefaultValues()}
            provideValidationRules={provideValidationRules}
            onValidFormSubmit={onSubmit}
          >
            <div className={'title-content'}>
              <h3><Trans i18nKey={'voucher-check-data-title'} values={{ voucher: voucher.code }} /></h3>
              <div className="alert alert-danger" role="alert">
                <Trans i18nKey={'voucher-check-data-subtitle'} />
              </div>
            </div>
            <Divider size={4} />
            {voucher && <VoucherCheckData t={t} voucher={voucher}/>}
            <Divider/>
            <div className={'d-flex flex-column align-items-center'}>
              <p style={{ fontWeight: 600, fontSize: '1.15em' }}><Trans i18nKey={'voucher-check-data-appendix'} /></p>
              <div className={'f-flex flex-row align-self-center gap-2'}>
                {loading && <Loader/>}
                {!loading &&
                <button className="btn btn-primary">{t('voucher-repair-data-and-continue')}</button>}
              </div>
              <Divider size={4} />
              <button type={'button'} className={'btn btn-sm btn-link'} onClick={onCancelClick} disabled={loading}>{t('voucher-process-cancel')}</button>
            </div>
            <Divider/>
            {result?.success === false && <div className="alert alert-danger">{result.message}</div>}
          </Form>}
        </div>
      </div>
    </div>
  </>)
}


const VoucherCheckData = ({ t, voucher }) => {

  const isUa = (voucher.type & Consts.Voucher.Type.Ukraine) !== 0

  const values = {
    'registration-student-title': {
      'registration-name': { value: voucher.student.name },
      'registration-surname': { value: voucher.student.surname },
      'registration-idnumber': { value: voucher.student.idNumber },
      ...(isUa ? {
        'registration-address-ua-card': { value: voucher.student.address, editable: true, name: 'student.address' },
        'registration-town-ua-card': { value: voucher.student.town, editable: true, name: 'student.town' },
      } : {
        'registration-address': { value: voucher.student.address, editable: true, name: 'student.address' },
        'registration-town': { value: voucher.student.town, editable: true, name: 'student.town' },
      }),
      'registration-postcode': { value: voucher.student.postcode, editable: true, name: 'student.postcode' },
      'general-phone': { value: voucher.student.phone },
      'general-email': { value: voucher.student.email },
    },
    'registration-title-parent1': !voucher.contractSubject?.id || voucher.contractSubject.id === voucher.student.id ? null : (
      !!voucher.contractSubject?.surname ? {
        'registration-name': { value: voucher.contractSubject.name },
        'registration-surname': { value: voucher.contractSubject.surname },
        ...(isUa ? {
          'registration-idnumber-ua': { value: voucher.contractSubject.idNumber },
          'registration-address-ua-card': { value: voucher.contractSubject.address, editable: true, name: 'contractSubject.address' },
          'registration-town-ua-card': { value: voucher.contractSubject.town, editable: true, name: 'contractSubject.town' },
        } : {
          'registration-idnumber': { value: voucher.contractSubject.idNumber },
          'registration-address': { value: voucher.contractSubject.address, editable: true, name: 'contractSubject.address' },
          'registration-town': { value: voucher.contractSubject.town, editable: true, name: 'contractSubject.town' },
        }),
        'registration-postcode': { value: voucher.contractSubject.postcode, editable: true, name: 'contractSubject.postcode' },
        'general-phone': { value: voucher.contractSubject.phone },
        'general-email': { value: voucher.contractSubject.email },
      } : {
        'registration-name': { value: voucher.contractSubject.name },
        'registration-ico': { value: voucher.contractSubject.idNumber },
        'general-phone': { value: voucher.contractSubject.phone },
      }
    ),
  }

  return <>
    { Object.keys(values).map(key => {
      if (values[key] === null) {
        return <div key={`empty-item-${key}`}></div>
      }
      return (
        <div key={`data-item-${key}`}>
          <h4>{t(key)}</h4>
          <table className={'table table-sm'}>
            <tbody>
            {!Array.isArray(values[key]) && Object.keys(values[key]).map(keyInner => {
              const innerVal = values[key][keyInner]
              return (<tr key={`tr-${keyInner}`}>
                <th className={'text-end'}>{t(keyInner)}</th>
                {innerVal.editable === true && innerVal.name && <td style={{ width: '70%' }}>
                  <FormInput name={innerVal.name} />
                </td>}
                {!innerVal.editable && <td style={{ width: '70%' }}>{innerVal['value']}</td>}
              </tr>)
            })}
            </tbody>
          </table>
          <Divider />
        </div>
      )
    })}
  </>
}

export default withTranslation()(VoucherCheckDataPage)