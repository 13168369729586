import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation }                      from 'react-router-dom'
import { withTranslation }                        from 'react-i18next'
import Consts                                     from '../consts'
import { useApi }                                 from '../api'
import { AppContext }                             from '../index'
import './header.scss'

const defaultMenuItems = (t) => [
  <a href="https://digitalnyziak.sk/" target="_blank" className="nav-link">{t('header-home')}</a>,
  <a href="https://digitalnyziak.sk/call-centrum/" target="_blank" className="nav-link">{t('header-callcentrum')}</a>,
]

const LogoutMenuItem = ({ t }) => {

  const api = useApi()
  const { setUser } = useContext(AppContext)

  return (<a href={'/'} className={'nav-link'} onClick={async (e) => {
    e.preventDefault()
    api.user.logout()
    setUser(null)
  }}>{t('general-logout')}</a>)
}

const UserNameMenuItem = () => {
  const { user } = useContext(AppContext)
  if (!user) {
    return <></>
  }

  return <div className={'nav-link username'}>{user?.name}</div>
}

function Header({ t }) {

  const location = useLocation()
  const [open, setOpen] = useState(false)
  const { user } = useContext(AppContext)
  const [menuItems, setMenuItems] = useState([])

  const adminMenuItems = (user, t) => {
    let isLimitedAdmin = user.flags & Consts.User.Flags.Readonly
    return [
      <Link to={'/'} className={'nav-link'}>{t('lookup-title')}</Link>,
      isLimitedAdmin ? false : <Link to={'/storeChain/list'} className={'nav-link'}>{t('storeChain-list-title')}</Link>,
      <Link to={'/export'} className={'nav-link'}>{t('export-title')}</Link>,
      <LogoutMenuItem t={t} />
    ].filter(it => it)
  }
  const storeChainMenuItems = (t) => [
    <Link to={'/'} className={'nav-link'}>{t('storeChain-title')}</Link>,
    <LogoutMenuItem t={t} />
  ]
  const salesmanMenuItems = (t, isSupervisor) => {
    let items = []
    if (isSupervisor) {
      items.push(<Link to={'/supervisor/vouchers'} className={'nav-link'}>{t('supervisor-vouchers')}</Link>)
    }
    return items.concat([
      <a href={'/'} className={'nav-link'}>{t('salesman-validation')}</a>, // ignore <Link component> -> navigate with browser router
      <LogoutMenuItem t={t} />
    ])
  }
  const callcenterMenuItems = (t) => [
    <LogoutMenuItem t={t} />
  ]

  useEffect(() => {
    setOpen(false)
  }, [location])

  useEffect(() => {
    let items = defaultMenuItems(t);
    if (user?.type === Consts.User.Type.Salesman) {
      items = salesmanMenuItems(t, user?.flags & Consts.User.Flags.Supervisor)
    } else if (user?.type === Consts.User.Type.Admin) {
      items = adminMenuItems(user, t)
    } else if (user?.type === Consts.User.Type.StoreChain) {
      items = storeChainMenuItems(t)
    } else if (user?.type === Consts.User.Type.Callcenter) {
      items = callcenterMenuItems(t)
    }
    if (user) {
      items = [<UserNameMenuItem />, ...items]
    }
    setMenuItems(items)
  }, [user])

  const toggleMenu = (e) => {
    e.preventDefault()
    setOpen((o) => !o)
  }
  return (
    <header className={'header'}>
      <div className="container d-none d-md-flex">
        <div className="header__partners ms-auto">
          <img src={'/img/logo_eu.svg'} alt={t('logo-eu')} />
          <img src={'/img/logo-md-sk.svg'} alt={t('logo-opii')} />
          <img src={'/img/logo-miiri.svg'} alt={t('logo-mirri')} />
        </div>
      </div>
      <div className="container d-flex">
        <a href="https://digitalnyziak.sk/" target="_blank" className="header__logo">
          <img src={'/img/logo.svg'} alt={t('logo-digiziak')}/>
        </a>

        <ul className="navbar-nav d-none d-lg-flex flex-row flex-wrap bd-navbar-nav ms-auto mt-auto">
          {menuItems.map((link, index) => (<li key={`nav-item-${index}`} className="nav-item">
            {link}
          </li>))}
        </ul>

        <div className="d-lg-none d-flex align-items-center w-100">
          <div className={`menu ms-auto${open ? ' nav-is-toggled' : ''}`} id="ham">
            <div className="nav-top" onClick={toggleMenu}>
              <span></span>
              <span></span>
            </div>
            <nav className={`nav-drill px-5 px-sm-4 px-md-5${open ? ' open' : ''}`}>
              <ul className="navbar-nav">
                {menuItems.map((link, index) => (<li key={`nav-item-${index}`} className="nav-item">
                  {link}
                </li>))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  )
}

export default withTranslation()(Header)