import { useFormContext }  from './form/FormProvider'
import { withTranslation } from 'react-i18next'

function FormSelect({ t, name, label, options = [], hint = null, disabled = false }) {

  const { state, handleChange } = useFormContext();

  return (<div className={`form-field ${!!state[name]?.error ? 'has-error' : ''}`}>
    {!!state[name]?.error && <div className={'field-error'}>{t(state[name]?.error)}</div> }
    <div className={'form-group'}>
      <label>{label}</label>
      {hint && <span className={'hint'}>({hint})</span>}
      <select className={'form-control'} value={state[name]?.value ?? ''} disabled={disabled} onChange={(e) => handleChange(name, e.target.value)}>
        {options.map(it => (<option key={`option-${it.value}`} value={it.value}>{it.text}</option>))}
      </select>
    </div>
  </div>)
}

export default withTranslation()(FormSelect)