import { useApi }                              from '../api'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import React, { useEffect, useRef, useState }  from 'react'
import { Form, ValidationRules }  from '../components/form/Form'
import { Trans, withTranslation } from 'react-i18next'
import FormField                  from '../components/FormField'
import { isUAHost }                            from '../helper/host'
import UAConsts                                from '../ua/consts'
import Consts                                  from '../consts'
import Loader                                  from '../components/Loader'
import { Divider }                             from '../components/Divider'
import ReCAPTCHA                               from 'react-google-recaptcha'
import FormCheckbox                            from '../components/FormCheckbox'

function TransferPage({ t }) {

  const api = useApi()
  const recaptchaRef = useRef()
  const { code, hash } = useParams()
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState(null)

  const onSubmit = async (data) => {
    const captchaToken = recaptchaRef.current.getValue()
    if (!captchaToken) {
      return
    }
    setResult(null)
    setLoading(() => true)
    try {
      let response = await api.voucher.transfer(code, hash, captchaToken, isUAHost())
      setResult({
        success: true,
        message: t('transfer-confirmed')
      })
    } catch (e) {
      console.error("Failed to send verify form", e)
      setResult({
        success: false,
        message: e.error ?? t('transfer-failed'),
      })
    }

    setLoading(() => false)
  }

  return (<div className={'container'}>
    <div className={'content-wrapper'}>
      <div className={'form-wrapper'}>
        <div className={'title-content'}>
          <h3>{t('transfer-title')}</h3>
          <Divider />
        </div>
        <Form
          defaultState={{
            consent: { value: false, error: null },
          }}
          customValidateForm={(state, dispatch) => {
            const result = state.consent.value === true
            if (!result) {
              dispatch({ key: 'consent', payload: { value: state.consent.value, error: 'validation-invalid-checkbox' } })
            }
            return result
          }}
          onValidFormSubmit={onSubmit}
        >
          <FormCheckbox label={t('transfer-consent')} name={'consent'} />
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={!process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? (isUAHost() ? UAConsts.ReCAPTCHA.keyDev : Consts.ReCAPTCHA.keyDev) : (isUAHost() ? UAConsts.ReCAPTCHA.key : Consts.ReCAPTCHA.key)}
            size={'normal'}
          />
          <Divider />
          {loading && <Loader />}
          {result !== null && <div className={result.success ? 'field-success' : 'field-error'}>{result.message}</div>}
          {!loading && (result === null || result.success === false) && <div><button type={'submit'} className={'btn btn-submit primary-button'}>{t('general-send')}</button></div>}
        </Form>
      </div>
    </div>
    <Divider size={5} />
  </div>)
}

export default withTranslation()(TransferPage)