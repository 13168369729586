import React from 'react';
import ColoredSquare from "./ColoredSquare";

function ColoredSquareFlower({type = 0, x = 0, y = 0}) {
    const firstSquares = [
        {x: 5, y: 5},
        {x: 15, y: 5},
        {x: 5, y: 15},
        {x: 15, y: 15},
    ]

    const secondSquares = [
        {x: 10, y: 0},
        {x: 20, y: 10},
        {x: 10, y: 20},
        {x: 0, y: 10},
    ]

    return (
        <div className={'square-flower'} style={{top: `${y}px`, left: `${x}px`}}>
            {firstSquares.map((item, index) => (
                <ColoredSquare
                    key={index}
                    color={type === 0 ? 'yellow' : 'blue'}
                    x={item.x}
                    y={item.y}
                />
            ))}

            {secondSquares.map((item, index) => (
                <ColoredSquare
                    key={index}
                    color={type === 0 ? 'blue' : 'yellow'}
                    x={item.x}
                    y={item.y}
                />
            ))}
        </div>
    );
}

export default ColoredSquareFlower;