import { withTranslation }            from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { useApi }          from '../../api'
import { Link, useParams } from 'react-router-dom'
import Loader              from '../../components/Loader'
import Consts                                              from '../../consts'
import { useCurrentUser }                                  from '../../hooks'

const StoreChainDetailPage = ({ t }) => {

  const api = useApi()
  const [user] = useCurrentUser()
  const { subjectId } = useParams()
  const [subject, setSubject] = useState([])
  const [loading, setLoading] = useState(null)
  const [onlyRejected, setOnlyRejected] = useState(null)

  useEffect(()=>{
    (async () => {
      setLoading((load) => true)
      const response = user?.type === Consts.User.Type.StoreChain ? await api.storeChain.storeChain(['children.vouchersSold', 'children.vouchersRejected']) : await api.admin.storeChainDetail(subjectId, ['children.vouchersSold', 'children.vouchersRejected'])
      setSubject(response)
      setLoading((load) => false)
    })()
  }, [subjectId])

  return (
    <div className={'container'}>
      <div className={'content-wrapper'}>
        <div className={'title-content mb-4'}>
          <h3>{subject?.name ?? ''}</h3>
        </div>
        {loading && <Loader />}
        <div className={'d-flex flex-column align-items-end'}>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="check-rejected" checked={onlyRejected} onChange={(e) => setOnlyRejected(e.target.checked)}/>
              <label className="form-check-label" htmlFor="check-rejected">
                {t('storeChain-only-rejected')}
              </label>
          </div>
        </div>
        <div className="list-group list-group-flush">
          {(subject.children ?? []).filter(store => !onlyRejected || (store.vouchersRejected ?? 0) > 0).map((store) => (
            <Link key={`store-chain-${store.id}`} to={`/store/${store.id}`} className="list-group-item list-group-item-action d-flex justify-content-between align-items-start">
              <div className={'ms-2 me-auto'}>
                {store.name}
              </div>
              {(store.vouchersRejected ?? 0) > 0 && <span className="badge bg-warning rounded-pill me-2">{store.vouchersRejected ?? 0}</span>}
              <span className="badge bg-primary rounded-pill">{store.vouchersSold ?? 0}</span>
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(StoreChainDetailPage)