import React, {useEffect, useState} from 'react';
import './animation.scss';
import ColoredSquare from "./ColoredSquare";
import ColoredSquareFlower from "./ColoredSquareFlower";


function AnimationContainer({type = 'header'}) {
    const yellowSquares = {
        medium: [
            {x: 70, y: 109},
            {x: 166, y: 104},
            {x: 205, y: 62},
            {x: 339, y: 102},
            {x: 368, y: 48},
            {x: 447, y: 22},
            {x: 454, y: 106},
            {x: 566, y: 75},
            {x: 632, y: 65},
            {x: 665, y: 6},
            {x: 751, y: 60},
            {x: 868, y: 111},
            {x: 881, y: 16},
            {x: 953, y: 96},
            {x: 955, y: 33},
            {x: 1028, y: 113},
            {x: 1122, y: 69},
            {x: 1155, y: 10},
            {x: 1215, y: 46},
            {x: 1290, y: 106},
        ],
        small: [
            {x: 90, y: 27},
            {x: 220, y: 3},
            {x: 280, y: 25},
            {x: 350, y: 18},
        ]
    }

    const blueSquares = {
        medium: [
            {x: 20, y: 20},
            {x: 122, y: 72},
            {x: 220, y: 47},
            {x: 308, y: 73},
            {x: 381, y: 121},
            {x: 476, y: 42},
            {x: 675, y: 15},
            {x: 766, y: 45},
            {x: 978, y: 24},
            {x: 978, y: 68},
            {x: 1080, y: 76},
            {x: 1165, y: 19},
            {x: 1190, y: 119},
            {x: 1240, y: 56},
            {x: 1275, y: 20},
        ],
        small: [
            {x: 20, y: 4},
            {x: 150, y: 27},
            {x: 280, y: 4},
            {x: 400, y: 14},
            {x: 430, y: 34},
        ]
    }

    const flowersType0 = {
        medium: [
            {x: 615, y: 108},
            {x: 1105, y: 112},
        ],
        small: [
            // {x: 450, y: 10},
        ]
    }

    const flowersType1 = {
        medium: [
            {x: 157, y: 18},
            {x: 812, y: 77},
        ],
        small: [
            {x: 180, y: 10},
        ]
    }

    const [isVisible, setIsVisible] = useState(true);
    const [screenSize, setScreenSize] = useState('small')
    // const handleBlockVisible = () => {
    //     setIsVisible(true);
    // };

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const block = document.querySelector(`#${type}-animation`);
    //         const blockPosition = block.getBoundingClientRect().top;
    //         const windowHeight = window.innerHeight;
    //
    //         if (blockPosition < windowHeight - 100) {
    //             handleBlockVisible();
    //         }
    //     };
    //
    //     window.addEventListener('scroll', handleScroll);
    //
    //     if (type === 'header'){
    //         setTimeout(() => {
    //             handleBlockVisible()
    //         }, 500)
    //     }
    //
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    const handleResize = () => {
        if (window.innerWidth > 600) {
            setScreenSize('medium')
        } else {
            setScreenSize('small')
        }
    }

    useEffect(() => {
        handleResize()
        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, []);

    return (
        <div
            className={`container animation-container ${isVisible ? 'visible' : ''}`}
            id={`${type}-animation`}
        >
            {yellowSquares[screenSize].map((value, index) => (
              <ColoredSquare key={index} color={"yellow"} x={value.x} y={value.y} />
            ))}
            {blueSquares[screenSize].map((value, index) => (
              <ColoredSquare key={index} color={"blue"} x={value.x} y={value.y} />
            ))}
            {flowersType0[screenSize].map((value, index) => (
              <ColoredSquareFlower key={index} type={0} x={value.x} y={value.y}/>
            ))}
            {flowersType1[screenSize].map((value, index) => (
              <ColoredSquareFlower key={index} type={1} x={value.x} y={value.y}/>
            ))}
        </div>
    );
}

export default AnimationContainer;
