module.exports = Object.freeze({
  Subject: {
    Type: {
      person: 'person',
      institution: 'institution',
      school: 'school',
    }
  },
  User: {
    Type: {
      Admin: 'admin',
      School: 'school',
      Callcenter: 'callcenter',
      StoreChain: 'storeChain',
      Salesman: 'salesman',
    },
    Flags: {
      Supervisor: 1,
      Readonly: 2,
      Control: 4,
    }
  },
  Voucher: {
    Type: {
      FirstClass: 1,
      Ukraine: 16,
      Deactivated: 1024,
    },
    Consent: {
      SaleWithoutSetup: 128
    },
  },
  VoucherAttachment: {
    nameContract: 'Sken podpisanej zmluvy',
    nameInvoice: 'Daňový doklad',
    nameAcceptanceProtocol: 'Sken podpisaného preberacieho protokolu',
    nameAcceptanceAffidavit: 'Cestne prehlasenie',
  },
  Storage: {
    keyCookiesAnalytics: 'cookies-analytics',
    keyCookiesRequired: 'cookies-required',
  },
  GoogleTagManager: {
    id: 'GTM-WDW2DBLC',
  },
  GoogleAnalytics: {
    id: 'G-9TFVVX91BD',
  },
  ReCAPTCHA: {
    key: '6Ldrxa4kAAAAAKM8BfrO_wQZunht5DDk7ec6Qd1F',
    keyDev: '6LcYb64kAAAAAHZosw1N-oQn7vsAwi73Vu0o8wVn',
  },
  Contact: {
    tel: '02 / 32284466',
    email: 'info@digitalnyziak.sk',
  },
  UserDateTimeFormat: 'dd.LL.yyyy HH:mm:ss',
  UserDateFormat: 'dd.LL.yyyy HH:mm:ss',
  IsoDateFormat: 'yyyy-LL-dd',
  ShortApiDateFormat: 'yyyyLLdd',
});
