import { useApi }                from '../../api'
import React, { useState }       from 'react'
import { DateTime }              from 'luxon'
import Consts                    from '../../consts'
import { Form, ValidationRules } from '../../components/form/Form'
import FormField                 from '../../components/FormField'
import { Divider }               from '../../components/Divider'
import FormResult                from '../../components/form/FormResult'
import { withTranslation }       from 'react-i18next'
import Loader                    from '../../components/Loader'

const ImportIfoPage = ({ t }) => {

  const api = useApi()
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState(null)
  const [logs, setLogs] = useState(null)

  const onSubmit = (data, extension) => {
    setLogs(l => null)
    setResult(l => null)
    setLoading(() => true)
    let ifoNumbers = data.references.value.split(/\s/)?.filter(val => !!val)
    api.admin.importIfo(ifoNumbers).then(response => {
      setLogs(() => response?.map(res => `${res.ifo} - ${res.error ?? 'OK'}`))
    }).catch(error => {
      setResult({ success: false, message: error.error ?? t('import-ifo-failed') })
    }).finally(() => {
      setLoading(() => false)
    })
  }

  return (
    <div className={'container'}>
      <div className={'content-wrapper'}>
        <div className={'title-content mb-4'}>
          <h3>{t('import-ifo-title')}</h3>
        </div>
        <div className={'form-wrapper'}>
          <Form
            defaultState={{
              date: { value: DateTime.now().toISODate(), error: null },
            }}
            provideValidationRules={() => ({
              'references': { regex: ValidationRules.digitsMultiline, error: 'validation-ifonumbers-invalid' },
            })}
            onValidFormSubmit={onSubmit}
          >
            <div className={'row'}>
              <div className={'col-12'}>
                <FormField label={t('import-ifo-numbers')} name={'references'} type={'textarea'} hint={t('import-ifo-numbers-hint')} />
              </div>
            </div>
            <Divider />
            <FormResult result={result} />
            {logs && <div>
              {logs.map((log, index) => (<p key={`log-${index}`} className={'m-0'}>{log}</p>))}
              <Divider />
            </div>}
            <div className={'d-flex justify-content-start gap-2'}>
              {loading && <Loader />}
              {!loading && <button type={'submit'} value={'xml'} className={'btn btn-primary btn-sm'}>{t('general-send')}</button>}
            </div>
          </Form>
        </div>
      </div>
    </div>
  )

}

export default withTranslation()(ImportIfoPage)