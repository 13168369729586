import { withTranslation }       from 'react-i18next'
import React, { useState }       from 'react'
import { useApi }                from '../../api'
import FormField                 from '../../components/FormField'
import { Divider }               from '../../components/Divider'
import Consts                    from '../../consts'
import { Form, ValidationRules } from '../../components/form/Form'
import FormResult                from '../../components/form/FormResult'
import { DateTime }              from 'luxon'

const ExportPage = ({ t }) => {

  const api = useApi()
  const [result, setResult] = useState(null)

  const onSubmit = (data, entity) => {
    let from = DateTime.fromFormat(data.dateFrom.value, Consts.IsoDateFormat)
    let to = DateTime.fromFormat(data.dateTo.value, Consts.IsoDateFormat)
    const extension = {
      'pohoda': 'xml',
      'mirri': 'csv'
    }[entity]
    window.open(api.common.getExportDataUrl(entity, extension, from.toFormat(Consts.ShortApiDateFormat), to.toFormat(Consts.ShortApiDateFormat)), '_blank')
  }

  const customValidateForm = (state, dispatch) => {
    try {
      let from = DateTime.fromFormat(state['dateFrom'].value, Consts.IsoDateFormat)
      let to = DateTime.fromFormat(state['dateTo'].value, Consts.IsoDateFormat)
      if (from > to) {
        dispatch({ key: 'dateFrom', payload: { value: state['dateFrom'].value, error: 'validation-date-preceded' } })
        return false
      }
    } catch (e) {
      return false
    }
    return true
  }

  return (
    <div className={'container'}>
      <div className={'content-wrapper'}>
        <div className={'title-content mb-4'}>
          <h3>{t('export-title')}</h3>
        </div>
        <div className={'form-wrapper'}>
          <Form
            defaultState={{
              dateFrom: { value: '', error: null },
              dateTo: { value: '', error: null },
            }}
            provideValidationRules={() => ({
              'dateFrom': { regex: ValidationRules.date, error: 'validation-invalid-date' },
              'dateTo': { regex: ValidationRules.date, error: 'validation-invalid-date' },
            })}
            customValidateForm={customValidateForm}
            onValidFormSubmit={onSubmit}
          >
            <div className={'row'}>
              <div className={'col-12 col-md-6'}>
                <FormField label={t('export-dateFrom')} name={'dateFrom'} type={'date'} />
              </div>
              <div className={'col-12 col-md-6'}>
                <FormField label={t('export-dateTo')} name={'dateTo'} type={'date'} />
              </div>
            </div>
            <Divider />
            <FormResult result={result} />
            <div className={'d-flex justify-content-start gap-2'}>
              {/*<button type={'submit'} value={'xlsx'} className={'btn btn-primary btn-sm'}>{t('export-action-xlsx')}</button>*/}
              <button type={'submit'} value={'pohoda'} className={'btn btn-primary btn-sm'}>{t('export-action-xml')}</button>
              <button type={'submit'} value={'mirri'} className={'btn btn-secondary btn-sm'}>{t('export-action-mirri')}</button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(ExportPage)