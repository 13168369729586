import { Trans, withTranslation }     from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { useApi }                     from '../../api'
import { DateTime, Duration }         from 'luxon'
import { Divider }                    from '../../components/Divider'
import { Icon }                       from '@iconify/react'
import NoData                         from '../../components/NoData'
import { Link }                       from 'react-router-dom'
import { Form }                       from '../../components/form/Form'
import FormField                      from '../../components/FormField'

const SupervisorVouchersPage = ({ t }) => {

  const api = useApi()
  const [vouchers, setVouchers] = useState([]);
  const [loading, setLoading] = useState(null)

  const reloadData = (from, to) => {
    api.supervisor.vouchers(from, to)
      .then(data => setVouchers(data))
      .finally(() => setLoading((e) => false))
  }

  const defaultDateFrom = DateTime.now().startOf('week').toISODate()
  const defaultDateTo = DateTime.now().plus(Duration.fromObject({ days: 1 })).toISODate()

  useEffect(() => {
    setLoading((e) => true)
    reloadData(defaultDateFrom, defaultDateTo)
  }, [])

  return <div className={'container-fluid'}>
    <div className="title-content">
      <h3>{t('supervisor-vouchers')}</h3>
    </div>
    <Divider size={3} />
    <Form
      defaultState={{
        dateFrom: { value: defaultDateFrom, error: null },
        dateTo: { value: defaultDateTo, error: null },
      }}
      onValidFormSubmit={(data) => {
        reloadData(data.dateFrom.value, data.dateTo.value)
      }}
    >
      <div className={'row align-items-bottom'}>
      <div className={'col'} />
      <div className="col-auto">
        <FormField label={'Od'} type={'date'} name={'dateFrom'} t={t} />
      </div>
      <div className="col-auto">
        <FormField label={'Do'} type={'date'} name={'dateTo'} t={t} />
      </div>
      <div className="col-auto d-flex">
        <button type="submit" className="btn btn-outline-primary btn-sm align-self-end mb-1">{t('general-search')}</button>
      </div>
      </div>
    </Form>
    <Divider size={3} />
    <div className="table-responsive">
    <table className={'table'}>
      <thead>
        <tr>
          <th>{t('vouchers-code')}</th>
          <th>{t('vouchers-applied')}</th>
          <th>{t('vouchers-confirmed')}</th>
          <th>{t('vouchers-student')}</th>
          <th>{t('vouchers-priceDevice')}</th>
          <th>{t('vouchers-priceAccessories')}</th>
          <th>{t('vouchers-priceTotal')}</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
      {vouchers.map(voucher => (<tr key={`vocher-${voucher.id}`}>
        <td><Link to={`/salesman/voucher/${voucher.id}`}>{voucher.code}</Link></td>
        <td>{voucher.appliedAt ? <Trans i18nKey={'fmDateTime'} values={{ val: DateTime.fromISO(voucher.appliedAt) }} /> : '---'}</td>
        <td>{voucher.confirmedAt ? <Trans i18nKey={'fmDateTime'} values={{ val: DateTime.fromISO(voucher.confirmedAt) }} /> : '---'}</td>
        <td>{`${voucher.student?.name} ${voucher.student?.surname}`}</td>
        <td><Trans i18nKey={'fmCurrency'} values={{ val: voucher.priceDevice ?? 0 }}/></td>
        <td><Trans i18nKey={'fmCurrency'} values={{ val: voucher.priceAccessories ?? 0 }}/></td>
        <td><Trans i18nKey={'fmCurrency'} values={{ val: (voucher.priceDevice ?? 0) + (voucher.priceAccessories ?? 0) }}/></td>
        <td>
          <a href={api.salesman.getVoucherDocUrl(voucher.id, `doklad.pdf`)} target={'_blank'}
             className={'d-flex text-primary'}>
            <Icon icon={'mdi:file-document-outline'} style={{ fontSize: '1.5em' }} />
            <span>{t('attachment-invoice')}</span>
          </a>
        </td>
        <td>{voucher.rejectedReason ?? ''}</td>
      </tr>))}
      </tbody>
    </table>
    <NoData loading={loading} data={vouchers} />
    </div>
  </div>
}

export default withTranslation()(SupervisorVouchersPage)