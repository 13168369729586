import { useFormContext }  from './form/FormProvider'
import { withTranslation } from 'react-i18next'

function FormInput({ t, name, type = 'text', disabled = false }) {

  const { state, handleChange } = useFormContext();

  return (<>
      {type !== 'textarea' && <input className={'form-control form-control-sm'} type={type} value={state[name]?.value ?? ''} disabled={disabled}
                                     onChange={(e) => handleChange(name, e.target.value)}
                                     onBlur={(e) => handleChange(name, e.target.value.trim())}
      />}
      {type === 'textarea' && <textarea className={'form-control'} value={state[name]?.value ?? ''} disabled={disabled} onChange={(e) => handleChange(name, e.target.value)} />}

    {!!state[name]?.error && <div className={'field-error'}>{t(state[name]?.error)}</div> }
  </>)
}

export default withTranslation()(FormInput)