export default function AgreementPage() {
  return <div className={'container'}>
    <div className={'content-wrapper'}>
    <h1>SÚHLAS DOTKNUTEJ OSOBY SO SPRACOVANÍM OSOBNÝCH ÚDAJOV</h1>
    <h3>na účely overenia splnenia podmienok cieľovej skupiny vo verejných registroch</h3>
    <p><i>poskytovaný v súlade s ustanoveniami § 7 Nariadenia Európskeho parlamentu a Radu (EÚ) 2016/679 z 27. apríla 2016 o ochrane fyzických osôb pri spracúvaní osobných údajov a o voľnom pohybe takýchto údajov, ktorým sa zrušuje smernica 95/46/ES (ďalej ako „GDPR“)</i></p>

    <p>Národná koalícia pre digitálne zručnosti a povolania Slovenskej republiky, so sídlom Mlynské nivy 18890/5, 821 09 Bratislava Ružinov, Slovenská republika, IČO: 52 828 123, zapísanému v Registri záujmových združení právnických osôb Okresného úradu Bratislava (ďalej len „Digitálna koalícia“) je prijímateľom nenávratného finančného príspevku z Operačného programu Integrovaná infraštruktúra 2014 – 2020 a realizátorom národného projektu „Digitálny príspevok pre žiakov Slovenskej republiky“ (ďalej ako „Projekt“), ktorého predmetom je vybavenie cieľových skupín Projektu digitálnym vybavením z príspevku poskytnutého na nákup digitálneho zariadenia (ďalej len „Digitálny príspevok“).</p>
    <p>Týmto ako žiak, resp. jeho zákonný zástupca konajúci za žiaka (ďalej spolu ako „Dotknutá osoba“) označený v registračnom formulári v súvislosti s mojou žiadosťou o zaradenie do zoznamu oprávnených príjemcov Digitálneho príspevku a jeho následného čerpania udeľujem súhlas Digitálnej koalícii so spracovaním osobných údajov Dotknutej osoby za účelom overenia a získania potvrdenia od orgánov verejnej správy o tom, či žiak spĺňa kritériá cieľovej skupiny Projektu a je oprávnený na získanie Digitálneho príspevku.</p>
    <p>Súhlasím s tým, aby Digitálna koalícia spracúvala nasledovné údaje:
      <ol type="a">
        <li>Identifikačné údaje Dotknutej osoby: meno a priezvisko, rodné číslo, adresu, emailovú adresu a telefónne číslo;</li>
        <li>Identifikačné údaje zákonného zástupcu Dotknutej osoby: meno a priezvisko, rodné číslo, adresu, emailovú adresu a telefónne číslo (ak je zákonným zástupcom Dotknutej osoby právnická osoba, tak jej názov, adresu sídla, IČO a údaje štatutára, teda jeho meno a priezvisko, rodné číslo, emailová adresa a telefónne číslo);</li>
        <li>Údaje súvisiace s príznakom špecifikujúcim cieľovú skupinu: tento údaj spočíva v zaškrtnutí políčka, ktoré označí Dotknutú osobu do niektorej (alebo do viacerých) zo sociálnych kategórií. Dotknutá osoba môže byť označená za (i) žiaka prvého ročníka strednej školy v školskom roku 2022/2023, za (ii) člena domácnosti v hmotnej núdzi, za (iii) člena domácnosti, ktorá neprekračuje 60% medián príjmu, alebo za (iv) žiaka, ktorý má vyjadrenie zariadenia poradenstva a prevencie o tom, že jeho špeciálne výchovnovzdelávacie potreby vyplývajú výlučne z jeho vývinu v sociálne znevýhodnenom prostredí;</li>
        <li>Výsledok overenia splnenia podmienok cieľovej skupiny.</li>
      </ol>
    </p>
    <p>Súhlasím s tým, aby Digitálna koalícia spracovala tieto údaje v uvedenom rozsahu za účelom overenia oprávnenosti Dotknutej osoby na získanie Digitálneho príspevku, vrátane overenia identifikácie osoby uplatňujúcej Digitálny príspevok v predzmluvných vzťahoch u zmluvných predajcov pred uzavretím zmluvy o participácii.</p>
    <p>Som oboznámený s tým, že na základe tohto súhlasu, údaje, ktoré som poskytol v rámci registračného formulára za účelom zapojenia sa do Projektu, Digitálna koalícia poskytuje vybraným orgánom verejnej správy, ktoré v rámci vykonávania zverených úloh a presadzovania verejného záujmu, budú spracúvať údaje Dotknutej osoby za účelom overenia splnenia kritérií niektorej z cieľových skupín Projektu v informačných systémoch verejnej správy a následného poskytnutia tejto informácie o overení splnenia podmienok Digitálnej koalícií, čo bude slúžiť ako predpoklad pre zaradenie žiaka medzi oprávnených príjemcov Digitálneho príspevku a plnenie našich povinností Digitálnej koalície v zmysle Zmluvy o participácii žiaka na Projekte (vrátane overenia identifikácie v predzmluvných vzťahoch u zmluvných predajcov pred uzavretím zmluvy). Vybranými orgánmi verejnej správy je najmä Ministerstvo investícií, regionálneho rozvoja a informatizácie, Ministerstvo dopravy a výstavby, Ministerstvo školstva, vedy a výskumu, Ministerstvo práce, sociálnych vecí a rodiny v spolupráci so Sociálnou poisťovňou, Finančnou správou slovenskej republiky a Úradom práce, sociálnych vecí a rodiny Slovenskej republiky.</p>
    <p>Udelenie súhlasu s (i) poskytnutím údajov zo strany Digitálnej koalície orgánom verejnej správy za účelom vykonania overenia, (ii) spracúvania poskytnutých údajov orgánmi verejnej správy vykonávajúcimi overenie splnenia podmienok cieľovej skupiny Projektu a (iii) následné poskytnutie výsledku overenia Digitálnej koalícii je nevyhnutnou podmienkou plnenia Zmluvy o participácii žiaka na Projekte.</p>
    <p>Beriem na vedomie, že v prípade odvolania tohto súhlasu, resp. neudelenia tohto súhlasu sa nebudem môcť zúčastniť Projektu a/alebo zaradiť medzi oprávnených príjemcov Digitálneho príspevku.</p>
    <p>Súhlas dávam na obdobie (i) trvania zmluvného vzťahu s Digitálnou koalíciou a 10 rokov po jeho skončení alebo (ii) do 31.12.2028, podľa toho, čo nastane neskôr.</p>
    <p>Poskytnutím tohto súhlasu zároveň vyhlasujem, že som bol/a oboznámený/á s Podmienkami ochrany osobných údajov, ktoré sú verejne dostupné na webovej stránke <a href={'www.digitalnyziak.sk'} target={'_blank'}>www.digitalnyziak.sk</a> .</p>
    <p>Pri spracúvaní osobných údajov v žiadnom prípade nebude dochádzať k cezhraničnému prenosu do tretích krajín.</p>
    <p>Tento súhlas so spracúvaním osobných údajov poskytujem dobrovoľne a môžem ho kedykoľvek odvolať písomnou formou zaslanou do sídla Digitálnej koalície alebo emailom zaslaným na adresu <a href={'mailto:gdpr@digitalnyziak.sk'}>gdpr@digitalnyziak.sk</a>. Beriem na vedomie, že odvolaním tohto súhlasu nie je dotknutá zákonnosť spracúvania osobných údajov pred jeho odvolaním. Zároveň vyhlasujem, že všetky mnou poskytnuté osobné údaje sú pravdivé a správne.</p>
    <p>Zároveň potvrdzujem, že som bol poučený/bola poučená o svojich právach pri spracovaní osobných údajov.</p>
    <p>Tento súhlas bol udelený dňom odoslania registračného formulára pre participáciu v Projekte.</p>
  </div>
  </div>
}
