import { withTranslation } from 'react-i18next'
import { Icon }            from '@iconify/react'
import { useApi }          from '../../api'
import { useParams }           from 'react-router-dom'
import { useEffect, useState } from 'react'

const VoucherResultPage = ({ t }) => {

  const api = useApi()
  const { voucherId } = useParams()
  const [voucher, setVoucher] = useState(null)

  useEffect(() => {
    (async () => {
      try {
        let response = await api.salesman.get(voucherId)
        setVoucher(response)
      } catch (e) {
        console.error(e)
      }
    })()
  }, [voucherId])

  return (
    <div className={'container'}>
      <div className={'content-wrapper bg-success-subtle'}>
        <div className={'form-wrapper text-emphasis-success text-center p-4'}>
          <h4 className={'mb-4'}>{t('voucher-result-title')}</h4>
          {voucher && <div className={'d-flex flex-row justify-content-center'}>
            <a href={api.salesman.getVoucherDocUrl(voucher.id, `doklad.pdf`)} target={'_blank'}
               className={'d-flex flex-column align-items-center justify-content-center btn btn-link btn-lg text-primary'}>
              <Icon icon={'mdi:file-document-outline'} style={{ fontSize: '4.5em' }} />
              <span>{t('attachment-invoice')}</span>
            </a>
          </div>}
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(VoucherResultPage)