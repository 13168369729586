import { withTranslation }  from 'react-i18next'
import { useRef, useState } from 'react'
import './file-upload.scss'
import { useApi }           from '../api'
import Consts               from '../consts'
import { Icon }                             from '@iconify/react'
import { isRequiredFile, sanitizeFileName } from '../helper/fileutils'

const fileToBase64 = (file) => {
  return new Promise(((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      let groups = /base64,(.+)/gm.exec(reader.result)
      resolve(groups[1])
    };
    reader.onerror = function (error) {
      reject(error)
    };
  }))
}

const deleteAttachment = async (api, userType, file, t) => {
  if (!!file.id) {
    try {
      if (window.confirm(t('attachment-delete-confirm'))) {
        await api.common.deleteAttachment(userType, file.id)
        return true
      }
      return false
    } catch (e) {
      return false
    }
  } else {
    return Promise.resolve(true)
  }
}

const uploadAttachment = async (api, userType, voucherId, file, t) => {
  try {
    return await api.common.uploadAttachment(userType, voucherId, file.fileName, file.mimeType, file.base64)
  } catch (e) {
    window.alert(`${t('attachment-sync-failed')}` + (e.error ? `(${e.error})` : ''))
    return Promise.resolve(null)
  }
}

function FilesUpload(props) {

  const { t, voucherId, files, setFiles, disabled, userType = 'salesman' } = props
  const api = useApi()
  const [triggeredIndex, setTriggeredIndex] = useState(null)

  const inputRef = useRef()

  const remove = (index) => {
    let fileToRemove = files[index]
    deleteAttachment(api, userType, fileToRemove, t).then(result => {
      if (result) {
        setFiles(() => {
          let result = [...files]
          if (isRequiredFile(fileToRemove)){
            result[index] = { fileName: result[index].fileName, mimeType: null, base64: null }
          } else {
            result.splice(index, 1)
          }
          return result
        })
      }
    })
  }

  const onUploadClick = (index) => (e) => {
    e.preventDefault()
    setTriggeredIndex(index)
    inputRef.current.click()
  }

  const onFileChange = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    let bFile = e.target.files[0]
    let file = {
      fileName: triggeredIndex === null ? bFile.name : files[triggeredIndex].fileName,
      mimeType: bFile.type,
      base64: await fileToBase64(bFile)
    }
    e.target.value = null
    uploadAttachment(api, userType, voucherId, file, t).then(responseFile => {
      if (responseFile !== null) {
        setFiles(() => {
          let result = [...files]
          if (triggeredIndex === null) {
            result.push(responseFile)
          } else {
            result[triggeredIndex] = responseFile
          }
          return result
        })
      }
    }).finally(() => {
      setTriggeredIndex(null)
    })
  }

  return (<>
    <div className="list-group list-group-flush text-center">
    {files.map((file, index) => (<div className="list-group-item list-group-item-action" key={`attachment-${index}`}>
      <div className="d-flex w-100 align-items-center gap-2">
        <div className={'d-flex flex-column align-items-start'}>
          <input className={'form-control input-attachment-name'} value={file.fileName ?? ''} disabled={true} />
          {file.link && <a className={'ps-1'} href={file.link.href} target={'_blank'}>{t(file.link.title)}</a>}
        </div>
        <div className={'flex-grow-1'}>&nbsp;</div>
        {!!file.id && <a href={api.common.getVoucherAttachmentUrl(userType, file.id, sanitizeFileName(file.fileName, file.mimeType))} target={'_blank'} title={file.fileName}>
          <Icon icon={'mdi:file-document-outline'} style={{ fontSize: '2.5em' }} />
        </a>}
        {file.base64 && <span className={'text-muted'}>{t('attachment-sync-warning')}</span>}
        {!disabled && !file.base64 && !file.id && <button className={'btn btn-link btn-list-action'} onClick={onUploadClick(index)}>{`${t('attachment-upload')}${file.required ? ' *' : ''}`}</button>}
        {!disabled && (file.base64 || file.id) && <button className={'btn btn-danger btn-list-action'} onClick={(e) => {
          e.preventDefault()
          remove(index)
        }}>x</button>}
      </div>
    </div>))}
    {!disabled && <a href="#" className="list-group-item list-group-item-action px-2 py-4" onClick={onUploadClick(null)}>
        <p className="text-muted mb-0">{t('attachment-add')}</p>
    </a>}
  </div>
  <input type={'file'} ref={inputRef} style={{ display: 'none' }} onChange={onFileChange} />
  </>)
}

export default withTranslation()(FilesUpload)