import { Trans, withTranslation }                           from 'react-i18next'
import { useApi }                     from '../../api'
import React, { useEffect, useState } from 'react'
import { Form, ValidationRules }      from '../../components/form/Form'
import { Divider }                                          from '../../components/Divider'
import FilesUpload                                          from '../../components/FilesUpload'
import Loader                                               from '../../components/Loader'
import { useParams, useNavigate }                           from 'react-router-dom'
import { Icon }                                             from '@iconify/react'
import { sanitizeRequiredFiles, hasAllRequiredFilesFilled } from '../../helper/voucherValidation'
import FormField                                            from '../../components/FormField'
import FormSelect                                           from '../../components/FormSelect'
import { DateTime }                                         from 'luxon'
import Consts                                               from '../../consts'
import FormCheckbox                                         from '../../components/FormCheckbox'

const INNER_ADDITIONAL_OPTIONAL_FILES = [
  { fileName: Consts.VoucherAttachment.nameAcceptanceAffidavit, mimeType: null, base64: null, required: false,
    link: { title: 'attachment-affidavit', href: '/docs/Ukrajinský_žiak_Čestné_vyhlásenie_zákonného_zástupcu.docx' }
  },
]

const VoucherApplyPage = ({ t }) => {

  const api = useApi()
  const navigate = useNavigate()
  const { voucherId } = useParams()

  const [voucher, setVoucher] = useState(null)
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState(null)
  const [files, setFiles] = useState([])
  const [error, setError] = useState(null)
  const [prices, setPrices] = useState([0, 0, 0])
  const [isPriceAccessoriesRequired, setIsPriceAccessoriesRequired] = useState(false)
  const [allRequiredFieldsFilled, setAllRequiredFieldsFilled] = useState(false)
  const [isSaleWithoutSetup, setIsSaleWithoutSetup] = useState(false)

  const allowedAttachments = !!voucher?.model
  const isEditDisabled = voucher?.confirmedAt && Math.abs(DateTime.fromISO(voucher.confirmedAt).diffNow('minutes').minutes) > 48 * 60
  const isUaVoucher = (voucher?.type & Consts.Voucher.Type.Ukraine) !== 0

  const setFilesImpl = (fls) => {
    const newFiles = typeof fls === 'function' ? fls() : fls
    setAllRequiredFieldsFilled(hasAllRequiredFilesFilled(newFiles))
    setFiles(newFiles)
  }

  const sanitizeFiles = (voucher, files) => {
    let fls = sanitizeRequiredFiles(files)
    if ((voucher?.type & Consts.Voucher.Type.Ukraine) === 0) {
      // is ua voucher
      return fls
    }
    let result = fls ?? []
    INNER_ADDITIONAL_OPTIONAL_FILES.forEach((file) => {
      if (!result.some(resultFile => resultFile.fileName === file.fileName)) {
        result.push(file)
      }
    })
    return result
  }

  useEffect(() => {
    (async () => {
      try {
        let response = await api.salesman.get(voucherId)
        setVoucher(response)
        setFilesImpl(sanitizeFiles(response, response.attachments))
        setIsPriceAccessoriesRequired(!!response?.priceAccessories && response?.priceAccessories > 0)
        setPrices((prcs) => [
          response?.priceDevice ?? 0,
          response?.priceAccessories ?? 0,
          (response?.priceDevice ?? 0) + (response?.priceAccessories ?? 0)
        ])
        setIsSaleWithoutSetup((response?.consent & Consts.Voucher.Consent.SaleWithoutSetup) !== 0)
      } catch (e) {
        setError(e.error ?? t('voucher-cant-load'))
      }
    })()
  }, [voucherId])

  const provideValidationRules = () => {
    let rules = {
      model: { regex: ValidationRules.notEmpty, error: t('validation-cant-be-empty') },
      description: { regex: ValidationRules.notEmpty, error: t('validation-must-be-selected') },
      serialNumber: { regex: ValidationRules.notEmpty, error: t('validation-cant-be-empty') },
      priceDevice: { regex: ValidationRules.notEmpty, error: t('validation-cant-be-empty') },
      idCard: { regex: ValidationRules.notEmpty, error: t('validation-cant-be-empty') },
    }
    if (isPriceAccessoriesRequired) {
      rules['descriptionAccessories'] = { regex: ValidationRules.notEmpty, error: t('validation-cant-be-empty') }
    }
    return rules
  }

  const customValidateForm = (state, dispatch, triggeredKey) => {
    if (triggeredKey !== 'save') {
      let allRequiredFiles = hasAllRequiredFilesFilled(files)
      if (!allRequiredFiles) {
        setResult({ success: false, message: t('attachment-pleaseUpload') })
        return false
      }
    }
    return true
  }

  const onSubmit = async (data, triggeredKey) => {
    setLoading((t) => true)
    setResult((d) => null)
    let requestData = {}
    Object.keys(data).forEach((key) => {
      requestData[key] = data[key].value
    })
    requestData.isSaleWithoutSetup = isSaleWithoutSetup
    try {
      await api.salesman.updateVoucher(voucher.id, requestData)
      let updatedVoucher = await api.salesman.get(voucher.id)
      setFilesImpl(sanitizeFiles(updatedVoucher, updatedVoucher.attachments))
      setVoucher(updatedVoucher)
      setResult({ success: true, message: t('voucher-model-updated'), })
    } catch (e) {
      console.error("Failed to update model for voucher", e)
      setResult({ success: false, message: e.error ?? t('voucher-model-update-failed'), })
    }
    if (triggeredKey === 'finish') {
      try {
        await api.salesman.confirmVoucher(voucher.id)
        const isUa = (voucher?.type & Consts.Voucher.Type.Ukraine) !== 0
        navigate(`${isUa ? '/ua' : ''}/salesman/voucher/result/${voucher.id}`)
      } catch (e) {
        setResult({ success: false, message: e.error ?? t('voucher-model-update-failed'), })
      }
    }
    setLoading((t) => false)
  }

  const onCancelClick = async(e) => {
    e.preventDefault()
    try {
      if (window.confirm(t('voucher-process-cancel-confirm'))) {
        await api.salesman.unlockVoucher(voucher.id)
        navigate(`/`)
      }
    } catch (e) {
      setResult({ success: false, message: e.error ?? t('voucher-model-update-failed'), })
    }
  }

  const onFormValueChange = (key, value, state) => {
    if (key === 'priceAccessories') {
      setIsPriceAccessoriesRequired(value !== '' && parseInt(value) !== 0)
    }
    if (['priceDevice', 'priceAccessories'].indexOf(key) > -1) {
      setPrices((prcs) => {
        let result = [...prcs]
        result[key === 'priceDevice' ? 0 : 1] = value ? parseFloat(value) : 0
        result[2] = Math.round((result[0] + result[1]) * 100) / 100
        return result
      })
    }
  }

  return (
    <div className={'container'}>
      <div className={'content-wrapper'}>
        <div className={'form-wrapper'}>
          <div className={'title-content'}>
            <h3><Trans i18nKey={'voucher-model-fields-title'} values={{ voucher: voucher?.code ?? '' }} /></h3>
          </div>
          {voucher?.rejectedReason && <>
            <div className="alert alert-danger" role="alert">
              <strong>{t('voucher-rejected')}:</strong>
              <br/>
              {voucher.rejectedReason}
            </div>
            <Divider />
          </>}
          {error && <div className="alert alert-danger">{error}</div>}
          {voucher && <VoucherInfos t={t} voucher={voucher} />}
          {voucher && <Form
            defaultState={{
              model: { value: voucher.model ?? '', error: null },
              description: { value: voucher.description ?? '', error: null },
              serialNumber: { value: voucher.serialNumber ?? '', error: null },
              descriptionAccessories: { value: voucher.descriptionAccessories ?? '', error: null },
              priceDevice: { value: voucher.priceDevice ?? '', error: null },
              priceAccessories: { value: voucher.priceAccessories ?? '', error: null },
              reference: { value: voucher.code?.replaceAll('-', '').padStart(10, '9') ?? '', error: null },
              idCard: { value: voucher.contractSubject?.idCard ?? '', error: null },
              ...(isUaVoucher ? {
                idCardType: { value: voucher.contractSubject?.idCardType ?? '', error: null },
              } : {})
            }}
            provideValidationRules={provideValidationRules}
            onValidFormSubmit={onSubmit}
            customValidateForm={customValidateForm}
            onValueChange={onFormValueChange}
          >
            <div className={'row'}>
              <div className={'col-12 col-md-8'}>
                <FormField label={`${t('voucher-model-field-model')} *`} name={'model'} type={'text'} disabled={isEditDisabled} />
              </div>
              <div className={'col-12 col-md-4'}>
                <FormField label={`${t('voucher-model-field-serialNumber')} *`} name={'serialNumber'} type={'text'} disabled={isEditDisabled} />
              </div>
              <div className={'col-12'}>
                <FormSelect label={`${t('voucher-model-field-description')} *`} name={'description'} disabled={isEditDisabled} options={[
                  { value: '', text: t('voucher-model-desc-select') },
                  { value: 'notebook', text: t('voucher-model-desc-optionNotebook') },
                  { value: 'tablet', text: t('voucher-model-desc-optionTablet') },
                  { value: 'all_in_one', text: t('voucher-model-desc-optionAllInOne') },
                ]} />
                <div className="alert alert-danger" role="alert">
                  {t('voucher-model-field-warning')}
                </div>
                <FormField label={`${t('voucher-model-field-descriptionAccessories')}${isPriceAccessoriesRequired ? ' *' : ''}`} name={'descriptionAccessories'} type={'textarea'} disabled={isEditDisabled} />
              </div>
              <div className={'col-12 col-sm-6 col-md-4'}>
                <FormField label={`${t('voucher-model-field-priceDevice')} *`} name={'priceDevice'} type={'number'} disabled={isEditDisabled} />
              </div>
              <div className={'col-12 col-sm-6 col-md-4'}>
                <FormField label={`${t('voucher-model-field-priceAccessories')}`} name={'priceAccessories'} type={'number'} disabled={isEditDisabled} />
              </div>
              <div className={'col'}>
                <div className={'form-field'}>
                  <div className={'form-group'}>
                    <label>{t('voucher-model-field-totalPrice')}</label>
                    <input className={'form-control'} disabled={true} value={prices?.length === 3 ? prices[2] : ''} />
                  </div>
                </div>
              </div>
              <div className={'col-12'}>
                <FormField label={t('voucher-model-field-reference')} name={'reference'} type={'text'} disabled={true} />
              </div>
              <div className={'col-12'}>
                <FormField label={`${t(isUaVoucher ? 'voucher-idCard-ua' : 'voucher-idCard')} *`} name={'idCard'} type={'text'} disabled={isEditDisabled} />
              </div>
              { isUaVoucher && <div className={'col-12'}>
                <FormSelect label={`${t('voucher-idCard-type')} *`} name={'idCardType'} disabled={isEditDisabled} options={[
                  { value: '', text: t('registration-radio-sk-rodne-cislo') },
                  { value: 'idOdidenca', text: t('registration-radio-sk-ifo') },
                  { value: 'uaDanoveCislo', text: t('registration-radio-ua-tax-id') },
                  { value: 'pas', text: t('registration-radio-ua-pass-number') },
                  { value: 'drivingLicense', text: t('registration-radio-driver-license') },
                  { value: 'temporaryResidence', text: t('registration-radio-temporary-residence') },
                ]} />
              </div> }
              {(voucher?.verifiedType & Consts.Voucher.Type.FirstClass) !== 0 && <div className={'col-12'}>
                <FormCheckbox label={t('voucher-sale-without-setup')} name={'isSaleWithoutSetup'}
                              checked={isSaleWithoutSetup} onChange={(e) => {
                  setIsSaleWithoutSetup(() => e.target.checked)
                }}/>
              </div>}
            </div>
            <Divider />
            <div className={'d-flex justify-content-between align-items-center'}>
              <div className={'d-flex flex-row gap-4'}>
                {allowedAttachments && <a href={api.salesman.getVoucherDocUrl(voucher.id, `zmluva.pdf`)} target={'_blank'} className={'d-flex flex-column align-items-center btn btn-link p-0'}>
                  <Icon icon={'mdi:file-document-outline'} style={{ fontSize: '4.5em' }} />
                  <span>{t('attachment-contract')}</span>
                </a>}
                {allowedAttachments && <a href={api.salesman.getVoucherDocUrl(voucher.id, `preberak.pdf`)} target={'_blank'} className={'d-flex flex-column align-items-center btn btn-link p-0'}>
                  <Icon icon={'mdi:file-document-outline'} style={{ fontSize: '4.5em' }} />
                  <span>{t('attachment-protocol')}</span>
                </a>}
              </div>
              <div className={'d-flex flex-row align-items-center gap-2'}>
                {result !== null && <div className={`m-1 ${result.success ? 'field-success' : 'field-error'}`}>{result.message}</div>}
                {loading && <Loader />}
                {!isEditDisabled && <button type={'submit'} value={'save'} className={'btn btn-primary'} disabled={loading}>{t('general-save')}</button>}
              </div>
            </div>
            {allowedAttachments && <>
              <Divider size={4} />
              <div className={'title-content'}>
                <h4>{t('attachments-upload')}</h4>
              </div>
              <FilesUpload voucherId={voucher.id} files={files} setFiles={setFilesImpl} disabled={isEditDisabled} />
              <Divider size={4} />
            </>}
            {!voucher.confirmedAt && <div className={'d-flex flex-column align-items-center'}>
              {allowedAttachments && <button type={'submit'} value={'finish'} className={'btn btn-lg btn-submit'} disabled={loading || !allRequiredFieldsFilled}>{t('voucher-process-finish')}</button>}
              <button type={'button'} className={'btn btn-sm btn-link'} onClick={onCancelClick} disabled={loading}>{t('voucher-process-cancel')}</button>
            </div>}
          </Form>}
        </div>
      </div>
    </div>)
}

const subjectToString = (it) => `${it.name}${it.surname ? ` ${it.surname}` : ''}, ${it.address ? `${it.address}, ${it.postcode} ${it.town}` : ''}`

const VoucherInfos = ({ t, voucher }) => {
  return (<div className={'text-center mb-2'}>
    {voucher.student?.id && <p className={'mb-0'}>
      <strong className={'me-1'}>{t('voucher-student')}:</strong><span>{subjectToString(voucher.student)}</span>
    </p>}
    {voucher.parent1?.id && <p className={'mb-0'}>
      <strong className={'me-1'}>{t('voucher-parent')}:</strong><span>{subjectToString(voucher.parent1)}</span>
    </p>}
    {voucher.parent2?.id && <p className={'mb-0'}>
      <strong className={'me-1'}>{t('voucher-parent')}:</strong><span>{subjectToString(voucher.parent2)}</span>
    </p>}
  </div>)
}

export default withTranslation()(VoucherApplyPage)