import React, { useContext, useEffect }                    from 'react'
import ReactDOM                                            from 'react-dom/client'
import { BrowserRouter, Navigate, useNavigate, useRoutes } from 'react-router-dom'
import AgreementPage                                       from './pages/static/AgreementPage'
import ContractPage                                        from './pages/static/ContractPage'
import PrivacyPolicyPage                                   from './pages/static/PrivacyPolicyPage'
import TargetGroupsPage                                    from './pages/static/TargetGroupsPage'
import Header                                              from './components/Header'
import Footer                                              from './components/Footer'
import { Outlet }                                          from 'react-router-dom'
import ReactGa                                             from 'react-ga'
import Consts                                              from './consts'
import UAConsts                                            from './ua/consts'
import { usePageViews }                                    from './components/CookiesDialog'
import LoginPage                                           from './pages/LoginPage'
import { useCurrentUser }                                  from './hooks'
import { Trans }                                           from 'react-i18next'
import { DateTime }          from 'luxon'
import { useApi }            from './api'
import VoucherLookupPage     from './pages/salesman/VoucherLookupPage'
import VoucherApplyPage      from './pages/salesman/VoucherApplyPage'
import VoucherResultPage     from './pages/salesman/VoucherResultPage'
import ScrollToTop           from './components/ScrollToTop'
import VouchersPage          from './pages/supervisor/SupervisorVouchersPage'
import SearchVoucherPage     from './pages/admin/VoucherSearchPage'
import CallSearchVoucherPage from './pages/callcenter/CallSearchVoucherPage'
import './index.scss'
import './i18n'
import StoreChainListPage   from './pages/admin/StoreChainListPage'
import StoreChainDetailPage from './pages/admin/StoreChainDetailPage'
import ExportPage             from './pages/admin/ExportPage'
import VoucherPage            from './pages/admin/VoucherPage'
import StoreChainVoucherPage                                from './pages/storeChain/VoucherPage'
import UserPage                                             from './pages/admin/UserPage'
import StorePage                                            from './pages/admin/StorePage'
import UALayout                                             from './ua/UALayout'
import VouchersPaidPage                                     from './pages/admin/VouchersPaidPage'
import ImportIfoPage                                        from './pages/admin/ImportIfoPage'
import { isUAHost }                                         from './helper/host'
import VoucherCheckDataPage                                 from './pages/salesman/VoucherCheckDataPage'
import TagManager                                           from 'react-gtm-module'
import { RegistrationFormNAPage, UARegistrationFormNAPage } from './pages/RegistrationFormNAPage'
import AdminStatisticsPage                                  from './pages/admin/AdminStatisticsPage'
import TransferPage                                         from './pages/TransferPage'

ReactGa.initialize(Consts.GoogleAnalytics.id, {
  debug: process.env.NODE_ENV === 'development'
});

TagManager.initialize({
  gtmId: isUAHost() ? UAConsts.GoogleTagManager.id : Consts.GoogleTagManager.id,
})

export const AppContext = React.createContext({
  user: null,
});

const App = ({ children }) => {
  const navigate = useNavigate()
  const [user, setUser] = useCurrentUser()
  const api = useApi()

  useEffect(() => {
    if (!!user?.expiresAt && DateTime.fromISO(user?.expiresAt) < DateTime.now()) {
      (async () => {
        try {
          let response = await api.user.refreshToken(user.refreshToken)
          setUserImpl({
            ...user,
            accessToken: response.accessToken,
            expiresAt: response.expiresAt,
          })
        } catch (e) {
          setUserImpl(null)
        }
      })()
    }
  }, [])

  const setUserImpl = (newUser) => {
    let redirectToLogin = newUser === null && user !== null
    setUser(newUser)
    if (redirectToLogin) {
      navigate('/prihlasenie')
    }
  }

  useEffect(() => {
    document.getElementsByTagName('body')[0].className = isUAHost() ? 'ua' : 'sk'
  }, [])

  return (
      <AppContext.Provider value={{ user: user, setUser: setUserImpl }}>
        {children}
      </AppContext.Provider>
  )
}

const CommonLayout = () => {
  usePageViews()
  return (<>
    <Header />
    <ScrollToTop />
    <Outlet />
    <Footer />
  </>)
}


const NotFoundPage = () => {
  return (<div className={'container'}>
    <div className={'content-wrapper alert-not-found'}>
      <p><Trans i18nKey={'general-not-found'} /></p>
    </div>
  </div> )
}

const UserProtectedPage = ({ children, allowedTypes = [], checkUser = (user) => true }) => {
  const [user] = useCurrentUser()
  if ((allowedTypes.length === 0 || allowedTypes.indexOf(user?.type) > -1) && checkUser(user)) {
    return (<React.Fragment>{children}</React.Fragment>)
  }
  return <Navigate to={'/notFound'} />
}
const SalesmanProtectedPage = ({ children }) => {
  return <UserProtectedPage allowedTypes={[Consts.User.Type.Salesman]}>{children}</UserProtectedPage>
}
const SupervisorProtectedPage = ({ children }) => {
  return <UserProtectedPage allowedTypes={[Consts.User.Type.Salesman]} checkUser={(user) => user.flags & Consts.User.Flags.Supervisor}>{children}</UserProtectedPage>
}
const CallCenterProtectedPage = ({ children }) => {
  return <UserProtectedPage allowedTypes={[Consts.User.Type.Callcenter]}>{children}</UserProtectedPage>
}
const StoreChainProtectedPage = ({ children }) => {
  return <UserProtectedPage allowedTypes={[Consts.User.Type.StoreChain]}>{children}</UserProtectedPage>
}
const AdminProtectedPage = ({ onlySuperAdmin = false, children }) => {
  const [user] = useCurrentUser()
  if (onlySuperAdmin === true && user.flags !== 0) {
    return <Navigate to={'/notFound'} />
  }
  return <UserProtectedPage allowedTypes={[Consts.User.Type.Admin]}>{children}</UserProtectedPage>
}

const Router = () => {
  const { user } = useContext(AppContext)

  return useRoutes([
    ...(isUAHost() ? [
      { path: '/',
        element: <UALayout />,
        children: [
          { path: '/suhlas-s-presunom/:code/:hash', element: <TransferPage /> },
          { path: '/', element: <UARegistrationFormNAPage /> },
        ]
      },
    ] : [
      {
        path: '/ua',
        element: <UALayout />,
        children: [
          ...((user?.type === Consts.User.Type.Salesman) ? [
            { path: '/ua/salesman/voucher/check/:voucherId', element: <SalesmanProtectedPage><VoucherCheckDataPage /></SalesmanProtectedPage> },
            { path: '/ua/salesman/voucher/:voucherId', element: <SalesmanProtectedPage><VoucherApplyPage/></SalesmanProtectedPage> },
            { path: '/ua/salesman/voucher/result/:voucherId', element: <SalesmanProtectedPage><VoucherResultPage/></SalesmanProtectedPage> },
          ] : []),
          { path: '/ua', element: <Navigate to="/" replace={true} />},
        ]
      },
      {
      path: '/',
      element: <CommonLayout/>,
      children: [
        ...(user === null ? [
          { path: '/notFound', element: <NotFoundPage/> },
          { path: '/zmluvne-podmienky', element: <ContractPage/> },
          { path: '/suhlas', element: <AgreementPage/> },
          { path: '/ochrana-osobnych-udajov', element: <PrivacyPolicyPage/> },
          { path: '/cielove-skupiny', element: <TargetGroupsPage/> },
          { path: '/prihlasenie', element: <LoginPage/> },
          { path: '/suhlas-s-presunom/:code/:hash', element: <TransferPage /> },
          { path: '/', element: <RegistrationFormNAPage /> },
        ] : []),
        ...((user?.type === Consts.User.Type.Admin) ? [
          /* TODO temporary(?) disabled { path: '/user/import', element: <AdminProtectedPage><UserImportPage/></AdminProtectedPage> }, */
          { path: '/store/:storeId', element: <AdminProtectedPage><StorePage /></AdminProtectedPage> },
          { path: '/storeChain/list', element: <AdminProtectedPage><StoreChainListPage /></AdminProtectedPage> },
          { path: '/storeChain/:subjectId', element: <AdminProtectedPage><StoreChainDetailPage /></AdminProtectedPage> },
          { path: '/import/ifo', element: <AdminProtectedPage><ImportIfoPage /></AdminProtectedPage> },
          { path: '/import/vouchers/paid', element: <AdminProtectedPage><VouchersPaidPage /></AdminProtectedPage> },
          { path: '/export', element: <AdminProtectedPage><ExportPage /></AdminProtectedPage> },
          { path: '/user/statistics', element: <AdminProtectedPage onlySuperAdmin={true}><AdminStatisticsPage /></AdminProtectedPage> },
          { path: '/voucher/:voucherId', element: <AdminProtectedPage><VoucherPage /></AdminProtectedPage> },
          { path: '/user/:userEmail?', element: <AdminProtectedPage><UserPage /></AdminProtectedPage> },
          { path: '/', element: <AdminProtectedPage><SearchVoucherPage/></AdminProtectedPage> },
        ] : []),
        ...((user?.type === Consts.User.Type.Salesman) ? [
          { path: '/salesman/voucher/check/:voucherId', element: <SalesmanProtectedPage><VoucherCheckDataPage /></SalesmanProtectedPage> },
          { path: '/salesman/voucher/:voucherId', element: <SalesmanProtectedPage><VoucherApplyPage/></SalesmanProtectedPage> },
          { path: '/salesman/voucher/result/:voucherId', element: <SalesmanProtectedPage><VoucherResultPage/></SalesmanProtectedPage> },
          { path: '/supervisor/vouchers', element: <SupervisorProtectedPage><VouchersPage/></SupervisorProtectedPage> },
          { path: '/', element: <SalesmanProtectedPage><VoucherLookupPage/></SalesmanProtectedPage> },
        ] : []),
        ...((user?.type === Consts.User.Type.Callcenter) ? [
          { path: '/', element: <CallCenterProtectedPage><CallSearchVoucherPage/></CallCenterProtectedPage> },
        ] : []),
        ...((user?.type === Consts.User.Type.StoreChain) ? [
          { path: '/store/:storeId', element: <StoreChainProtectedPage><StorePage /></StoreChainProtectedPage> },
          { path: '/voucher/:voucherId', element: <StoreChainProtectedPage><StoreChainVoucherPage /></StoreChainProtectedPage> },
          { path: '/', element: <StoreChainProtectedPage><StoreChainDetailPage /></StoreChainProtectedPage> },
        ] : []),
      ],
    }]),
    { path: '*', element: <Navigate to="/" replace={true} /> }
  ])
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <React.StrictMode>
      <App>
        <Router />
      </App>
    </React.StrictMode>
  </BrowserRouter>
)
