import Consts from './../consts'

const UAConsts = Object.freeze({
  ...Consts,
  GoogleTagManager: {
    id: 'GTM-TRGBS59S',
  },
  GoogleAnalytics: {
    id: 'G-9TFVVX91BD',
  },
  ReCAPTCHA: {
    key: '6Lcsq24nAAAAAHMDjKx0s3jNNzaveaRTrd4vuzgQ',
    keyDev: '6Lcsq24nAAAAAlqrRLrfvZWsyr1JrlMCm5Wi_1oL',
  },
  Contact: {
    tel: '+421 232 284 467',
    email: 'info@ukrajinskyziak.sk',
  },
  LocalStorageLangKey: 'lang',
})

export default UAConsts
