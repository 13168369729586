import { Trans, withTranslation }                 from 'react-i18next'
import React, { useContext, useEffect, useState } from 'react'
import { useApi }                                 from '../../api'
import { useParams, useNavigate }                 from 'react-router-dom'
import FormField                                  from '../../components/FormField'
import { Divider }    from '../../components/Divider'
import { Form }       from '../../components/form/Form'
import {
  sanitizeRequiredFiles, VALIDATION_RULES_VOUCHER_MODEL
}                     from '../../helper/voucherValidation'
import Loader         from '../../components/Loader'
import FormResult     from '../../components/form/FormResult'
import FormSelect     from '../../components/FormSelect'
import FilesUpload    from '../../components/FilesUpload'
import Consts         from '../../consts'
import { AppContext } from '../../index'
import { Icon }       from '@iconify/react'

const VoucherPage = ({ t }) => {

  const api = useApi()
  const navigate = useNavigate()
  const { voucherId } = useParams()
  const [voucher, setVoucher] = useState(null)
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState(null)

  useEffect(() => {
    setLoading(() => true)
    api.storeChain.voucherDetail(voucherId).then(response => {
      setVoucher(response)
    }).catch(error => {
      setResult({ success: false, message: error.error })
    }).finally(() => {
      setLoading(() => false)
    })
  }, [voucherId])

  // must be checked before Form.defaultValues is provided (because of many useState variables usage)
  const isDataInitialized = loading === false && voucher !== null

  const repairVoucher = () => {
    if (window.confirm(t('voucher-repair-confirm'))) {
      setLoading(() => true)
      api.storeChain.repairVoucher(voucher.id).then(resp => {
        navigate('/')
      }).catch(error => {
        setResult({ success: false, message: error.error })
      }).finally(() => {
        setLoading(() => false)
      })
    }
  }

  return (
    <div className={'container'}>
      <div className={'content-wrapper'}>
        <div className={'mb-4 d-flex flex-row justify-content-center gap-2 align-items-baseline'}>
          <h3 className={'mb-0'}><Trans i18nKey={'voucher-title'} values={{ voucher: voucher?.code ?? '' }} /></h3>
          <span className={'fs-2'}>{((voucher?.type & Consts.Voucher.Type.Ukraine) === 0) ? <Icon icon={'cif:sk'} /> : <Icon icon={'cif:ua'} />}</span>
        </div>
        {loading && <Loader />}
        {voucher && <div className={'form-wrapper'}>
          {isDataInitialized && <>
            <Divider />
            <div className="alert alert-danger" role="alert">
              <strong>{t('voucher-rejected')}:</strong>
              <br/>
              {voucher?.rejectedReason ?? ''}
            </div>
            <Divider />
            {voucher?.student && <>
              <h2>{t('voucher-student')}</h2>
              <p className="mb-1" key={'voucher-student'}>
                <span className={'voucher-field'}>{`${voucher.student.name} ${voucher.student.surname}`}</span>
              </p>
              <Divider />
            </>}
            {voucher.contractSubject && <>
              <h2>{t('voucher-parent')}</h2>
              <p className="mb-1" key={'contract-subject'}>
                <span className={'voucher-field'}>{[`${voucher.contractSubject.name}${voucher.contractSubject.surname ? ` ${voucher.contractSubject.surname}` : ''}`, voucher.contractSubject.phone].filter(it => !!it).join(', ')}</span>
              </p>
              <Divider />
            </>}
            {voucher.salesman && <>
              <h2>{t('voucher-salesman')}</h2>
              <p className="mb-1" key={'salesman'}>
                <span className={'voucher-field'}>{[voucher.salesman.subject?.name, `${voucher.salesman.name}${voucher.salesman.surname ? ` ${voucher.salesman.surname}` : ''}`, voucher.salesman.email, voucher.salesman.phone].filter(it => !!it).join(', ')}</span>
              </p>
              <Divider />
            </>}
            { /* Voucher device info part */  }
            <VoucherModelInfo t={t} voucher={voucher} />
            { /* Voucher attachments */ }
            <VoucherAttachments t={t} voucher={voucher} />
            <FormResult result={result} />
            <Divider size={4} />
            <div className={'d-flex flex-row justify-content-end'}>
              {!loading && <button type={'button'} className={'btn btn-outline-danger'} onClick={repairVoucher}>{t('voucher-return-rejected')}</button>}
            </div>
          </>}
        </div>}
      </div>
    </div>
  )
}

const VoucherModelInfo = ({ t, voucher }) => {

  const api = useApi()
  const { user } = useContext(AppContext)
  const [result, setResult] = useState(null)
  const [loading, setLoading] = useState(null)
  const [prices, setPrices] = useState([0, 0, 0])

  const disabledInput = !!voucher?.approvedAt || (user?.type === Consts.User.Type.Admin && (user?.flags & Consts.User.Flags.Readonly))

  useEffect(() => {
    setPrices([voucher.priceDevice ?? 0, voucher.priceAccessories ?? 0, (voucher.priceDevice ?? 0) + (voucher.priceAccessories ?? 0)])
  }, [])

  const onSubmit = (data) => {
    setLoading(() => true)
    const requestData = Object.keys(data).reduce((object, currentKey) => {
      object[currentKey] = data[currentKey].value
      return object
    }, {})
    api.storeChain.updateVoucher(requestData).then(response => {
      setResult({ success: true, message: t('general-data-saved') })
    }).catch(error => {
      setResult({ success: false, message: error.error })
    }).finally(() => {
      setLoading(() => false)
    })
  }
  const onFormValueChange = (key, value, state) => {
    if (['priceDevice', 'priceAccessories'].indexOf(key) > -1) {
      setPrices((prcs) => {
        let result = [...prcs]
        result[key === 'priceDevice' ? 0 : 1] = value ? parseFloat(value) : 0
        result[2] = Math.round((result[0] + result[1]) * 100) / 100
        return result
      })
    }
  }

  return (<Form
    defaultState={{
      'id': { value: voucher.id ?? '' },
      'model': { value: voucher.model ?? '' },
      'serialNumber': { value: voucher.serialNumber ?? '' },
      'description': { value: voucher.description ?? '' },
      'descriptionAccessories': { value: voucher.descriptionAccessories ?? '' },
      'priceDevice': { value: voucher.priceDevice ?? '' },
      'priceAccessories': { value: voucher.priceAccessories ?? '' },
      'reference': { value: voucher.reference ?? '' },
    }}
    onValueChange={onFormValueChange}
    provideValidationRules={() => VALIDATION_RULES_VOUCHER_MODEL}
    onValidFormSubmit={onSubmit}>
    <div className={'form-heading'}>
      <h2>{t('voucher-model-fields')}</h2>
    </div>
    <div className={'row'}>
      <div className={'col-12 col-md-8'}>
        <FormField label={`${t('voucher-model-field-model')} *`} name={'model'} type={'text'} disabled={disabledInput} />
      </div>
      <div className={'col-12 col-md-4'}>
        <FormField label={`${t('voucher-model-field-serialNumber')} *`} name={'serialNumber'} type={'text'} disabled={disabledInput} />
      </div>
      <div className={'col-12'}>
        <FormSelect label={`${t('voucher-model-field-description')} *`} name={'description'} disabled={disabledInput}
                    options={[
                      { value: '', text: t('voucher-model-desc-select') },
                      { value: 'notebook', text: t('voucher-model-desc-optionNotebook') },
                      { value: 'tablet', text: t('voucher-model-desc-optionTablet') },
                      { value: 'all_in_one', text: t('voucher-model-desc-optionAllInOne') },
                    ]}/>
        <FormField
          label={t('voucher-model-field-descriptionAccessories')}
          name={'descriptionAccessories'} type={'textarea'} disabled={disabledInput} />
      </div>
      <div className={'col-12 col-sm-6 col-md-4'}>
        <FormField label={`${t('voucher-model-field-priceDevice')} *`} name={'priceDevice'} type={'number'} disabled={disabledInput} />
      </div>
      <div className={'col-12 col-sm-6 col-md-4'}>
        <FormField label={`${t('voucher-model-field-priceAccessories')}`} name={'priceAccessories'} type={'number'} disabled={disabledInput} />
      </div>
      <div className={'col'}>
        <div className={'form-field'}>
          <div className={'form-group'}>
            <label>{t('voucher-model-field-totalPrice')}</label>
            <input className={'form-control'} disabled={true} value={prices?.length === 3 ? prices[2] : ''}/>
          </div>
        </div>
      </div>
      <div className={'col-12'}>
        <FormField label={t('voucher-model-field-reference')} name={'reference'} type={'text'}
                   disabled={true}/>
      </div>
    </div>
    <FormResult result={result} />
    <div className={'d-flex justify-content-start gap-2'}>
      {loading && <Loader />}
      {!loading && !disabledInput && <button type={'submit'} className={'btn btn-primary'}>{t('general-save')}</button>}
    </div>
  </Form>)
}

const VoucherAttachments = ({ t, voucher }) => {

  const [files, setFiles] = useState(sanitizeRequiredFiles(voucher.attachments))

  return (<div className={'row'}>
    <div className={'col-12'}>
      <Divider size={4} />
      <div className={'form-heading'}>
        <h2>{t('attachments')}</h2>
      </div>
      <FilesUpload voucherId={voucher.id} files={files} setFiles={setFiles} userType={'storeChain'} />
    </div>
  </div> )
}

export default withTranslation()(VoucherPage)