export default function TargetGroupsPage() {
  return <div className={'container'}>
    <div className={'content-wrapper'}>
    <p>Nárok na digitálny príspevok má žiak alebo študent, ktorý:
      <ol>
        <li><b>študentom 1. ročníka stredných škôl na SR alebo študentom 5. ročníka (kvinta) 8 ročných vzdelávacích programov stredných škôl na SR</b><br/>
          Študenti do 26.rokov, prvých ročníkov alebo zodpovedajúcich piatych ročníkov (kvintám 8 ročných vzdelávacích programov stredných škôl na SR) nasledujúcich druhov škôl v školskom roku 2023/2024:
          <ul>
            <li>4 ročné gymnázium</li>
            <li>8 ročné gymnázium</li>
            <li>4 ročné stredné odborné školy</li>
            <li>5 ročné stredné odborné školy</li>
            <li>2 ročné odbory stredných odborných škôl</li>
            <li>konzervatóriá</li>
            <li>8 ročné konzervatóriá</li>
            <li>stredné športové školy</li>
            <li>školy umeleckého priemyslu</li>
            <li>Školy pre deti so špeciálnymi výchovno-vzdelávacími potrebami:
              <ul>
                <li>špeciálne gymnáziá</li>
                <li>špeciálne stredné odborné školy</li>
              </ul>
            </li>
            <li>Špeciálne školy pre deti s mentálnym hendikepom:
              <ul>
                <li>odborné učilišťa (4 ročné)</li>
                <li>odborné učilišťa (2 ročné odbory)</li>
                <li>praktické školy</li>
              </ul>
            </li>
          </ul>
        </li>
        <li><b>v hmotnej núdzi</b><br/>
          Žiak ako člen domácnosti v hmotnej núdzi
        </li>
        <li><b>so špeciálnymi vzdelávacími potrebami podľa § 2 písm. p) zákona č. 245/2008 Z.z.</b><br/>
          Žiak majúci vyjadrenie zariadenia poradenstva a prevencie o tom, že jeho špeciálne výchovno-vzdelávacie potreby vyplývajú výlučne z ich vývinu v sociálne znevýhodnenom prostredí (§ 2 písm. p) zákona č. 245/2008 Z.z.)
        </li>
        <li><b>členom domácnosti, ktorej spoločný príjem nedosahuje 60% mediánu príjmu</b><br/>
          Žiak ako člen domácnosti, ktorej spoločný príjem nedosahuje 60% mediánu príjmu
        </li>
      </ol>
    </p>
  </div>
  </div>
}
