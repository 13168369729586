import { withTranslation }            from 'react-i18next'
import NoData                         from '../../components/NoData'
import React, { useEffect, useState } from 'react'
import { useApi }                     from '../../api'
import { Link, useNavigate }          from 'react-router-dom'
import Loader                         from '../../components/Loader'

const StoreChainListPage = ({ t }) => {

  const api = useApi()
  const navigate = useNavigate()
  const [subjects, setSubjects] = useState([])
  const [loading, setLoading] = useState(null)
  const [query, setQuery] = useState('')

  useEffect(()=>{
    (async () => {
      setLoading((load) => true)
      const { subjects } = await api.admin.storeChain(['vouchersSold'])
      setSubjects(subjects)
      setLoading((load) => false)
    })()
  }, [])

  return (
    <div className={'container'}>
      <div className={'content-wrapper'}>
        <div className={'title-content mb-4'}>
          <h3>{t('storeChain-list-title')}</h3>
        </div>
        {loading && <Loader />}
        <form className={'d-flex justify-content-end gap-2 mb-3'} onSubmit={(e) => {
          e.preventDefault()
          if (query) navigate(`/user/${query}`)
        }}>
          <div className={'col'}>
            <Link to={`/user/create`} className={'btn btn-outline-secondary'} style={{ padding: '0.65rem 1rem' }} >{t('user-create')}</Link>
          </div>
          <div className={'col'}>
            <input className={'form-control'} placeholder={t('user-email')} value={query} onChange={(e) => setQuery(e.target.value)} />
          </div>
          <div className={'col-auto'}>
            <button type="button" className="btn btn-primary" style={{ padding: '0.65rem 1rem' }} onClick={(e) => {
              if (query) navigate(`/user/${query}`)
            }}>{t('general-search')}</button>
          </div>
        </form>
        <div className="list-group list-group-flush">
          {(subjects ?? []).map((storeChain) => (
            <Link key={`store-chain-${storeChain.id}`} to={`/storeChain/${storeChain.id}`} className="list-group-item list-group-item-action d-flex justify-content-between align-items-start">
              <div className={'ms-2 me-auto'}>
                {storeChain.name}
              </div>
              <span className="badge bg-primary rounded-pill">{storeChain.vouchersSold ?? 0}</span>
            </Link>
          ))}
        </div>
        <NoData data={subjects} loading={loading} />
      </div>
    </div>
  )
}

export default withTranslation()(StoreChainListPage)