import './dialog.scss'
import { useEffect } from 'react'

export function Dialog({ title, open, children, actions }) {

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    }
    return () => document.body.style.overflow = 'unset'
  }, [open]);

  return (<div className={`dialog-backdrop ${open ? 'open' : ''}`}>
    <div className={`dialog-content ${open ? 'open' : ''}`}>
      <div className={'dialog-title'}>{title}</div>
      <div className={'dialog-body'}>{children}</div>
      {actions && <div className={'dialog-actions'}>
        {actions.map((action, index) => {
          return <button key={`dialog-action-${index}`} type={'button'} className={action.className} onClick={action.onClick}>{action.label}</button>
        })}
      </div>}
    </div>
  </div>)
}