import { Trans, withTranslation }                 from 'react-i18next'
import React, { useContext, useEffect, useState } from 'react'
import { useApi }                                 from '../../api'
import { useParams, useNavigate }                 from 'react-router-dom'
import FormField                                  from '../../components/FormField'
import { Divider }               from '../../components/Divider'
import { Form, ValidationRules } from '../../components/form/Form'

import {
  REASON_OPTIONS,
  sanitizeRequiredFiles, VALIDATION_RULES_SUBJECT_INSTITUTION,
  VALIDATION_RULES_SUBJECT_PERSON, VALIDATION_RULES_VOUCHER_MODEL
} from '../../helper/voucherValidation'
import Loader         from '../../components/Loader'
import FormResult     from '../../components/form/FormResult'
import FormSelect     from '../../components/FormSelect'
import FilesUpload    from '../../components/FilesUpload'
import Consts         from '../../consts'
import { AppContext } from '../../index'
import { Icon }       from '@iconify/react'
import { DateTime }   from 'luxon'
import FormCheckbox   from '../../components/FormCheckbox'

const VoucherPage = ({ t }) => {

  const api = useApi()
  const navigate = useNavigate()
  const { voucherId } = useParams()
  const { user } = useContext(AppContext)
  const [forceReloadCounter, setForceReloadCounter] = useState(0)
  const [voucher, setVoucher] = useState(null)
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState(null)
  const [addParent2, setAddParent2] = useState(false)
  const [parentCanBeInstitution, setParentCanBeInstitution] = useState(false)
  const [isInstitution, setIsInstitution] = useState(false)

  const showModelInfo = !!voucher?.confirmedAt
  const showAttachments = !!voucher?.confirmedAt
  const approved = !!voucher?.approvedAt
  const isActive = (voucher?.type & Consts.Voucher.Type.Deactivated) === 0
  const isAdmin = user?.type === Consts.User.Type.Admin && user?.flags === 0
  const isLimitedAdmin = user?.type === Consts.User.Type.Admin && (user?.flags & Consts.User.Flags.Readonly) !== 0
  const isControlAdmin = user?.type === Consts.User.Type.Admin && (user?.flags & Consts.User.Flags.Control) !== 0
  const disabledInputsGeneral = !isAdmin && (!isActive || approved || isLimitedAdmin || isControlAdmin)
  const disabledActions = !isActive || approved || isLimitedAdmin
  const allowGenerateDocuments = user?.type === Consts.User.Type.Admin && voucher?.confirmedAt

  useEffect(() => {
    setLoading(() => true)
    api.admin.voucherDetail(voucherId).then(response => {
      setParentCanBeInstitution(response.parent2 == null)
      setIsInstitution(response.parent1?.type === Consts.Subject.Type.institution)
      setVoucher(response)
    }).catch(error => {
      setResult({ success: false, message: error.error })
    }).finally(() => {
      setLoading(() => false)
    })
  }, [voucherId, forceReloadCounter])

  // must be checked before Form.defaultValues is provided (because of many useState variables usage)
  const isDataInitialized = loading === false && voucher !== null
  const hasSale = (voucher?.confirmedAt || voucher?.appliedAt) && voucher?.salesman

  const deleteVoucher = () => {
    if (window.confirm(t('voucher-delete-confirm'))) {
      setLoading(() => true)
      api.admin.deleteVoucher(voucher.id).then(resp => {
        setResult({ success: true, message: t('voucher-delete-success') })
        navigate('/')
      }).catch(error => {
        setResult({ success: false, message: error.error })
      }).finally(() => {
        setLoading(() => false)
      })
    }
  }
  const releaseSaleVoucher = () => {
    if (window.confirm(t('voucher-released-sale-confirm'))) {
      setLoading(() => true)
      api.admin.releaseSaleVoucher(voucher.id).then(resp => {
        setResult({ success: true, message: t('voucher-released-sale-success') })
        setForceReloadCounter((i) => i + 1)
      }).catch(error => {
        setResult({ success: false, message: error.error })
      }).finally(() => {
        setLoading(() => false)
      })
    }
  }
  const approveVoucher = () => {
    if (window.confirm(t('voucher-approve-confirm'))) {
      setLoading(() => true)
      api.admin.approveVoucher(voucher.id).then(resp => {
        setResult({ success: true, message: t('voucher-approve-success') })
        setForceReloadCounter((i) => i + 1)
      }).catch(error => {
        setResult({ success: false, message: error.error })
      }).finally(() => {
        setLoading(() => false)
      })
    }
  }
  const rejectVoucher = () => {
    const reason = window.prompt(t('voucher-reject-confirm'))
    if (reason) {
      setLoading(() => true)
      api.admin.rejectVoucher(voucher.id, reason).then(resp => {
        setResult({ success: true, message: t('voucher-reject-success') })
        setForceReloadCounter((i) => i + 1)
      }).catch(error => {
        setResult({ success: false, message: error.error })
      }).finally(() => {
        setLoading(() => false)
      })
    }
  }

  return (
    <div className={'container'}>
      <div className={'content-wrapper'}>
        <div className={'title-content mb-4'}>
          <h3><Trans i18nKey={'voucher-title'} values={{ voucher: voucher?.code ?? '' }} /></h3>
        </div>
        {loading && <Loader />}
        <div className={'form-wrapper'}>
          {isDataInitialized && <>
            <Divider />
            {!isActive && <>
              <div className="alert alert-danger" role="alert">{t('voucher-deactivated')}</div>
              <Divider />
              </>}
            {voucher?.rejectedReason && <>
              <div className="alert alert-danger" role="alert">
                <strong>{t('voucher-rejected')}:</strong>
                <br/>
                {voucher.rejectedReason}
              </div>
              <Divider />
            </>}
            {((voucher?.verifiedType & Consts.Voucher.Type.Deactivated) !== 0) && <>
              <div className="alert alert-danger" role="alert">
                <strong>{t('voucher-refused-detail')}</strong>
              </div>
              <Divider />
            </>}
            { /* STUDENT part */  }
            <h6>{t('registration-school')}</h6>
            <h4 className={voucher?.school.deactivatedAt ? 'text-danger-emphasis' : ''}>{voucher?.school.name}</h4>
            <Divider size={2} />
            <SubjectForm key={'student'} t={t} title={t('registration-student-title')} voucher={voucher} subject={voucher.student} disabled={disabledInputsGeneral} />
            <ReasonForm t={t} voucher={voucher} disabled={disabledInputsGeneral} />
            <Divider size={2} />
            {parentCanBeInstitution && <Form>
              <FormCheckbox label={t('registration-parent-is-institution')} name={'isInstitution'} checked={isInstitution} onChange={(e) => {
                setIsInstitution(() => e.target.checked)
              }} />
            </Form>}
            <SubjectForm key={'parent-1'} t={t} title={t('registration-title-parent1')} voucher={voucher} isInstitution={isInstitution} canBeInstitution={parentCanBeInstitution} subject={voucher.parent1} disabled={disabledInputsGeneral} isUARegistration={(voucher.type & Consts.Voucher.Type.Ukraine) !== 0} onSave={() => setForceReloadCounter((i) => i + 1)} />
            <Divider size={2} />
            {!disabledInputsGeneral && !voucher.parent2?.id && !isInstitution && <Form>
              <FormCheckbox label={t('registration-add-parent2')} name={'addParent2'} checked={addParent2} onChange={(e) => {
                setAddParent2(() => e.target.checked)
              }} />
            </Form>}
            {(!!voucher.parent2?.id || (!isInstitution && addParent2) ) && <>
              <SubjectForm key={'parent-2'} t={t} title={t('registration-title-parent2')} voucher={voucher} subject={voucher.parent2 ?? {}} disabled={disabledInputsGeneral} isUARegistration={(voucher.type & Consts.Voucher.Type.Ukraine) !== 0} onSave={() => setForceReloadCounter((i) => i + 1)} />
              <Divider size={2} />
            </>}
            {hasSale && <>
              <div className={'form-heading'}>
                <h2>{t('voucher-salesInfo')}</h2>
              </div>
              <div>
                {voucher.salesman && <p className="mb-1" key={'salesman'}>
                  <span className={'me-1'}>{`${t('voucher-salesman')}:`}</span>
                  <span className={'voucher-field'}>{[voucher.salesman.subject?.name, `${voucher.salesman.name}${voucher.salesman.surname ? ` ${voucher.salesman.surname}` : ''}`, voucher.salesman.email, voucher.salesman.phone].filter(it => !!it).join(', ')}</span>
                </p>}
              </div>
            </>}
            { /* Voucher device info part */  }
            {showModelInfo && <>
              <VoucherModelInfo t={t} voucher={voucher} />
            </>}
            {allowGenerateDocuments && <>
              <Divider size={4} />
              <div className={'d-flex flex-row gap-4'}>
                <a href={api.salesman.getVoucherDocUrl(voucher.id, `zmluva.pdf`)} target={'_blank'} className={'d-flex flex-column align-items-center btn btn-link p-0'}>
                  <Icon icon={'mdi:file-document-outline'} style={{ fontSize: '4.5em' }} />
                  <span>{t('attachment-contract')}</span>
                </a>
                <a href={api.salesman.getVoucherDocUrl(voucher.id, `preberak.pdf`)} target={'_blank'} className={'d-flex flex-column align-items-center btn btn-link p-0'}>
                  <Icon icon={'mdi:file-document-outline'} style={{ fontSize: '4.5em' }} />
                  <span>{t('attachment-protocol')}</span>
                </a>
              </div>
            </>}

            { /* Voucher attachments */ }
            {showAttachments && <>
              <VoucherAttachments t={t} voucher={voucher} />
            </>}
            <FormResult result={result} />
            <Divider size={4} />
            <div className={'d-flex flex-column align-items-end justify-content-end'}>
              {!loading && !disabledInputsGeneral && !hasSale && <button type={'button'} className={'btn btn-link'} onClick={deleteVoucher}>{t('voucher-delete')}</button>}
            </div>
            <div className={'d-flex flex-row justify-content-between'}>
              {!loading && !disabledActions && voucher?.confirmedAt && <button type={'button'} className={'btn btn-outline-secondary'} onClick={releaseSaleVoucher}>{t('voucher-release-sale-voucher')}</button>}
              {!loading && !disabledActions && voucher?.confirmedAt && !voucher.rejectedReason && <button type={'button'} className={'btn btn-outline-danger'} onClick={rejectVoucher}>{t('voucher-reject')}</button>}
              {!loading && !disabledActions && hasSale && <button type={'button'} className={'btn btn-outline-primary'} onClick={approveVoucher}>{t('voucher-approve')}</button>}
            </div>
            {voucher?.logs && <>
              <Divider size={4} />
              <div className="form-heading"><h2>Logy</h2></div>
              <table className={'table table-sm'}>
                <tbody>
                {voucher.logs.map((log, i) => (<tr key={`log-${i}`}>
                  <td>{log.action}</td>
                  <td className={'text-muted'} style={{ whiteSpace: 'nowrap' }}>{DateTime.fromISO(log.created).toFormat(Consts.UserDateTimeFormat)}</td>
                  <td style={{ whiteSpace: 'nowrap' }}>{log?.userName ?? ''}</td>
                  <td style={{ whiteSpace: 'pre-wrap' }}>{log.message}</td>
                </tr>))}
                </tbody>
              </table>
            </>}
          </>}
        </div>
      </div>
    </div>
  )
}

const SubjectForm = ({ t, title, voucher, subject, disabled, isInstitution, canBeInstitution = false, isUARegistration = false, onSave = () => {}}) => {

  const api = useApi()
  const [result, setResult] = useState(null)
  const [loading, setLoading] = useState(null)

  const onSubmit = async (data) => {
    setLoading(() => true)
    const requestData = Object.keys(data).reduce((object, currentKey) => {
      object[currentKey] = data[currentKey].value
      return object
    }, {})
    try {
      if (!subject.id) {
        await api.admin.createSubject(voucher.id, requestData)
      } else {
        await api.admin.updateSubject({
          ...requestData,
          ...(canBeInstitution ? { type: isInstitution ? Consts.Subject.Type.institution : Consts.Subject.Type.person } : {})
        })
      }
      setResult({ success: true, message: t('general-data-saved') })
      onSave()
    } catch (error) {
      setResult({ success: false, message: error.error })
    } finally {
      setLoading(() => false)
    }
  }

  return (<>
    {subject && <Form
      defaultState={{
        'id': { value: subject?.id ?? '' },
        'name': { value: subject?.name ?? '' },
        'surname': { value: subject?.surname ?? '' },
        'idNumber': { value: subject?.idNumber ?? '' },
        'town': { value: subject?.town ?? '' },
        'address': { value: subject?.address ?? '' },
        'postcode': { value: subject?.postcode ?? '' },
        'email': { value: subject?.email ?? '' },
        'phone': { value: subject?.phone ?? '' },
        'idCard': { value: subject?.idCard ?? '' },
      }}
      provideValidationRules={() => {
        if (isInstitution) {
          return VALIDATION_RULES_SUBJECT_INSTITUTION
        }
        return {
          ...VALIDATION_RULES_SUBJECT_PERSON,
          ...(isUARegistration ? {
            'idNumber': { regex: ValidationRules.notEmpty, error: 'validation-invalid-value' },
          } : {})
        }
      }}
      onValidFormSubmit={onSubmit}
    >
      <div className={'form-heading'}>
        <h2>{title}</h2>
      </div>
      <Divider />
      {isInstitution && <>
        <div className={'row'}>
          <div className={'col-12 col-md-6'}>
            <FormField label={`${t('registration-name')} *`} name={'name'} disabled={disabled} />
          </div>
          <div className={'col-12 col-md-6'}>
            <FormField label={`${t('registration-ico')} *`} name={'idNumber'} disabled={disabled} />
          </div>
          <div className={'col-12 col-md-6'}>
            <FormField label={t('general-phone')} name={'phone'} hint={t('hint-phone')} disabled={disabled} />
          </div>
          <div className={'col-12 col-md-6'}>
            <FormField label={t('voucher-idCard')} name={'idCard'} type={'text'} disabled={disabled} />
          </div>
        </div>
      </>}
      {!isInstitution && <>
        <div className={'row'}>
          <div className={'col-12 col-md-4'}>
            <FormField label={`${t('registration-name')} *`} name={'name'} disabled={disabled} />
          </div>
          <div className={'col-12 col-md-4'}>
            <FormField label={`${t('registration-surname')} *`} name={'surname'} disabled={disabled} />
          </div>
          <div className={'col-12 col-md-4'}>
            <FormField label={`${isUARegistration ? t('registration-idnumber-general') : t('registration-idnumber')} *`} name={'idNumber'} hint={isUARegistration ? t('registration-idnumber-general-hint') : t('hint-idnumber')} disabled={disabled} />
          </div>
        </div>
        <div className={'row'}>
          <div className={'col-12 col-md-5'}>
            <FormField label={`${t('registration-address')} *`} name={'address'} disabled={disabled} />
          </div>
          <div className={'col-12 col-md-5'}>
            <FormField label={`${t('registration-town')} *`} name={'town'} disabled={disabled} />
          </div>
          <div className={'col-12 col-md-2'}>
            <FormField label={`${t('registration-postcode')} *`} name={'postcode'} hint={t('hint-postcode')} disabled={disabled} />
          </div>
        </div>
        <div className={'row'}>
          <div className={'col-12 col-md-6'}>
            <FormField label={t('general-phone')} name={'phone'} hint={t('hint-phone')} disabled={disabled} />
          </div>
          <div className={'col-12 col-md-6'}>
            <FormField label={t('general-email')} name={'email'} hint={t('hint-email')} disabled={disabled} />
          </div>
          <div className={'col-12'}>
            <FormField label={t('voucher-idCard')} name={'idCard'} type={'text'} disabled={disabled} />
          </div>
        </div>
      </>}
      <FormResult result={result} />
      <div className={'d-flex justify-content-start gap-2'}>
        {loading && <Loader />}
        {!loading && !disabled && <button type={'submit'} className={'btn btn-primary'}>{t('general-save')}</button>}
      </div>
    </Form>}
  </>);
}

const ReasonForm = ({ t, voucher, disabled }) => {

  const api = useApi()
  const [reasons, setReasons] = useState(REASON_OPTIONS.map((reason, index) => (voucher.type & Math.pow(2, index)) !== 0))
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState(null)

  const onSubmit = (data) => {
    setLoading(() => true)
    const type = reasons.reduce((acc, it, idx) => acc | (it ? Math.pow(2, idx) : 0), 0)
    api.admin.updateVoucher({
      id: voucher.id,
      type: type
    }).then(() => {
      setResult( { success: true, message: t('voucher-type-updated') } )
    }).catch((e) => {
      setResult({ success: false, message: e.error ?? t('voucher-type-update-failed'), })
    }).finally(() => {
      setLoading(() => false)
    })
  }

  return <Form
    defaultState={{}}
    onValidFormSubmit={onSubmit}>
    <div className="form-heading"><h2>{t('voucher-groups')}</h2></div>
    {REASON_OPTIONS.map((reason, index) => (<FormCheckbox
      key={`reason-checkbox-${index}`}
      label={t(reason.label)}
      checked={reasons[index]}
      disabled={disabled}
      name={`reasonCheckbox${index}`}
      onChange={(e) => {
        setReasons((r) => {
          let result = [...r];
          result[index] = e.target.checked
          return result
        })
      }}
    />))}

    <FormResult result={result} />
    <div className={'d-flex justify-content-start gap-2'}>
      {loading && <Loader />}
      {!loading && !disabled && <button type={'submit'} className={'btn btn-primary'}>{t('general-save')}</button>}
    </div>
  </Form>
}

const VoucherModelInfo = ({ t, voucher }) => {

  const api = useApi()
  const { user } = useContext(AppContext)
  const [result, setResult] = useState(null)
  const [loading, setLoading] = useState(null)
  const [prices, setPrices] = useState([0, 0, 0])

  const isAdmin = user?.type === Consts.User.Type.Admin && user?.flags === 0
  const disabledInput = !isAdmin && (!!voucher?.approvedAt || (user?.type === Consts.User.Type.Admin && (user?.flags & Consts.User.Flags.Readonly) !== 0))

  useEffect(() => {
    setPrices([voucher.priceDevice ?? 0, voucher.priceAccessories ?? 0, (voucher.priceDevice ?? 0) + (voucher.priceAccessories ?? 0)])
  }, [])

  const onSubmit = (data) => {
    setLoading(() => true)
    const requestData = Object.keys(data).reduce((object, currentKey) => {
      object[currentKey] = data[currentKey].value
      return object
    }, {})
    api.admin.updateVoucher(requestData).then(response => {
      setResult({ success: true, message: t('general-data-saved') })
    }).catch(error => {
      setResult({ success: false, message: error.error })
    }).finally(() => {
      setLoading(() => false)
    })
  }
  const onFormValueChange = (key, value, state) => {
    if (['priceDevice', 'priceAccessories'].indexOf(key) > -1) {
      setPrices((prcs) => {
        let result = [...prcs]
        result[key === 'priceDevice' ? 0 : 1] = value ? parseFloat(value) : 0
        result[2] = Math.round((result[0] + result[1]) * 100) / 100
        return result
      })
    }
  }

  return (<Form
    defaultState={{
      'id': { value: voucher.id ?? '' },
      'model': { value: voucher.model ?? '' },
      'serialNumber': { value: voucher.serialNumber ?? '' },
      'description': { value: voucher.description ?? '' },
      'descriptionAccessories': { value: voucher.descriptionAccessories ?? '' },
      'priceDevice': { value: voucher.priceDevice ?? '' },
      'priceAccessories': { value: voucher.priceAccessories ?? '' },
      'reference': { value: voucher.reference ?? '' },
    }}
    onValueChange={onFormValueChange}
    provideValidationRules={() => VALIDATION_RULES_VOUCHER_MODEL}
    onValidFormSubmit={onSubmit}>
    <div className={'form-heading'}>
      <h2>{t('voucher-model-fields')}</h2>
    </div>
    <div className={'row'}>
      <div className={'col-12 col-md-8'}>
        <FormField label={`${t('voucher-model-field-model')} *`} name={'model'} type={'text'} disabled={disabledInput} />
      </div>
      <div className={'col-12 col-md-4'}>
        <FormField label={`${t('voucher-model-field-serialNumber')} *`} name={'serialNumber'} type={'text'} disabled={disabledInput} />
      </div>
      <div className={'col-12'}>
        <FormSelect label={`${t('voucher-model-field-description')} *`} name={'description'} disabled={disabledInput}
                    options={[
                      { value: '', text: t('voucher-model-desc-select') },
                      { value: 'notebook', text: t('voucher-model-desc-optionNotebook') },
                      { value: 'tablet', text: t('voucher-model-desc-optionTablet') },
                      { value: 'all_in_one', text: t('voucher-model-desc-optionAllInOne') },
                    ]}/>
        <FormField
          label={t('voucher-model-field-descriptionAccessories')}
          name={'descriptionAccessories'} type={'textarea'} disabled={disabledInput} />
      </div>
      <div className={'col-12 col-sm-6 col-md-4'}>
        <FormField label={`${t('voucher-model-field-priceDevice')} *`} name={'priceDevice'} type={'number'} disabled={disabledInput} />
      </div>
      <div className={'col-12 col-sm-6 col-md-4'}>
        <FormField label={`${t('voucher-model-field-priceAccessories')}`} name={'priceAccessories'} type={'number'} disabled={disabledInput} />
      </div>
      <div className={'col'}>
        <div className={'form-field'}>
          <div className={'form-group'}>
            <label>{t('voucher-model-field-totalPrice')}</label>
            <input className={'form-control'} disabled={true} value={prices?.length === 3 ? prices[2] : ''}/>
          </div>
        </div>
      </div>
      <div className={'col-12'}>
        <FormField label={t('voucher-model-field-reference')} name={'reference'} type={'text'}
                   disabled={true}/>
      </div>
    </div>
    <FormResult result={result} />
    <div className={'d-flex justify-content-start gap-2'}>
      {loading && <Loader />}
      {!loading && !disabledInput && <button type={'submit'} className={'btn btn-primary'}>{t('general-save')}</button>}
    </div>
  </Form>)
}

const VoucherAttachments = ({ t, voucher }) => {

  const { user } = useContext(AppContext)
  const [files, setFiles] = useState(sanitizeRequiredFiles(voucher.attachments))
  const isAdmin = user?.type === Consts.User.Type.Admin && user?.flags === 0
  const disabledInput = !isAdmin && (!!voucher?.approvedAt || (user?.type === Consts.User.Type.Admin && (user?.flags & Consts.User.Flags.Readonly) !== 0) || (user?.type === Consts.User.Type.Admin && (user?.flags & Consts.User.Flags.Control) !== 0))

  return (<div className={'row'}>
    <div className={'col-12'}>
      <Divider size={4} />
      <div className={'form-heading'}>
        <h2>{t('attachments')}</h2>
      </div>
      <FilesUpload voucherId={voucher.id} files={files} setFiles={setFiles} disabled={disabledInput} />
    </div>
  </div> )
}

export default withTranslation()(VoucherPage)