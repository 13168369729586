import Consts from '../consts'

export const isRequiredFile = (file) => isRequiredFileName(file.fileName)
export const isRequiredFileName = (fileName) => {
  let requiredFileNames = [Consts.VoucherAttachment.nameContract, Consts.VoucherAttachment.nameInvoice, Consts.VoucherAttachment.nameAcceptanceProtocol,]
  return requiredFileNames.indexOf(fileName) > -1
}

export const sanitizeFileName = (fileName, mimeType) => {
  if (isRequiredFileName(fileName)) {
    return fileName.replaceAll(' ', '-') + (mimeType === 'application/pdf' ? '.pdf' : mimeType === 'image/jpeg' || mimeType === 'image/jpg' ? '.jpg' : mimeType === 'image/png' ? '.png' : '')
  } else {
    return fileName
  }
}