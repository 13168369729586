import React from 'react'
import {Trans, useTranslation} from "react-i18next";

const RegistrationFormNAPage = () => {
  return <div className={'container'}>
    <div className={'content-wrapper'}>
      <h1>Projekt Digitálny žiak je ukončený, uplatnenie príspevku bolo možné do 30. 11. 2023</h1>
      <p>
        V projekte Digitálny príspevok pre žiakov Slovenskej republiky (Digitálny žiak) sme prijali a spracovali viac ako 150-tisíc registrácií – ďakujeme! Overovanie nárokov na príspevok, ako aj distribúcia potvrdzujúcich validačných SMS kódov boli ukončené a uplatnenie príspevku na nákup novej digitálnej techniky bolo následne možné v ktorejkoľvek z viac ako 500 predajní zapojených do projektu do 30. novembra 2023.
      </p>
    </div>
  </div>
}

const UARegistrationFormNAPage = () => {
  const {t} = useTranslation()

  return <div className={'container'}>
    <div className={'content-wrapper'}>
      <h1>{t('ua-registration-end-title')}</h1>
      <br/>
      <Trans i18nKey={'ua-registration-end-text'} components={{p: <p/>}}/>
    </div>
  </div>
}

export { RegistrationFormNAPage, UARegistrationFormNAPage }
