import { useFormContext }  from './form/FormProvider'
import { withTranslation } from 'react-i18next'

function FormCheckbox({ t, name, label, info, checked, onChange, disabled = false }) {

  const { state, handleChange } = useFormContext();

  return (<div className={`form-field ${!!state[name]?.error ? 'has-error' : ''}`}>
    {!!state[name]?.error && <div className={'field-error'}>{t(state[name]?.error)}</div> }

    <div className="form-check">
      <p>
        {/* <span> root is the magic fix in flexbox to wrap label in the new line correctly */}
        <span>
          <input className="form-check-input" type="checkbox" value="" id={`id-${name}`} checked={onChange ? checked : state[name]?.value} disabled={disabled} onChange={(e) => {
            onChange ? onChange(e) : handleChange(name, e.target.checked)
          }} />
        </span>
        <label className="form-check-label" htmlFor={`id-${name}`}>
          {label}
        </label>
      </p>
    </div>
    {info && <div className="form-check-info">
      <p>{info}</p>
    </div>}
  </div>)
}

export default withTranslation()(FormCheckbox)