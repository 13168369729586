import { Icon } from '@iconify/react'
import { withTranslation } from 'react-i18next'

const NoData = ({ t, data, loading }) => {

  if (loading !== false || (data?.length ?? 0) > 0) {
    return <></>
  }

  return <div className={'card my-3'} style={{ border: 'none' }} key={'no-data-element'}>
    <div className="card-body text-secondary-emphasis text-center">
      <Icon icon={'mdi:cancel'} className={'m-2'} style={{ fontSize: '4.5em' }} />
      <h3 className="card-title">{t('general-no-data')}</h3>
    </div>
  </div>
}

export default withTranslation()(NoData);