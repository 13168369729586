import { Trans, withTranslation } from 'react-i18next'
import { DateTime }               from 'luxon'
import Consts                     from '../../consts'
import React, { useContext }      from 'react'
import { Icon }                   from '@iconify/react'
import { Divider }                from '../../components/Divider'
import './voucher-list-item.scss'
import { useApi }                 from '../../api'
import { sanitizeFileName }       from '../../helper/fileutils'
import { AppContext }             from '../../index'
import { Link }                   from 'react-router-dom'

const VoucherListItem = ({ t, voucher, isCheckedToDeactivate = false, onDeactivateCheckChange = undefined, allowedFields = [] }) => {

  const api = useApi()
  const { user } = useContext(AppContext)

  const joinVisibleValues = (array) => array.filter(s => !!s).join(', ')
  const isAllowed = (fieldName) => allowedFields.indexOf(fieldName) > -1

  const phoneVerified = [voucher.student?.phoneVerifiedAt, voucher.parent1?.phoneVerifiedAt, voucher.parent2?.phoneVerifiedAt].filter(va => !!va)?.pop()

  const hasSale = (voucher.confirmedAt || voucher.appliedAt) && voucher.salesman
  const isActive = (voucher.type & Consts.Voucher.Type.Deactivated) === 0

  return (
    <li key={`voucher-${voucher.id}`} className="list-group-item list-group-item-action voucher-listitem">
      <div className={'d-flex flex-column'}>
        {!isActive && <div className={'mb-2 text-center'}>
            <span className={'text-secondary-emphasis font-monospace text-uppercase fw-bold'}>{t('voucher-deactivated')}</span>
        </div>}
        <div className={'d-flex flex-row'} style={{ opacity: isActive ? '' : 0.3 }}>
          <div className={'d-flex w-100 flex-column pe-1'}>
            <div className={'d-flex flex-row align-items-center gap-2'}>
              {isAllowed('edit') && <Link to={`/voucher/${voucher.id}`}><h5 className="mb-1">{voucher.code}</h5></Link>}
              {!isAllowed('edit') && isAllowed('code') && <h5 className="mb-1">{voucher.code}</h5>}
              {((voucher.type & Consts.Voucher.Type.Ukraine) === 0) ? <Icon icon={'cif:sk'} /> : <Icon icon={'cif:ua'} />}
            </div>

            <span style={{ fontWeight: '600' }} className={'text-primary'}>{`${t('voucher-details')}:`}</span>
            <hr className={'m-0 mb-1'} />
            {isAllowed('created') && <p className="mb-1" key={'created'}>
              <span className={'me-1'}>{`${t('voucher-created')}:`}</span>
              <span className={'voucher-field'}>{DateTime.fromISO(voucher.created).toFormat(Consts.UserDateTimeFormat)}</span>
            </p>}
            {isAllowed('student') && voucher.student && <p className="mb-1" key={'student'}>
              <span className={'me-1'}>{`${t('voucher-student')}:`}</span>
              <span className={'voucher-field'}>{joinVisibleValues([`${voucher.student.name} ${voucher.student.surname}`, voucher.student.email, voucher.student.phone])}</span>
            </p>}
            {isAllowed('school') && voucher.school && <p className="mb-1" key={'school'}>
              <span className={'me-1'}>{`${t('voucher-school')}:`}</span>
              <span className={'voucher-field'}>{joinVisibleValues([`${voucher.school.name}`, voucher.school.address, `${voucher.school.postcode} ${voucher.school.town}`])}</span>
            </p>}
            {isAllowed('parent1') && voucher.parent1 && <p className="mb-1" key={'parent1'}>
              <span className={'me-1'}>{`${t('voucher-parent')}:`}</span>
              <span className={'voucher-field'}>{joinVisibleValues([`${voucher.parent1.name} ${voucher.parent1.surname}`, voucher.parent1.email, voucher.parent1.phone])}</span>
            </p>}
            {isAllowed('parent2') && voucher.parent2 && <p className="mb-1" key={'parent2'}>
              <span className={'me-1'}>{`${t('voucher-parent')}:`}</span>
              <span className={'voucher-field'}>{joinVisibleValues([`${voucher.parent2.name} ${voucher.parent2.surname}`, voucher.parent2.email, voucher.parent2.phone])}</span>
            </p>}
            {hasSale && <div className={'mt-2'}>
              <span style={{ fontWeight: '600' }} className={'text-primary'}>{`${t('voucher-salesInfo')}:`}</span>
              <hr className={'m-0 mb-1'} />
              <div>
                {isAllowed('salesmanParent') && !isAllowed('salesman') && voucher.salesman && <p className="mb-1" key={'salesman'}>
                  <span className={'me-1'}>{`${t('voucher-salesman')}:`}</span>
                  <span className={'voucher-field'}>{joinVisibleValues([voucher.salesman.subject?.name])}</span>
                </p>}
                {isAllowed('salesman') && voucher.salesman && <p className="mb-1" key={'salesman'}>
                  <span className={'me-1'}>{`${t('voucher-salesman')}:`}</span>
                  <span className={'voucher-field'}>{joinVisibleValues([voucher.salesman.subject?.name, `${voucher.salesman.name}${voucher.salesman.surname ? ` ${voucher.salesman.surname}` : ''}`, voucher.salesman.email, voucher.salesman.phone])}</span>
                </p>}
                {isAllowed('deviceInfo') && voucher.model && <div className="my-1" key={'deviceInfo'}>
                  { [
                    { key: 'voucher-model-field-model', value: voucher.model },
                    { key: 'voucher-model-field-description', value: voucher.description },
                    { key: 'voucher-model-field-serialNumber', value: voucher.serialNumber },
                    { key: 'voucher-model-field-descriptionAccessories', value: voucher.descriptionAccessories },
                    { key: 'voucher-model-field-priceDevice', value: <Trans i18nKey={'fmCurrency'} values={{ val: voucher.priceDevice ?? 0 }}/> },
                    { key: 'voucher-model-field-priceAccessories', value: <Trans i18nKey={'fmCurrency'} values={{ val: voucher.priceAccessories ?? 0 }}/> },
                    { key: 'voucher-model-field-totalPrice', value: <Trans i18nKey={'fmCurrency'} values={{ val: (voucher.priceDevice ?? 0) + (voucher.priceAccessories ?? 0) }}/> },
                    { key: 'voucher-model-field-reference', value: voucher.reference },
                  ].map(item => (<div className={'d-flex'} key={`device-field-${item.key}`}>
                    <span className={'me-1'}>{t(item.key)}:</span><span className={'voucher-field'}>{item.value}</span>
                  </div>)) }
                </div>}
                {isAllowed('attachments') && voucher.attachments && [Consts.User.Type.Salesman, Consts.User.Type.Admin].indexOf(user.type) > -1 && <div className={'mb-1 ps-1'}>
                  {voucher.attachments.map(attachment => {
                    let url = api.common.getVoucherAttachmentUrl(user.type, attachment.id, sanitizeFileName(attachment.fileName, attachment.mimeType))
                    return (<a key={`attachment-${attachment.id}`} className={'me-2'} href={url} target={'_blank'} title={attachment.fileName}>
                      {attachment.fileName}
                    </a>)
                  })}
                </div>}
              </div>
            </div>}

            {typeof onDeactivateCheckChange !== 'undefined' && isActive && <div className={'mt-2'}>
              <hr className={'m-0 mb-3'} />
              <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" checked={isCheckedToDeactivate} id={`deactivate-checkbox-${voucher.id}`} onChange={(e) => {
                  onDeactivateCheckChange(voucher, e.target.checked)
                }} />
                <label className="form-check-label" htmlFor={`deactivate-checkbox-${voucher.id}`}>{t('voucher-deactivate-select')}</label>
              </div>
            </div>}
          </div>
          <div className={'d-flex flex-column text-end'} style={{ whiteSpace: 'nowrap' }}>
            <div className={'d-flex flex-column align-items-end'}>
              <span style={{ fontWeight: '100' }}>{voucher.verifiedType ? ((voucher.verifiedType & Consts.Voucher.Type.Deactivated) != 0 ? t('voucher-refused') : t('voucher-verified')) : t('voucher-unverified')}</span>
              <Divider size={0.5} />
            </div>
            <div className={'d-flex flex-column align-items-end'}>
              <span style={{ fontWeight: '100' }}>{voucher.confirmedAt || voucher.appliedAt ? (voucher.confirmedAt ? t('voucher-confirmedAt') : t('voucher-appliedAt')) : t('voucher-unused')}</span>
              {(voucher.confirmedAt || voucher.appliedAt) && <span>{DateTime.fromISO(voucher.confirmedAt ?? voucher.appliedAt).toFormat(Consts.UserDateTimeFormat)}</span>}
              <Divider size={0.5} />
            </div>
            <span className={`badge ${phoneVerified ? 'text-bg-success' : 'text-bg-warning' }`} title={phoneVerified ? DateTime.fromISO(phoneVerified).toFormat(Consts.UserDateTimeFormat) : ''}>
            <Icon className={'me-2'} icon={phoneVerified ? 'mdi:check-bold' : 'mdi:exclamation-bold'} />
              {phoneVerified ? t('voucher-phoneVerified') : t('voucher-phoneNotVerified')}
            </span>
            {!phoneVerified && <div className={'mt-1 fs-6'}>
              <Icon className={'me-2'} icon={'mdi:cellphone-information'} />
              <span>{voucher.student?.phoneVerificationCode ?? voucher.parent1?.phoneVerificationCode ?? ''}</span>
            </div>}
          </div>
        </div>
      </div>
    </li>
  )
}

export default withTranslation()(VoucherListItem)